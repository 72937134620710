import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StaticMapService {
  apiUrl = environment.config.apiUrl;
  endpoint = environment.config.staticMapEndpoint;
  private mapType = '.jpeg';

  constructor(private httpClient: HttpClient) {}

  public getImage(orderId: string, version: string | null): Observable<any> {
    const fileName = this.getFileName(orderId, version);
    return this.httpClient.get(`${this.apiUrl}${this.endpoint.replace(/folder/, orderId).replace(/item/, fileName)}`, {
      responseType: 'text'
    });
  }

  private getFileName(orderId: string, version: string | null): string {
    let fileName = orderId;
    if (version) {
      fileName = fileName + '_' + version;
    }
    return fileName + this.mapType;
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusCircleComponent } from './status-circle.component';
import { StatusColorPipeModule } from '../../pipes/status-color/status-color-pipe.module';

@NgModule({
  declarations: [StatusCircleComponent],
  exports: [StatusCircleComponent],
  imports: [CommonModule, StatusColorPipeModule]
})
export class StatusCircleModule {}

import { Injectable } from '@angular/core';
import { NotificationHttpService } from '../../../../services/notification-http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  GetAllNotificationsForUserIdQueryResponse,
  NotificationDto,
  NotificationStatus
} from '../../../../services/dto/notification.model';
import { map, switchMap, tap } from 'rxjs/operators';
import { NotificationService } from '../../../../services/notification.service';
import { cloneDeep } from 'lodash';

@Injectable()
export class NotificationListService {
  private readonly notificationsSubject: BehaviorSubject<NotificationDto[]> = new BehaviorSubject<NotificationDto[]>(
    []
  );
  public readonly notifications: Observable<NotificationDto[]>;

  constructor(
    private notificationHttpService: NotificationHttpService,
    private notificationService: NotificationService
  ) {
    this.notifications = this.notificationsSubject.asObservable().pipe(
      tap((notifications) =>
        notifications.sort((a, b) => {
          return new Date(b.creationTime).getTime() - new Date(a.creationTime).getTime();
        })
      )
    );
  }

  getNotifications(): void {
    this.allNotifications.subscribe((res) => this.notificationsSubject.next(res.notifications));
  }

  tileActionBtnClicked(link: string, id: string, creationTime: string): void {
    this.markNotificationRead(id, creationTime);
  }

  markReadBtnClicked(id: string, creationTime: string): void {
    this.markNotificationRead(id, creationTime);
  }

  markReadClickedWithoutRefresh(id: string, creationTime: string): void {
    this.notificationHttpService.markNotificationRead(id, creationTime).subscribe(() => {
      const notifications = <any>cloneDeep(this.notificationsSubject.value);
      const currentNotification = notifications.find((notification: NotificationDto) => {
        return notification.id === id;
      });

      if (currentNotification) {
        currentNotification.status = NotificationStatus.READ;
        this.notificationsSubject.next(notifications);
      }
    });
  }

  deleteNotification(id: string, creationTime: string): void {
    this.doAndRefresh(() => this.notificationHttpService.deleteNotification(id, creationTime));
  }

  deleteAllNotifications(): void {
    this.doAndRefresh(() => this.notificationHttpService.deleteAllNotifications());
  }

  markAllNotificationsAsRead(): void {
    this.doAndRefresh(() => this.notificationHttpService.markAllNofiticationsAsRead());
  }

  private markNotificationRead(id: string, creationTime: string): void {
    this.doAndRefresh(() => this.notificationHttpService.markNotificationRead(id, creationTime));
  }

  private get allNotifications(): Observable<GetAllNotificationsForUserIdQueryResponse> {
    return this.notificationHttpService.getAllNotifications();
  }

  private doAndRefresh(initialAction: () => Observable<unknown>): void {
    initialAction()
      .pipe(
        switchMap(() => this.allNotifications),
        map((res) => res.notifications)
      )
      .subscribe((notifications: NotificationDto[]) => {
        this.notificationsSubject.next(notifications);
        this.checkAndRefreshNotificationUser();
      });
  }

  private checkAndRefreshNotificationUser() {
    this.notificationService.checkNotificationRefresh();
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/api';

import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Range, SearchService } from '../../searchCommon/search.service';
import { AuthService } from '../../auth/auth.service';
import { QueryParamsService } from '../service/query-params.service';
import * as _ from 'lodash';
import { ShipperService } from '../../services/shipper.service';
import { CompaniesShipperResponse } from '../../services/dto/response/companies-shipper-response';
import { TranslateService } from '@ngx-translate/core';
import { RWD_BREAKPOINT, RwdService } from '../../services/rwd.service';
import { FavouritesService } from '../../services/favourites.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-listings-criteria',
  templateUrl: './criteria.component.html',
  styleUrls: ['./criteria.component.sass']
})
export class CriteriaComponent implements OnInit {
  @ViewChild('calendar') private calendar: any; // eslint-disable-line
  RWD_BREAKPOINT = RWD_BREAKPOINT;
  public moreFilters = false;
  public criteriaForm: FormGroup;
  INIT_VALUE_RANGE = 100;

  ranges: Range[];
  today: Date = new Date();

  usedCriteria = 0;
  shippers: CompaniesShipperResponse[] = [];
  isLoadingRouteToFav: boolean;
  routeAddedToFav: boolean;
  allowAddRouteToFav: boolean;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public searchService: SearchService,
    private route: ActivatedRoute,
    private query: QueryParamsService,
    public auth: AuthService,
    public shipperService: ShipperService,
    public messageService: MessageService,
    public translateService: TranslateService,
    public rwdService: RwdService,
    public favouritesService: FavouritesService
  ) {}

  ngOnInit(): void {
    this.initForm();

    this.searchService.clearLoadingCountry();
    this.searchService.clearUnloadingCountry();
    this.searchService.subscribeForFuzzyResponse();
    this.ranges = this.searchService.initRanges();
    this.criteriaForm.valueChanges.subscribe(() => {
      this.checkAllowAddRouteToFav();
      this.recalculateUsedCriteria();
    });

    this.subscribeValueChangesCity(<FormGroup<FormGroupPlace>>this.criteriaForm.get('loadingPlace'));
    this.subscribeValueChangesCity(<FormGroup<FormGroupPlace>>this.criteriaForm.get('unloadingPlace'));

    this.subscribeRouteQueryParams();
  }

  subscribeRouteQueryParams(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.decodeCriteria(params);
    });
  }

  private initForm() {
    this.criteriaForm = this.fb.group({
      loadingPlace: this.fb.group({
        city: [null],
        range: [this.INIT_VALUE_RANGE]
      }),
      loadingDateRange: [null],
      unloadingPlace: this.fb.group({
        city: [null],
        range: [this.INIT_VALUE_RANGE]
      }),
      unloadingDate: [null],
      minNumberOfCars: [null],
      maxNumberOfCars: [null],
      shipper: [null],
      negotiable: [null],
      fullTruckLoad: [null],
      hideYourCompanysOrders: [null],
      showOnlyMyFavouriteOrders: [null]
    });
  }

  subscribeValueChangesCity(formGroup: FormGroup<FormGroupPlace>): void {
    formGroup.get('city')?.valueChanges.subscribe((value: any) => {
      if (!value || value.localityType === 'city' || value.localityType === 'district') {
        formGroup.get('range')?.enable();
      }

      if (value?.administrativeAreaType === 'country') {
        formGroup.get('range')?.disable();
      }

      this.clearAddedToFav();
    });
    formGroup.get('range')?.valueChanges.subscribe((value: any) => {
      this.allowAddRouteToFav = true;
      this.routeAddedToFav = false;
    });
  }

  checkDisabled(controlPath: string): boolean {
    const formControl = _.get(this, controlPath);
    return formControl?.disabled;
  }

  decodeCriteria(params: ParamMap): void {
    if (this.query.containsLoadingData(params)) {
      this.searchService.loadingCountryCode = this.query.getLoadingCountry(params);
      this.criteriaForm
        .get('loadingPlace')
        ?.get('city')
        ?.setValue({
          country: this.query.getLoadingCountry(params),
          displayValue: this.query.getLoadingCaption(params),
          lat: this.query.getLoadingLatitude(params),
          lon: this.query.getLoadingLongitude(params),
          administrativeAreaType: this.query.getLoadingAdministrativeAreaType(params),
          localityType: this.query.getLoadingLocalityType(params)
        });
    } else {
      this.searchService.loadingCountryCode = '';
      this.criteriaForm.get('loadingPlace')?.get('city')?.setValue(null);
    }

    this.criteriaForm.get('loadingPlace')?.get('range')?.setValue(this.query.getLoadingRange(params));

    if (this.query.containsUnloadingData(params)) {
      this.searchService.unloadingCountryCode = this.query.getUnloadingCountry(params);
      this.criteriaForm
        .get('unloadingPlace')
        ?.get('city')
        ?.setValue({
          country: this.query.getUnloadingCountry(params),
          displayValue: this.query.getUnloadingCaption(params),
          lat: this.query.getUnloadingLatitude(params),
          lon: this.query.getUnloadingLongitude(params),
          administrativeAreaType: this.query.getUnloadingAdministrativeAreaType(params),
          localityType: this.query.getUnloadingLocalityType(params)
        });
    } else {
      this.searchService.unloadingCountryCode = '';
      this.criteriaForm.get('unloadingPlace')?.get('city')?.setValue(null);
    }

    this.criteriaForm.get('unloadingPlace')?.get('range')?.setValue(this.query.getUnloadingRange(params));

    this.criteriaForm.get('loadingDateRange')?.setValue(this.query.getLoadingDates(params));
    this.criteriaForm.get('unloadingDate')?.setValue(this.query.getUnloadingDate(params));
    this.criteriaForm.get('minNumberOfCars')?.setValue(this.query.getMinNumberOfCars(params));
    this.criteriaForm.get('maxNumberOfCars')?.setValue(this.query.getMaxNumberOfCars(params));
    this.criteriaForm.get('shipper')?.setValue(this.query.getShipper(params));
    this.criteriaForm.get('negotiable')?.setValue(this.query.getNegotiable(params));
    this.criteriaForm.get('fullTruckLoad')?.setValue(this.query.getFullTruckLoad(params));
    this.criteriaForm.get('hideYourCompanysOrders')?.setValue(this.query.getHideYourCompanysOrders(params));
    this.criteriaForm.get('showOnlyMyFavouriteOrders')?.setValue(this.query.getShowOnlyMyFavouriteOrders(params));
  }

  showMoreFilters(): void {
    this.moreFilters = true;
  }

  hideMoreFilters(): void {
    //TODO czyszczenie kryteriow??
    this.moreFilters = false;
  }

  onSearchClicked(): void {
    const rawValue = this.criteriaForm.getRawValue();

    this.router.navigate(['/search'], {
      queryParams: this.query.buildQueryParamsFromCriteria(rawValue),
      queryParamsHandling: 'merge'
    });
  }

  clearAdditionalFilters(): void {
    this.searchService.clearLoadingCountry();
    this.searchService.clearUnloadingCountry();

    this.criteriaForm.reset({
      loadingPlace: { range: this.INIT_VALUE_RANGE },
      unloadingPlace: { range: this.INIT_VALUE_RANGE }
    });
  }

  onDateRangeSelect(): void {
    if (this.criteriaForm.getRawValue()?.loadingDateRange?.[1]) {
      // If second date is selected
      this.calendar.overlayVisible = false;
    }
  }

  minimumUnloadingDate(): Date {
    const loadingDateRange = this.criteriaForm.get('loadingDateRange')?.value;

    if (!loadingDateRange) {
      return this.today;
    }

    return loadingDateRange[1];
  }

  recalculateUsedCriteria(): void {
    const rawValue = this.criteriaForm.getRawValue();
    let count = 0;
    if (rawValue.minNumberOfCars > 0) count++;
    if (rawValue.maxNumberOfCars > 0) count++;
    if (rawValue.negotiable > 0) count++;
    if (rawValue.fullTruckLoad > 0) count++;
    if (rawValue.hideYourCompanysOrders > 0) count++;
    if (rawValue.showOnlyMyFavouriteOrders > 0) count++;
    if (rawValue.shipper) count++;
    if (rawValue.loadingDateRange) count++;
    if (rawValue.unloadingDate) count++;

    this.usedCriteria = count;
  }

  getCompaniesShipperHelper(event: any): void {
    this.getCompaniesShipper(event.query.toLowerCase());
  }

  getCompaniesShipper(shipperCompanyName: string): void {
    this.shipperService.getCompaniesShipper(shipperCompanyName).subscribe((resp) => {
      this.shippers = resp;
    });
  }

  addRouteToFav(): void {
    this.isLoadingRouteToFav = true;

    const loadingPlace = this.criteriaForm.value.loadingPlace.city;
    const unloadingPlace = this.criteriaForm.value.unloadingPlace.city;

    const data = {
      loading: {
        city: null,
        countryCode: loadingPlace.displayValue.split(',').map((a: string) => a.trim())[0],
        location: null,
        range: null,
        administrativeAreaType: loadingPlace.administrativeAreaType === 'country' ? 'COUNTRY' : 'CITY'
      },
      unloading: {
        city: null,
        countryCode: unloadingPlace.displayValue.split(',').map((a: string) => a.trim())[0],
        location: null,
        range: null,
        administrativeAreaType: unloadingPlace.administrativeAreaType === 'country' ? 'COUNTRY' : 'CITY'
      }
    };

    if (data.loading.administrativeAreaType === 'CITY') {
      data.loading.city = loadingPlace.displayValue.split(',').map((a: string) => a.trim())[1];

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data.loading.location = {
        lat: loadingPlace.lat,
        lon: loadingPlace.lon
      };

      data.loading.range = this.criteriaForm.value.loadingPlace.range;
    }

    if (data.unloading.administrativeAreaType === 'CITY') {
      data.unloading.city = unloadingPlace.displayValue.split(',').map((a: string) => a.trim())[1];

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data.unloading.location = {
        lat: unloadingPlace.lat,
        lon: unloadingPlace.lon
      };

      data.unloading.range = this.criteriaForm.value.unloadingPlace.range;
    }

    this.favouritesService
      .addRouteToFavourites(data)
      .pipe(
        finalize(() => {
          this.isLoadingRouteToFav = false;
          this.routeAddedToFav = true;
        })
      )
      .subscribe(
        () => {
          this.messageService.add({
            closable: false,
            severity: 'success',
            summary: this.translateService.instant('listings.search.favouriteRoute.successfullyAddedRouteToFav'),
            data: {
              iconSrc: 'assets/icons/15px/heart-full.svg',
              iconStyle: 'width: 20px'
            }
          });
        },
        () => {
          this.messageService.add({
            closable: false,
            severity: 'error',
            summary: this.translateService.instant('listings.search.favouriteRoute.errorAddedRouteToFav'),
            data: {
              iconSrc: 'assets/icons/15px/heart-full.svg',
              iconStyle: 'width: 20px'
            }
          });
        }
      );
  }

  clearAddedToFav(): void {
    this.routeAddedToFav = false;
  }

  checkAllowAddRouteToFav(): void {
    this.allowAddRouteToFav =
      this.criteriaForm.get('loadingPlace')?.get('city')?.value &&
      this.criteriaForm.get('unloadingPlace')?.get('city')?.value
        ? true
        : false;
  }
}

interface FormGroupPlace {
  city: FormControl<string | null>;
  range: FormControl<number | null>;
}

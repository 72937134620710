import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FuzzySearchResponse } from '@tomtom-international/web-sdk-services';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LanguageService } from '../language.service';

declare let H: any;

@Injectable({
  providedIn: 'root'
})
export class HereMapService {
  public fuzzyResponse: BehaviorSubject<FullSearchResult> = new BehaviorSubject<FullSearchResult>({ items: [] });
  private apiKey = environment.hereMap.apiKey;
  private languageCode = this.translate.currentLang || environment.hereMap.languageCode;
  public platform: any;
  public geocoder: any;
  public searchService: any;
  private WHOLE_WORLD_BOUNDING_BOX = 'bbox:-180,-90,180,90';

  public constructor(private translate: TranslateService, private languageService: LanguageService) {
    this.platform = new H.service.Platform({
      apikey: this.apiKey
    });
    this.searchService = this.platform.getSearchService();
    this.languageService.currentLanguage$.subscribe((languageCode: string) => {
      this.languageCode = languageCode;
    });
  }

  public search(query: string): void {
    this.searchService.discover(
      {
        q: query,
        in: this.WHOLE_WORLD_BOUNDING_BOX,
        lang: this.languageCode
      },
      (response: any) => {
        const result = this.getFullSearchResult(response);
        this.fuzzyResponse.next(result);
      },
      (error: any) => {
        this.fuzzyResponse.error(error);
      }
    );
  }

  private getFullSearchResult(response: FuzzySearchResponse): FullSearchResult {
    const json: string = JSON.stringify(response);
    return JSON.parse(json);
  }

  public getCountry(result: SearchResult): string {
    return result.address.countryName || '';
  }
  public getCountryCode(result: SearchResult): string {
    return result.address.countryCode || '';
  }
  public getPostalCode(result: SearchResult): string {
    return result.address.postalCode || '';
  }
  public getStreetAndNumber(result: SearchResult): string {
    return (result.address.street ? result.address.street + ' ' : '') + (result.address.houseNumber || '');
  }
  public getCityOrCountryside(result: SearchResult): string {
    const countryside = this.getCountryside(result);
    const city = this.getCity(result);
    return countryside == city || !countryside ? city : countryside;
  }
  private getCountryside(result: SearchResult): string {
    return result.address.city !== result.address.county ? result.address.district : '';
  }
  private getCity(result: SearchResult): string {
    return result.address.city || '';
  }
  public getPlaceName(result: SearchResult): string {
    return result.resultType === "place" ? result.title : '';
  }
}

export interface FullSearchResult {
  items: SearchResult[];
}

export interface SearchResult {
  id: string;
  resultType: string;
  localityType: string | undefined;
  administrativeAreaType: string | undefined;
  position: Position;
  title: string;
  address: Address;
}

export interface Position {
  lng: number;
  lat: number;
  label?: string;
}

export interface Address {
  city: string;
  countryCode: string;
  countryName: string;
  county: string;
  district: string;
  subdistrict: string;
  label: string;
  postalCode: string;
  state: string;
  street: string;
  houseNumber: string;
}

export default class ValidatorPatterns {
  // PASSWORD V2 START
  public static readonly ONE_OR_MORE_CAPITAL: RegExp = /^.*[A-Z]+.*$/;
  public static readonly ONE_OR_MORE_LOWERCASE: RegExp = /^.*[a-z]+.*$/;
  public static readonly ONE_OR_MORE_SPECIAL_CHARACTERS: RegExp = /^.*[=+^$\*\.\[\]{}\(\)?"!@#%&\/\,><':;|_~`]+.*$/;
  public static readonly ONE_OR_MORE_NUMBER: RegExp = /^.*\d+.*$/;
  // PASSWORD V2 END
  public static readonly COUNTRY_PHONE_CODE: string = '^\\+\\d{1,4}$';
  public static readonly PHONE_NUMBER_REGISTRATION: string = '^[^0\\D]{1}\\d{1,14}$';
  public static readonly PHONE_NUMBER: string = '^[^0\\D]{1}\\d{7,12}$';
  public static readonly VIN: string = '^[^OQI]{17}$';
  public static readonly DRIVING_LICENSE_NO: string = '^[\\w./\\\\-]{1,20}$';
  public static readonly NAME: string = '^[a-zA-Z- ]{1,30}$';
  public static readonly SURNAME: string = '^[a-zA-Z- ]{1,50}$';
  public static readonly NOT_SPACES: RegExp = /(?!^\s+$)^.*$/;
  public static readonly NOT_SPECIAL_CHARACTERS_AND_DIGITS_WITHOUT_DASH: RegExp = /^[^\d!@#$%^&*()_+=\[\]{};':"\\|,.<>\/?]+$/;
}

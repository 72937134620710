import { Component, Input } from '@angular/core';

@Component({
  selector: 'adamp-loader', // eslint-disable-line
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.sass']
})
export class LoaderComponent {
  @Input() customStyleParent: { [index: string]: string };
  @Input() customStyleChild: { [index: string]: string };
}

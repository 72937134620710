import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { RegistrationStepI } from '../../../guards/registration-step.guard';
import { RegistrationService } from '../../../services/registration.service';
import { NavigationStart, Router } from '@angular/router';
import { GlobalLoaderService } from '../../shared/global-loader/global-loader.service';
import { FormGroupUtilService } from '../../../services/form-group-util.service';
import { InvitationRegistrationService } from '../../../services/invitation-registration.service';
import { CompanyDocument } from '../../company-data/model/company-document';
import { map, mergeMap, tap } from 'rxjs/operators';
import { FileUtilService } from '../../../services/file-util.service';
import { CompanyData } from '../../company-data/company-data';
import { DateParserService } from '../../../services/date-parser.service';
import { UserData } from '../../user-data/user-data';
import { UserTermsData } from '../user-terms-data';
import { InvitationRegistrationRequest } from '../../../services/dto/request/invitation-registration-request';
import { RegisterResponseDto } from '../../../services/dto/register-response-dto';
import { S3UploadService } from '../../../services/s3-upload.service';
import { CarrierDocumentsService } from '../../../services/carrier-documents.service';
import {
  FileMetadataWithKey,
  FileToUpload,
  FileWithMetadata,
  ConfirmUploadCompanyFiles
} from '../../../services/dto/request/confirm-upload-company.files';
import { RegisterCompanyRequest } from '../../../services/dto/register-company-request';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-terms-content',
  templateUrl: './terms-content.component.html',
  styleUrls: ['./terms-content.component.sass']
})
export class TermsContentComponent implements OnInit, RegistrationStepI, OnDestroy {
  formGroup: FormGroup;
  shouldActivateAlertsOnHover = false;
  @Input() invitationMode = false;
  @Input() invitationId: string;
  @Input() companyName: string;
  private subscriptions: Subscription[] = [];
  allCheckboxesList: (AbstractControl | null)[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private registrationService: RegistrationService,
    private router: Router,
    private globalLoaderService: GlobalLoaderService,
    private formGroupUtilService: FormGroupUtilService,
    private invitationRegistrationService: InvitationRegistrationService,
    private fileUtilService: FileUtilService,
    private dateParser: DateParserService,
    private s3UploadService: S3UploadService,
    private carrierDocumentsService: CarrierDocumentsService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      termsAndConditions: [this.registrationService.userTermsData.termsAndConditions, Validators.requiredTrue],
      privacyPolicy: [this.registrationService.userTermsData.privacyPolicy, Validators.requiredTrue],
      transferPersonalData: [this.registrationService.userTermsData.transferPersonalData, Validators.requiredTrue],
      processingPersonalData: [this.registrationService.userTermsData.processingPersonalData, Validators.requiredTrue],
      useEmailAddress: [this.registrationService.userTermsData.useEmailAddress],
      usePhoneNumber: [this.registrationService.userTermsData.usePhoneNumber]
    });
    this.setNavigationChangeSubscriber();
    setTimeout(() => {
      this.shouldActivateAlertsOnHover = true;
    }, 3000);

    for (const controlKey in this.formGroup.controls) {
      this.allCheckboxesList.push(this.formGroup.get(controlKey));
    }
  }

  setNavigationChangeSubscriber(): void {
    this.subscriptions.push(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.setState();
        }
      })
    );
  }

  createAccount() {
    this.globalLoaderService.show();
    this.setState();
    const roles = this.registrationService.selectedRoles;
    const userData = this.registrationService.userData;
    const companyData = this.registrationService.companyData;
    const userTerms = this.registrationService.userTermsData;
    let documentsToUpload: FileWithMetadata[];
    if (!this.registrationService.companyData.shouldUploadCompanyDocuments) {
      documentsToUpload = [];
    } else {
      documentsToUpload = this.buildDocumentsToUpload();
    }
    let registerResponseDto: RegisterResponseDto;
    let filesToUpload: FileToUpload[] = [];
    this.register(companyData, roles, documentsToUpload.length, userData, userTerms)
      .pipe(
        tap((response: RegisterResponseDto) => (registerResponseDto = response)),
        map((result: RegisterResponseDto) => this.mapToFilesToUpload(result, documentsToUpload)),
        tap((mappedFiles: FileToUpload[]) => (filesToUpload = mappedFiles)),
        mergeMap((filesToUpload: FileToUpload[]) => {
          const uploadObservables: Observable<string>[] = [];
          filesToUpload.forEach((file: FileToUpload) => uploadObservables.push(this.s3UploadService.uploadFile(file)));
          if (uploadObservables.length === 0) {
            return of([]);
          }
          return forkJoin(uploadObservables);
        }),
        mergeMap((uploadResponses: string[]) => {
          if (uploadResponses.length === 0) {
            return of('no files to upload');
          }
          return this.carrierDocumentsService.confirmUploadCarrierDocumentsRegistration(
            {
              filesMetadata: filesToUpload.map((file) => {
                return {
                  key: file.key,
                  fileName: file.fileName,
                  companyFileType: file.companyFileType,
                  validUntil: file.validUntil
                } as FileMetadataWithKey;
              })
            } as ConfirmUploadCompanyFiles,
            registerResponseDto.companyId
          );
        })
      )
      .subscribe(
        (confirmUploadFilesResponse: string | null) => {
          this.globalLoaderService.hide();
          if (registerResponseDto.register) {
            this.registrationService.accountCreated = true;
            this.router.navigate(['confirmation']);
          }
        },
        () => {
          this.globalLoaderService.hide();
        }
      );
  }

  private buildDocumentsToUpload() {
    return [
      this.registrationService.companyData.licenceDocument,
      this.registrationService.companyData.insuranceDocument
    ]
      .filter((file: CompanyDocument) => file.file != null)
      .map((document: CompanyDocument) => {
        return {
          companyFileType: document.fileType,
          fileName: document.file?.name,
          validUntil: this.dateParser.parseDate(<Date>document?.validUntil) + 'T23:59:59Z',
          file: document.file
        } as FileWithMetadata;
      });
  }

  private mapToFilesToUpload(response: RegisterResponseDto, documentsToUpload: FileWithMetadata[]): FileToUpload[] {
    const filesToUpload: FileToUpload[] = [];
    if (!response.register || response.tempUrls.length !== documentsToUpload.length) {
      throw new Error('registration result: ' + response + ', number of files to upload: ' + documentsToUpload.length);
    }
    for (let i = 0; i < documentsToUpload.length; i++) {
      const tempUrl = response.tempUrls[i];
      const uploadedFile = documentsToUpload[i];
      if (uploadedFile != null) {
        filesToUpload.push({
          companyFileType: uploadedFile.companyFileType,
          key: tempUrl.key,
          url: tempUrl.url,
          fileName: uploadedFile.fileName,
          file: uploadedFile.file,
          validUntil: uploadedFile.validUntil
        });
      }
    }
    return filesToUpload;
  }

  private register(
    companyData: CompanyData,
    roles: string[],
    numberOfFilesToUpload: number,
    userData: UserData,
    userTermsData: UserTermsData
  ): Observable<RegisterResponseDto> {
    return this.registrationService.register({
      companyDto: {
        taxId: companyData.companyTaxId,
        types: roles,
        name: companyData.companyName,
        country: companyData.countryData?.countryCode,
        streetNameAndNumber: companyData.streetNameAndNumber,
        postalCode: companyData.postCode,
        city: companyData.city,
        user: {
          fullName: userData.fullName,
          email: userData.email,
          countryCode: userData.countryPhoneCode,
          phoneNumber: userData.phoneNumber,
          language: this.translate.currentLang,
          password: userData.password,
          userTerms: userTermsData
        } as UserDto
      } as CompanyDto,
      numberOfFilesToUpload: numberOfFilesToUpload
    } as RegisterCompanyRequest);
  }

  createUser(): void {
    this.globalLoaderService.show();
    this.setState();
    const registerUserRequest: InvitationRegistrationRequest = {
      invitationId: this.invitationId,
      userName: this.registrationService.userData.fullName,
      roles: this.registrationService.userData?.role || [],
      countryCode: this.registrationService.userData.countryPhoneCode,
      number: this.registrationService.userData.phoneNumber,
      language: this.translate.currentLang,
      password: this.registrationService.userData.password,
      userTerms: this.registrationService.userTermsData
    };
    this.invitationRegistrationService.registerUser(registerUserRequest).subscribe(
      () => {
        this.globalLoaderService.hide();
        this.router.navigate(['confirmation-invitation'], { state: { companyName: this.companyName } });
      },
      (error) => {
        this.globalLoaderService.hide();
        console.error(error);
        throw new Error(error.error.message);
      }
    );
  }

  getForm(): FormGroup {
    return this.formGroup;
  }

  isFormGroupPending(): boolean {
    return this.formGroup.pending;
  }

  setState(): void {
    this.registrationService.userTermsData = {
      termsAndConditions: this.formGroup.controls['termsAndConditions'].value,
      privacyPolicy: this.formGroup.controls['privacyPolicy'].value,
      transferPersonalData: this.formGroup.controls['transferPersonalData'].value,
      processingPersonalData: this.formGroup.controls['processingPersonalData'].value,
      useEmailAddress: this.formGroup.controls['useEmailAddress'].value,
      usePhoneNumber: this.formGroup.controls['usePhoneNumber'].value
    };
    if (this.invitationMode) {
      this.invitationRegistrationService.validSteps[1] = this.formGroup.valid;
    } else this.registrationService.validSteps[2] = this.formGroup.valid;
  }

  highlightInvalidFields(): void {
    if (this.shouldActivateAlertsOnHover) {
      this.formGroupUtilService.markAllFieldsAsDirtyAndUpdateValueAndValidity(this.getForm());
    }
  }

  changeAllValues(checked: boolean): void {
    for (const checkbox of this.allCheckboxesList) {
      checkbox?.setValue(checked);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }
}

export interface CompanyDto {
  taxId: string;
  types: string[];
  name: string;
  country: string;
  streetNameAndNumber: string;
  postalCode: string;
  city: string;
  user: UserDto;
}

export interface UserDto {
  fullName: string;
  email: string;
  countryCode: string;
  phoneNumber: string;
  password: string;
  language: string;
  userTerms: UserTermsDto;
}

export interface UserTermsDto {
  termsAndConditions: boolean;
  privacyPolicy: boolean;
  transferPersonalData: boolean;
  processingPersonalData: boolean;
  useEmailAddress: boolean;
  usePhoneNumber: boolean;
}

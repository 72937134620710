import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpRequestChecker } from './services/http-request-checker.service';

@Injectable()
export class HttpInterceptorImpl implements HttpInterceptor {
  constructor(public httpRequestChecker: HttpRequestChecker) {}

  // eslint-disable-next-line
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.httpRequestChecker.start();
    return next.handle(request).pipe(
      finalize(() => {
        this.httpRequestChecker.stop();
      })
    );
  }
}

import { CountryData } from '../../services/dto/country-data.model';
import { CompanyDocument } from './model/company-document';

export class CompanyData {
  constructor(
    public companyTaxId: string = '',
    public countryData: CountryData | null = null,
    public companyName: string = '',
    public streetNameAndNumber: string = '',
    public postCode: string = '',
    public city: string = '',
    public shouldUploadCompanyDocuments: boolean = false,
    public insuranceDocument: CompanyDocument = {
      file: null,
      fileType: 'INSURANCE',
      validUntil: ''
    },
    public licenceDocument: CompanyDocument = {
      file: null,
      fileType: 'LICENCE',
      validUntil: ''
    }
  ) {}
}

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CompaniesShipperResponse } from './dto/response/companies-shipper-response';
import { OffersCompaniesShipperResponse } from './dto/response/offers-companies-shipper-response';

@Injectable({
  providedIn: 'root'
})
export class ShipperService {
  private apiUrl = environment.config.apiUrl;
  private getCompaniesShipperEndpoint = environment.config.getCompaniesShipperEndpoint;
  private getOffersCompaniesShipperEndpoint = environment.config.getOffersCompaniesShipperEndpoint;

  constructor(private httpClient: HttpClient) {}

  public getCompaniesShipper(shipperCompanyName: string): Observable<CompaniesShipperResponse[]> {
    const queryParams = this.convertToQueryParams(shipperCompanyName);
    return this.httpClient.get<CompaniesShipperResponse[]>(`${this.apiUrl}${this.getCompaniesShipperEndpoint}`, {
      params: queryParams
    });
  }

  public getOffersCompaniesShipper(shipperCompanyName: string): Observable<OffersCompaniesShipperResponse> {
    const queryParams = this.convertToQueryParams(shipperCompanyName);
    return this.httpClient.get<OffersCompaniesShipperResponse>(`${this.apiUrl}${this.getOffersCompaniesShipperEndpoint}`, {
      params: queryParams
    });
  }

  private convertToQueryParams(shipperCompanyName: string) {
    let params = new HttpParams();
    params = params.append('shipperCompanyName', shipperCompanyName);
    return params;
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class CookieBarService {
  private readonly COOKIE_NAME: string = 'cookie_accepted';

  private policyCookie: BehaviorSubject<boolean>;
  policyCookieObs: Observable<boolean>;

  constructor(private cookieService: CookieService) {
    this.policyCookie = new BehaviorSubject<boolean>(this.policyCookiePresent());
    this.policyCookieObs = this.policyCookie.asObservable();
  }

  private policyCookiePresent(): boolean {
    return this.cookieService.check(this.COOKIE_NAME);
  }

  acceptCookiePolicy(): void {
    this.cookieService.set(this.COOKIE_NAME, 'true', {expires: 365});
    this.policyCookie.next(true);
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NotificationDto } from '../../../../../services/dto/notification.model';
import {
  NotificationDeleteAction,
  NotificationModalAction,
  NotificationModalService
} from './notification-modal.service';
import { NotificationService } from '../../../../../services/notification.service';
import { Subscription } from 'rxjs';
import { NotificationIconService } from '../notification-icon.service';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.sass']
})
export class NotificationModalComponent implements OnInit, OnDestroy {
  notifications: NotificationDto[] = [];
  pendingUnread = 0;

  private sub: Subscription = new Subscription();

  constructor(
    private notificationModalService: NotificationModalService,
    private notificationService: NotificationService,
    private notificationIconService: NotificationIconService
  ) {}

  ngOnInit(): void {
    this.getNotifications();
  }

  getNotifications(): void {
    this.sub.add(
      this.notificationService.notifications.subscribe((resp) => {
        this.notifications = resp.notifications;
        this.pendingUnread = resp.allPending;
      })
    );
  }

  additionalNotificationsRouteAction(): void {
    this.notificationIconService.hideModal();
  }

  tileActionBtnClicked(link: string, id: string, creationTime: string): void {
    const action: NotificationModalAction = {
      id: id,
      creationTime: creationTime,
      notifications: this.notifications,
      allPending: this.pendingUnread,
      link: link
    };
    this.notificationModalService.tileActionBtnClicked(action);
  }

  markReadBtnClicked(id: string, creationTime: string): void {
    const action: NotificationModalAction = {
      id: id,
      creationTime: creationTime,
      notifications: this.notifications,
      allPending: this.pendingUnread
    };
    this.notificationModalService.markReadBtnClicked(action);
  }

  deleteNotification(id: string, creationTime: string): void {
    const action: NotificationDeleteAction = {
      id: id,
      creationTime: creationTime
    };
    this.notificationModalService.deleteNotification(action);
  }

  get notificationsCount(): number {
    return this.pendingUnread - this.notifications.length;
  }

  trackNotification(index: number, notification: NotificationDto): string {
    return index + notification.id + notification.status;
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}

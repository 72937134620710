/* eslint-disable @angular-eslint/no-host-metadata-property */
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { MenuItem } from 'primeng/api';
import { TieredMenu } from 'primeng/tieredmenu';
import { ButtonDirective } from 'primeng/button';

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated,
  styleUrls: ['./dropdown-button.component.sass'],
  host: {
    class: 'p-element'
  }
})
export class DropdownButtonComponent {
  @Input() model: MenuItem[];
  @Input() icon: string;
  @Input() iconPos: ButtonDirective['iconPos'] = 'left';
  @Input() label: string;
  @Output() onDropdownClick: EventEmitter<any> = new EventEmitter();
  @Input() style: any;
  @Input() styleClass: string;
  @Input() buttonStyle: any = 'regular-text p-text-nowrap p-m-0 a-low-height-button';
  @Input() menuStyle: any;
  @Input() menuStyleClass: string;
  @Input() disabled: boolean;
  @Input() appendTo: any = 'body';
  @Input() expandAriaLabel: string;
  @Input() showTransitionOptions = '.12s cubic-bezier(0, 0, 0.2, 1)';
  @Input() hideTransitionOptions = '.1s linear';
  @ViewChild('container') containerViewChild: ElementRef;
  @ViewChild('menu') menu: TieredMenu;

  onDropdownButtonClick(event: Event): void {
    event.preventDefault();
    this.onDropdownClick.emit(event);
    this.menu.toggle({ currentTarget: this.containerViewChild.nativeElement, relativeAlign: this.appendTo == null });
  }
}

<ng-container [formGroup]="form">
  <div class="adamp-password">
    <p-password
      #passwordInput
      [feedback]="false"
      id="password-input"
      [toggleMask]="false"
      [formControlName]="controlName"
      class="adamp-password"
      [class.warning-border-color]="
        error ||
        ((form.controls[controlName].dirty || form.controls[controlName].touched) && form.controls[controlName].errors)
      "
    ></p-password>

    <div class="password-toggle-image">
      <img
        src="assets/icons/20px/{{ iconType }}-{{ form.controls[controlName].value.length > 0 ? 'black' : 'gray' }}.svg"
        (click)="changeType()"
      />
    </div>
  </div>

  <div
    class="alert g-mt-8"
    *ngIf="
      (form.controls[controlName].dirty || form.controls[controlName].touched) &&
      (form.controls[controlName].errors || form.errors)
    "
  >
    <small
      class="p-error"
      *ngIf="form.controls[controlName].hasError('required')"
    >
      {{ 'common.alerts.required' | translate }}
    </small>
    <small
      id="password-pattern"
      class="p-error"
      *ngIf="form.controls[controlName].hasError('pattern')"
    >
      {{ 'common.alerts.notValid' | translate: { name: 'common.fieldNames.password' | translate } }}
    </small>
    <small
      id="repeat-match"
      class="p-error"
      *ngIf="controlName === 'repeatPassword' && form.hasError('mismatchedPassword')"
    >
      {{ 'userData.alerts.mustMatch' | translate: { fieldName: 'common.fieldNames.passwords' | translate } }}
    </small>
  </div>
</ng-container>

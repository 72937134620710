import { Pipe, PipeTransform } from '@angular/core';
import { COUNTRIES, Country } from '../../model/domain/countries';

@Pipe({
  name: 'countryCode'
})
export class CountryCodePipe implements PipeTransform {
  transform(name: string | undefined): string {
    if (name === undefined || !name || !name.length) {
      return '';
    }

    if (name.length === 2) {
      return name.toLowerCase();
    }

    const country = COUNTRIES.find((country: Country) => country.code3 === name);

    return country ? country.code.toLowerCase() : name.length > 1 ? name.substring(0, 2).toLowerCase() : name;
  }
}

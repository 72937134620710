import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormGroupUtilService {
  public markAllFieldsAsTouched(form: FormGroup): void {
    form.markAllAsTouched();
  }

  public markAllFieldsAsDirtyAndUpdateValueAndValidity(form: FormGroup): void {
    this.markAllFieldsAsDirty(form);
    form.updateValueAndValidity();
  }

  public markAllFieldsAsDirty(form: FormGroup): void {
    for (const field in form.controls) {
      form.controls[field].markAsDirty();
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyPrilo'
})
export class CurrencyPriloPipe implements PipeTransform {
  transform(value: number | undefined, withSymbol: boolean = true): any {
    if(value === undefined || value === null){
      return '';
    }

    let options: any = {
      style: 'currency',
      currency: 'EUR',
    }

    if (value % 1 == 0) {
      options = {
        ...options,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }
    } else {
      options = {
        ...options,
        minimumIntegerDigits: 1,
      }
    }

    const currencyValueFormatted = new Intl.NumberFormat('en-GB', options).format(value);

    return currencyValueFormatted;
  }
}

<app-registration [activeTab]="0"></app-registration>

<div class="a-page-registration-container">
  <div class="body-large-bold">{{ 'companyData.title' | translate }}</div>

  <div class="description-regular mono-font">{{ 'common.allFieldsAreMandatory' | translate }}</div>
  <form
    id="user-input-container"
    [formGroup]="companyDataForm"
    (ngSubmit)="nextStep()"
    autocomplete="off"
  >
    <div class="grid">
      <div [ngClass]="registrationService.responsiveClasses.label">
        <p class="body-bold">
          {{ 'companyData.country' | translate }}
        </p>
      </div>
      <div
        *ngIf="allCountries | async as countries"
        [ngClass]="registrationService.responsiveClasses.input"
      >
        <p-autoComplete
          [suggestions]="filteredCountries"
          (completeMethod)="searchCountry($event, countries)"
          [dropdown]="true"
          [forceSelection]="true"
          field="translation"
          placeholder="{{ 'companyData.countryPlaceHolder' | translate }}"
          formControlName="country"
        >
        </p-autoComplete>

        <span
          class="p-error"
          *ngIf="(form.country.dirty || form.country.touched) && form.country.errors"
        >
          <small *ngIf="form.country.hasError('required')">
            {{ 'common.alerts.required' | translate }}
          </small>
        </span>
      </div>

      <div [ngClass]="registrationService.responsiveClasses.label">
        <p class="body-bold g-mb-0">
          {{ 'companyData.companyTaxId' | translate }}
        </p>

        <div
          *ngIf="companyDataForm.get('country')?.value?.isViesCountry"
          class="description-regular py-1"
          [innerHTML]="'companyData.companyTaxIdViesInfo' | translate"
        ></div>
      </div>
      <div [ngClass]="registrationService.responsiveClasses.input">
        <adamp-input-loader
          [form]="companyDataForm"
          [controlName]="companyTaxIdControlName"
          [controlId]="'tax-id-input'"
          [isLoading]="isHttpRequestPending || autoFillFromViesPending"
          [placeholder]="
            !companyDataForm.get('country')?.value?.isViesCountry
              ? ('companyData.companyTaxIdPlaceHolder' | translate)
              : ('companyData.example' | translate) + ' ' + companyDataForm.get('country')?.value?.viesPlaceholder
          "
        ></adamp-input-loader>

        <span
          class="p-error"
          *ngIf="(form.companyTaxId.dirty || form.companyTaxId.touched) && form.companyTaxId.errors"
        >
          <small
            *ngIf="form.companyTaxId.hasError('pattern')"
            [innerHTML]="'companyData.alerts.viesCountryInvalid' | translate"
          >
          </small>
          <small *ngIf="form.companyTaxId.hasError('required')">
            {{ 'common.alerts.required' | translate }}
          </small>
          <small *ngIf="form.companyTaxId.hasError('companyExists')">
            {{ 'companyData.alerts.companyExists' | translate }}
          </small>
          <small *ngIf="form.companyTaxId.hasError('minlength')">
            {{ 'common.alerts.minLength' | translate: { value: companyTaxIdMinLength } }}
          </small>
          <small *ngIf="form.companyTaxId.hasError('maxlength')">
            {{ 'common.alerts.maxLength' | translate: { value: companyTaxIdMaxLength } }}
          </small>
          <small *ngIf="form.companyTaxId.hasError('onlyWhiteCharacters')">
            {{ 'common.alerts.invalidValue' | translate }}
          </small>
        </span>

        <div class="flex justify-content-end mt-3 md:mt-2">
          <p-button
            [disabled]="!shouldEnableAutofillFromVies()"
            (onClick)="onAutoFillFromViesClick()"
            class="no-wrap small-dark"
            label="{{ 'companyData.autoFillFromVies' | translate }}"
          ></p-button>
        </div>
      </div>
      <div [ngClass]="registrationService.responsiveClasses.label">
        <p class="body-bold">
          {{ 'companyData.companyName' | translate }}
        </p>
      </div>
      <div [ngClass]="registrationService.responsiveClasses.input">
        <input
          id="company-name-input"
          placeholder="{{ 'companyData.companyNamePlaceHolder' | translate }}"
          type="text"
          pInputText
          formControlName="companyName"
        />

        <span
          class="p-error"
          *ngIf="(form.companyName.dirty || form.companyName.touched) && form.companyName.errors"
        >
          <small *ngIf="form.companyName.hasError('required')">
            {{ 'common.alerts.required' | translate }}
          </small>
          <small *ngIf="form.companyName.hasError('minlength')">
            {{ 'common.alerts.minLength' | translate: { value: companyNameMinLength } }}
          </small>
          <small *ngIf="form.companyName.hasError('maxlength')">
            {{ 'common.alerts.maxLength' | translate: { value: companyNameMaxLength } }}
          </small>
          <small *ngIf="form.companyName.hasError('onlyWhiteCharacters')">
            {{ 'common.alerts.invalidValue' | translate }}
          </small>
        </span>
      </div>

      <div [ngClass]="registrationService.responsiveClasses.label">
        <p class="body-bold">
          {{ 'companyData.streetNameAndNumber' | translate }}
        </p>
      </div>
      <div [ngClass]="registrationService.responsiveClasses.input">
        <input
          id="street-name-and-number-input"
          placeholder="{{ 'companyData.streetNameAndNumberPlaceHolder' | translate }}"
          type="text"
          pInputText
          formControlName="streetNameAndNumber"
        />

        <span
          class="p-error"
          *ngIf="
            (form.streetNameAndNumber.dirty || form.streetNameAndNumber.touched) && form.streetNameAndNumber.errors
          "
        >
          <small *ngIf="form.streetNameAndNumber.hasError('required')">
            {{ 'common.alerts.required' | translate }}
          </small>
          <small *ngIf="form.streetNameAndNumber.hasError('minlength')">
            {{ 'common.alerts.minLength' | translate: { value: streetNameAndNumberMinLength } }}
          </small>
          <small *ngIf="form.streetNameAndNumber.hasError('maxlength')">
            {{ 'common.alerts.maxLength' | translate: { value: streetNameAndNumberMaxLength } }}
          </small>
          <small *ngIf="form.streetNameAndNumber.hasError('onlyWhiteCharacters')">
            {{ 'common.alerts.invalidValue' | translate }}
          </small>
        </span>
      </div>

      <div class="col-12 md:col-4 xl:col-3">
        <p class="body-bold">
          {{ 'companyData.postCode' | translate }}
        </p>
      </div>
      <div class="col-12 md:col-8 xl:col-3 input-helper">
        <input
          id="post-code-input"
          placeholder="{{ 'companyData.postCodePlaceHolder' | translate }}"
          type="text"
          [style]="{ width: '100%' }"
          pInputText
          formControlName="postCode"
        />

        <span
          class="p-error"
          *ngIf="(form.postCode.dirty || form.postCode.touched) && form.postCode.errors"
        >
          <small *ngIf="form.postCode.hasError('required')">
            {{ 'common.alerts.required' | translate }}
          </small>
          <small *ngIf="form.postCode.hasError('minlength')">
            {{ 'common.alerts.minLength' | translate: { value: postCodeMinLength } }}
          </small>
          <small *ngIf="form.postCode.hasError('maxlength')">
            {{ 'common.alerts.maxLength' | translate: { value: postCodeMaxLength } }}
          </small>
          <small *ngIf="form.postCode.hasError('onlyWhiteCharacters')">
            {{ 'common.alerts.invalidValue' | translate }}
          </small>
        </span>
      </div>

      <div class="col-12 md:col-4 xl:col-2 p-text-xl-center">
        <p class="body-bold">
          {{ 'companyData.city' | translate }}
        </p>
      </div>
      <div class="col-12 md:col-8 xl:col-4 input-helper">
        <input
          id="city-input"
          placeholder="{{ 'companyData.cityPlaceholder' | translate }}"
          type="text"
          pInputText
          formControlName="city"
          [style]="{ width: '100%' }"
        />

        <span
          class="p-error"
          *ngIf="(form.city.dirty || form.city.touched) && form.city.errors"
        >
          <small *ngIf="form.city.hasError('required')">
            {{ 'common.alerts.required' | translate }}
          </small>
          <small *ngIf="form.city.hasError('minlength')">
            {{ 'common.alerts.minLength' | translate: { value: cityMinLength } }}
          </small>
          <small *ngIf="form.city.hasError('maxlength')">
            {{ 'common.alerts.maxLength' | translate: { value: cityMaxLength } }}
          </small>
          <small *ngIf="form.city.hasError('onlyWhiteCharacters')">
            {{ 'common.alerts.invalidValue' | translate }}
          </small>
        </span>
      </div>

      <div
        class="col 12 g-pl-0 g-pr-0 mt-4 xl:mt-6"
        *ngIf="_shouldShowCompanyDocuments"
      >
        <p-divider class="primary-purple"></p-divider>
        <div>
          <div class="grid">
            <div class="col-12 g-pt-0">
              <p class="body-large-bold g-pb-30 g-mb-0 g-mt-40">
                {{ 'companyData.documents.companyDocuments' | translate }}
              </p>
            </div>
            <div class="col-12">
              <div class="grid g-pt-0">
                <div class="flex align-items-center">
                  <p-checkbox
                    inputId="uploadCompanyDocumentsCheckbox"
                    formControlName="uploadCompanyDocumentsCheckbox"
                    [binary]="true"
                    label=" {{ 'companyData.documents.defaultCompanyDocumentsLabel' | translate }}"
                    labelStyleClass="g-pt-4 pointer g-ml-0 g-pl-10"
                  ></p-checkbox>

                  <img
                    src="assets/icons/15px/info.svg"
                    class="ml-2 pointer g-pb-0"
                    [ngbTooltip]="paymentTooltipContent"
                    placement="right auto"
                    triggers="hover:blur"
                  />
                  <ng-template #paymentTooltipContent>
                    <div [innerHTML]="'companyData.documents.defaultCompanyDocumentsTooltip' | translate"></div>
                  </ng-template>
                </div>
              </div>
            </div>
            <div
              *ngIf="this.companyDataForm.controls['uploadCompanyDocumentsCheckbox'].value"
              class="col-12"
            >
              <p class="body g-mt-30 g-mb-0">
                {{ 'companyData.documents.tip' | translate }}
              </p>
              <app-upload-company-document
                [companyDocument]="registrationService.companyData.insuranceDocument"
                [fileForm]="_insuranceFormGroup"
                [title]="'companyData.documents.insurance' | translate"
                [chooseLabel]="'companyData.documents.insurancePlaceholder' | translate"
                [tooltipContent]="'companyData.documents.insuranceTooltip' | translate"
                (companyDocumentChanged)="companyDocumentChangedHandler($event)"
              ></app-upload-company-document>
              <app-upload-company-document
                [companyDocument]="registrationService.companyData.licenceDocument"
                [fileForm]="_licenceFormGroup"
                [title]="'companyData.documents.licence' | translate"
                [chooseLabel]="'companyData.documents.licencePlaceholder' | translate"
                [tooltipContent]="'companyData.documents.licenceTooltip' | translate"
                (companyDocumentChanged)="companyDocumentChangedHandler($event)"
              ></app-upload-company-document>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 g-pt-40 flex justify-content-center md:justify-content-end">
        <p-button
          type="submit"
          label="{{ 'common.nextButton' | translate }}"
          class="thin-yellow"
          (mouseenter)="highlightInvalidFields()"
          [disabled]="companyDataForm.invalid || companyDataForm.pending"
        >
        </p-button>
      </div>
    </div>
  </form>
</div>

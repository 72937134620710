<div class="grid">
  <div>
    <p class="body-large-bold g-mt-30 g-mb-0">
      {{ title }}
    </p>
  </div>
  <div>
    <img
      src="assets/icons/15px/info.svg"
      class="ml-2 g-mt-30 pointer g-pb-0"
      [ngbTooltip]="paymentTooltipContent"
      placement="right auto"
      triggers="hover:blur"
    />
    <ng-template
      #paymentTooltipContent
      class="tooltip"
    >
      <div
        class="tooltip"
        [innerHTML]="tooltipContent | translate"
      ></div>
    </ng-template>
  </div>
</div>
<div>
  <div class="grid first-row g-pt-30">
    <div class="col-12 md:col-4 xl:col-3 g-pt-0">
      <p class="g-mb-0 g-mt-0 g-mt-5">
        {{ 'companyData.documents.scan' | translate }}
      </p>
    </div>
    <div class="col-12 md:col-8 xl:col-9">
      <p-fileUpload
        appCustomPFileUpload
        id="companyFile"
        *ngIf="!companyDocument.file; else elseBlock"
        name="companyFile[]"
        class="regular-form-file body-bold"
        styleClass="body-bold"
        accept=".jpg, .pdf"
        mode="basic"
        chooseIcon="pi pi-search"
        uploadLabel="Upload"
        [chooseLabel]="chooseLabel"
        [customUpload]="true"
        [multiple]="false"
        [auto]="true"
        [maxFileSize]="209715200"
        (uploadHandler)="onFileUpload($event)"
      >
      </p-fileUpload>
      <ng-template #elseBlock>
        <div class="grid justify-content-between flex-nowrap align-items-center">
          <div
            *ngIf="companyDocument.file"
            class="description-bold max-col pr-3"
            style="line-height: 100%"
          >
            <p>{{ companyDocument.file.name }}</p>
          </div>
          <div
            class="grid pointer flex-nowrap"
            (click)="clearFile()"
          >
            <div class="min-col g-pt-8">
              <img
                class="justify-content-between g-mr-8 trash-image"
                src="./assets/icons/15px/trash.svg"
                alt="delete file icon"
              />
            </div>
            <div
              class="min-col g-mr-0 description-bold white-space-nowrap"
              style="line-height: 100%"
            >
              <p>{{ 'companyData.documents.removeFile' | translate }}</p>
            </div>
          </div>
        </div>
      </ng-template>
      <p-divider class="primary-purple regular"></p-divider>
      <div validation>
        <app-file-upload-error-msgs
          [fileUpload]="fileUpload"
          [classes]="['mt-2']"
        ></app-file-upload-error-msgs>
      </div>
    </div>
  </div>
  <div
    name="second-row"
    class="grid g-pb-30 g-mt-8"
  >
    <div class="md:col-offset-4 xl:col-offset-3 g-pt-0"></div>
    <div class="col-12 md:col-8 xl:col-9 g-pt-0">
      <p
        id="acceptedFiles"
        class="description mono-font g-mt-0 g-mb-0"
      >
        {{ 'companyData.documents.acceptedFiles' | translate }}
      </p>
    </div>
  </div>
  <div class="grid">
    <div class="col-12 md:col-4 xl:col-3 g-pt-0">
      <div class="g-mt-10 g-pt-4">
        {{ 'companyData.documents.documentValidUntil' | translate }}
      </div>
    </div>
    <div
      class="col-12 md:col-3 xl:col-2 g-pt-0"
      [formGroup]="fileForm"
    >
      <p-calendar
        [firstDayOfWeek]="1"
        [showIcon]="false"
        dateFormat="dd.mm.yy"
        [minDate]="today"
        #calendar
        placeholder="{{ 'common.datePlaceholder' | translate }}"
        [selectOtherMonths]="true"
        formControlName="validUntil"
      ></p-calendar>
      <div
        class="p-error"
        *ngIf="
          (fileForm.get('validUntil')?.dirty || fileForm.get('validUntil')?.touched) &&
          fileForm.get('validUntil')?.errors
        "
      >
        <small
          class="p-error"
          *ngIf="hasControlError('validUntil', 'required')"
          >{{ 'common.alerts.required' | translate }}</small
        >
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { HereMapService, SearchResult } from '../services/map/here-map.service';
import { CountryCodePipe } from '../pipes/country-code/country-code.pipe';

export interface Range {
  label: string;
  distance: number;
}

interface Place {
  country: string;
  countryCode: string;
  displayValue: string;
  lat: number;
  lon: number;
  administrativeAreaType: string | undefined;
  localityType: string | undefined;
}

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  fullSearchResult: Place[];
  public loadingCountryCode: string;
  public unloadingCountryCode: string;

  constructor(private hereMapService: HereMapService, private countryCode: CountryCodePipe) {}

  public initRanges(): Range[] {
    return [
      { label: '+ 25 km', distance: 25 },
      { label: '+ 50 km', distance: 50 },
      { label: '+ 100 km', distance: 100 },
      { label: '+ 250 km', distance: 250 },
      { label: '+ 500 km', distance: 500 }
    ];
  }

  subscribeForFuzzyResponse(): void {
    this.hereMapService.fuzzyResponse.subscribe((val) => {
      if (val?.items) {
        this.fullSearchResult = val.items
          .filter(
            (item) =>
              item.localityType === 'city' ||
              item.localityType === 'district' ||
              item.administrativeAreaType === 'country'
          )
          .map((e) => this.mapResult(e));
      }
    });
  }

  // eslint-disable-next-line
  searchLoadingPlace(event: any): void {
    this.loadingCountryCode = '';
    this.hereMapService.search(event.query);
  }

  // eslint-disable-next-line
  searchUnloadingPlace(event: any): void {
    this.unloadingCountryCode = '';
    this.hereMapService.search(event.query);
  }

  // eslint-disable-next-line
  getLoadingPositionAndSetValues(event: any): void {
    this.loadingCountryCode = this.getCountryCodeValue(event);
  }

  // eslint-disable-next-line
  getUnloadingPositionAndSetValues(event: any): void {
    this.unloadingCountryCode = this.getCountryCodeValue(event);
  }

  private getCountryCodeValue(result: Place): string {
    return result.countryCode;
  }

  private mapResult(searchResult: SearchResult): Place {
    return {
      country: this.countryCode.transform(searchResult.address.countryName).toUpperCase(),
      countryCode: searchResult.address.countryCode,
      displayValue: this.placeDisplayValue(searchResult),
      lat: searchResult.position.lat,
      lon: searchResult.position.lng,
      administrativeAreaType: searchResult.administrativeAreaType,
      localityType: searchResult.localityType
    };
  }

  clearLoadingCountry(): void {
    this.loadingCountryCode = '';
  }

  clearUnloadingCountry(): void {
    this.unloadingCountryCode = '';
  }

  private placeDisplayValue(searchResult: SearchResult): string {
    return `${this.countryCode.transform(searchResult?.address.countryCode).toUpperCase()}, ${
      searchResult?.address?.city || searchResult?.address?.countryName
    } ${searchResult?.address?.district ? '(' + searchResult?.address?.district + ')' : ''}`;
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth/auth.service';
import { GlobalSettingsService } from './services/global-settings.service';
import { AppReloadService } from './services/app-reload.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  providers: [MessageService]
})
export class AppComponent implements OnInit {
  title = 'adampol';
  appReloadRequired = false;

  constructor(
    public auth: AuthService,
    private router: Router,
    private globalService: GlobalSettingsService,
    private appReloadService: AppReloadService
  ) {}

  changingPage(): void {
    this.globalService.updateTitleFromList(this.router.url);
  }

  private requireAppReload(): void {
    this.appReloadRequired = true;
  }

  reloadApp(): void {
    window.location.reload();
  }

  ngOnInit(): void {
    this.appReloadService.reloadNeeded$.subscribe(() => {
      this.requireAppReload();
    });
  }
}

import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { GlobalLoaderService } from './global-loader.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'adamp-global-loader', // eslint-disable-line
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlobalLoaderComponent implements OnInit {
  loaderVisible: Observable<boolean>;

  constructor(private globalLoaderService: GlobalLoaderService) {}

  ngOnInit(): void {
    this.loaderVisible = this.globalLoaderService.loaderState;
  }
}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieBarComponent } from './cookie-bar.component';
import { CookieBarService } from './cookie-bar.service';
import { ButtonModule } from 'primeng/button';
import { CookieService } from 'ngx-cookie-service';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [CookieBarComponent],
  imports: [CommonModule, ButtonModule, TranslateModule.forChild()],
  exports: [CookieBarComponent]
})
export class CookieBarModule {
  static forRoot(): ModuleWithProviders<CookieBarModule> {
    return {
      ngModule: CookieBarModule,
      providers: [CookieBarService]
    };
  }
}

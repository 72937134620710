<app-dropdown-button
  [buttonStyle]="buttonStyle"
  label="{{ 'shipmentOrder.manage.title' | translate }}"
  icon="pi pi-ellipsis-h"
  [menuStyleClass]="menuStyleClass"
  [model]="menuItems"
>
</app-dropdown-button>
<app-cancel-order-dialog
  [display]="cancelOrderDialogVisible"
  (hideEvent)="hideCancelOrderDialog()"
  (confirmCancel)="cancelOrder()"
></app-cancel-order-dialog>

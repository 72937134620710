import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Subscription, of } from 'rxjs';
import { concat } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { RegistrationService } from '../../services/registration.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.sass']
})
export class RegistrationComponent implements OnInit, OnDestroy {
  @Input() activeTab: number;
  visitedStepsIds: number[] = [];
  steps: MenuItem[] = [];
  private subscriptions: Subscription[] = [];

  constructor(private registrationService: RegistrationService, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.subscriptions.push(
      of(1).pipe(concat(this.translateService.onLangChange)).subscribe(() => {
        this.steps = [
          {
            label: this.translateService.instant('registration.companyData'),
            routerLink: '/company-data',
            styleClass: this.getStepStyleClass(0)
          },
          {
            label: this.translateService.instant('registration.userData'),
            routerLink: '/user-data',
            styleClass: this.getStepStyleClass(1)
          },
          {
            label: this.translateService.instant('registration.contractConditions'),
            routerLink: '/terms',
            styleClass: this.getStepStyleClass(2)
          },
          {
            label: this.translateService.instant('registration.confirm'),
            routerLink: '/confirmation',
            styleClass: this.getStepStyleClass(3)
          }
        ];
      })
    );
  }

  ngOnDestroy(): void {
    for (const subs of this.subscriptions) {
      subs.unsubscribe();
    }
  }


  getStepStyleClass(stepNumber: number): string {
    let classes = '';
    if (this.activeTab > stepNumber) {
      classes += ' p-steps-visited';
    }
    if (this.registrationService.validSteps[stepNumber]) {
      classes += ' valid';
    }
    return classes;
  }
}

import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { UserRole } from '../model/domain/user-role.type';

@Injectable({
  providedIn: 'root'
})
export class MenuItemsService {
  activeRoles: UserRole[];
  constructor(private auth: AuthService) {
    this.activeRoles = this.auth.getUserInfo().groups as UserRole[];
  }

  filterMenuItems(options: MenuItem[]): MenuItem[] {
    return this.loadMenuItems(options, this.activeRoles);
  }

  private loadMenuItems(options: MenuItem[], activeRoles: UserRole[]): MenuItem[] {
    return options.filter((item: MenuItem) => this.isInActiveRoles(item.visibleFor, activeRoles));
  }

  private isInActiveRoles(roles: UserRole[], activeRoles: UserRole[]): boolean {
    return activeRoles.some((active: UserRole) => roles.includes(active));
  }
}

export type MenuItem = Readonly<{
  label: string;
  icon: string;
  link: string;
  visibleFor: UserRole[];
  badgeText?: string;
  keyTranslate?: string;
}>;

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferItemComponent } from './offer-item.component';
import { TranslateModule } from '@ngx-translate/core';
import { DividerModule } from 'primeng/divider';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { LocationEntryComponent } from './components/location-entry/location-entry.component';
import { RouteItemComponent } from './components/route-item/route-item.component';
import { RouteCarsComponent } from './components/route-cars/route-cars.component';
import { OfferItemDetailsComponent } from './components/offer-item-details/offer-item-details.component';
import { OfferActionsComponent } from './components/offer-actions/offer-actions.component';
import { PriceComponent } from './components/price/price.component';
import { StatusColorPipeModule } from '../../pipes/status-color/status-color-pipe.module';
import { PriceTypePipe } from './pipes/price-type.pipe';
import { StatusCircleModule } from '../status-circle/status-circle.module';
import { CountryCodeModule } from '../../pipes/country-code/country-code.module';
import { CancelOrderDialogComponent } from '../orders-search/components/cancel-order-dialog/cancel-order-dialog.component';
import { DialogModule } from 'primeng/dialog';
import { PayCommissionWrapperModule } from '../shared/pay-commission-wrapper/pay-commission-wrapper.module';
import { DropdownButtonModule } from '../dropdown-button/dropdown-button.module';
import { SharedModule } from '../../shared/shared.module';
import { PayComponent } from './components/pay/pay.component';
import { RouterModule } from '@angular/router';
import { DateElementModule } from '../shared/date-element/date-element.module';

@NgModule({
  declarations: [
    OfferItemComponent,
    LocationEntryComponent,
    RouteItemComponent,
    RouteCarsComponent,
    OfferItemDetailsComponent,
    OfferActionsComponent,
    PriceComponent,
    PriceTypePipe,
    CancelOrderDialogComponent,
    PayComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    DividerModule,
    ButtonModule,
    CardModule,
    StatusColorPipeModule,
    StatusCircleModule,
    CountryCodeModule,
    DialogModule,
    PayCommissionWrapperModule,
    DropdownButtonModule,
    SharedModule,
    RouterModule,
    DateElementModule
  ],
  exports: [
    OfferItemComponent,
    LocationEntryComponent,
    CancelOrderDialogComponent,
    OfferItemDetailsComponent,
    RouteCarsComponent,
    OfferActionsComponent,
    PriceComponent,
    PriceTypePipe,
    RouteItemComponent
  ]
})
export class OfferItemModule {}

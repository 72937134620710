import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownButtonComponent } from './dropdown-button.component';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [DropdownButtonComponent],
  exports: [DropdownButtonComponent],
  imports: [CommonModule, DropdownModule, ButtonModule, TranslateModule, FormsModule, TieredMenuModule, RouterModule]
})
export class DropdownButtonModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WrappedTextareaComponent } from './wrapped-textarea.component';

@NgModule({
  declarations: [WrappedTextareaComponent],
  exports: [WrappedTextareaComponent],
  imports: [CommonModule]
})
export class WrappedTextareaModule {}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestChecker {
  isHttpRequestPending: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  start(): void {
    this.isHttpRequestPending.next(true);
  }

  stop(): void {
    this.isHttpRequestPending.next(false);
  }
}

import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NotificationService } from '../../../../services/notification.service';
import { NotificationIconService } from './notification-icon.service';
import { GetUnreadNotificationsForUserIdQueryResponse } from '../../../../services/dto/notification.model';

@Component({
  selector: 'app-notification-icon',
  templateUrl: './notification-icon.component.html',
  styleUrls: ['./notification-icon.component.sass']
})
export class NotificationIconComponent implements OnInit {
  notifications: Observable<GetUnreadNotificationsForUserIdQueryResponse>;
  showModal: Observable<boolean>;

  constructor(
    private notificationService: NotificationService,
    private notificationIconService: NotificationIconService
  ) {}

  ngOnInit(): void {
    this.notifications = this.notificationService.notificationState;
    this.showModal = this.notificationIconService.switchViewState;
  }

  iconClicked(unread: number | undefined): void {
    this.notificationIconService.iconClicked(!!(unread && unread > 0));
  }

  hideModal(): void {
    this.notificationIconService.hideModal();
  }
}

import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { TransferDetails } from './pay-commission-dto';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { PayCommissionOptions } from '../../services/dto/response/pay-commission-options.type';

@Injectable()
export class PayCommissionService {
  private readonly transferDetailsSub: Subject<TransferDetails> = new Subject<TransferDetails>();
  private readonly paymentEndpoint = environment.config.apiUrl + environment.config.getPaymentsEndpoint;

  constructor(private httpClient: HttpClient) {}

  get state(): Observable<TransferDetails> {
    return this.transferDetailsSub.asObservable();
  }

  getPayment(orderId: string): Observable<PayCommissionOptions> {
    return this.httpClient.get<PayCommissionOptions>(`${this.paymentEndpoint}/order/${orderId}`);
  }

  getTransferDetails(paymentId: string): void {
    this.httpClient
      .get<TransferDetails>(`${this.paymentEndpoint}/${paymentId}/transfer-details`)
      .subscribe((res) => this.transferDetailsSub.next(res));
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusColorPipe } from './status-color.pipe';

@NgModule({
  declarations: [StatusColorPipe],
  exports: [StatusColorPipe],
  imports: [CommonModule]
})
export class StatusColorPipeModule {}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { RegistrationStepI } from '../../../guards/registration-step.guard';
import { UniqueEmailValidator } from '../validators/unique-email-validator';
import { RegistrationService } from '../../../services/registration.service';
import { HttpRequestChecker } from '../../../interceptors/services/http-request-checker.service';
import { FormGroupUtilService } from '../../../services/form-group-util.service';
import { MatchValue } from '../validators/match-value-validator';
import { InvitationRegistrationService } from '../../../services/invitation-registration.service';
import { CountryService } from '../../../services/country.service';
import { CountryData } from '../../../services/dto/country-data.model';
import UserValidators from '../../../utils/input-validators/user.validators';

@Component({
  selector: 'app-user-data-form',
  templateUrl: './user-data-form.component.html',
  styleUrls: ['./user-data-form.component.sass']
})
export class UserDataFormComponent implements OnInit, OnDestroy, RegistrationStepI {
  public userDataForm: FormGroup;
  isHttpRequestPending: boolean;
  shouldActivateAlertsOnHover = false;
  emailChecked = false;
  private navigationChangeSubscription: Subscription;
  @Input() invitationMode: boolean;
  roles: string[][];
  selectedRole: string[];
  phoneCodeByCountryCode?: number;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private uniqueEmailValidator: UniqueEmailValidator,
    public registrationService: RegistrationService,
    private httpRequestChecker: HttpRequestChecker,
    private formGroupUtilService: FormGroupUtilService,
    public invitationRegistrationService: InvitationRegistrationService,
    private countryService: CountryService
  ) {}

  ngOnInit(): void {
    this.setPhoneCode();
    this.initRoles();
    this.buildUserDataForm();
    this.selectedRole = this.form.role.value;
    this.setNavigationChangeSubscriber();
    this.httpRequestChecker.isHttpRequestPending
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((isHttpRequestPending: boolean) => {
        this.isHttpRequestPending = isHttpRequestPending;
        this.emailChecked = true;
      });

    setTimeout(() => {
      this.shouldActivateAlertsOnHover = true;
    }, 3000);
  }

  initRoles(): void {
    this.roles = [['CARRIER'], ['SHIPPER'], ['CARRIER', 'SHIPPER']];
  }

  private buildUserDataForm(): void {
    this.userDataForm = this.formBuilder.group(
      {
        fullname: [this.registrationService.userData.fullName, [...UserValidators.FULL_NAME]],
        email: [
          {
            value: this.invitationMode
              ? this.invitationRegistrationService.email
              : this.registrationService.userData.email,
            disabled: this.invitationMode
          },
          {
            validators: [...UserValidators.EMAIL],
            asyncValidators: [this.uniqueEmailValidator]
          }
        ],
        repeatEmail: [this.registrationService.userData.repeatEmail],
        password: [this.registrationService.userData.password, [...UserValidators.PASSWORD_V2]],
        repeatPassword: [this.registrationService.userData.repeatPassword, [...UserValidators.REPEAT_PASSWORD]],
        countryPhoneCode: [
          this.invitationMode && !this.registrationService.userData.countryPhoneCode
            ? '+' + this.phoneCodeByCountryCode
            : this.registrationService.userData.countryPhoneCode,
          [...UserValidators.COUNTRY_PHONE_CODE]
        ],
        phoneNumber: [this.registrationService.userData.phoneNumber, [...UserValidators.PHONE_NUMBER]],
        role: [
          this.invitationMode && !this.registrationService.userData?.role
            ? this.invitationRegistrationService.roles
            : this.registrationService.userData?.role
        ]
      },
      {
        validators: [MatchValue('password', 'repeatPassword'), MatchValue('email', 'repeatEmail')],
        asyncValidators: null,
        updateOn: 'blur'
      }
    );
    if (this.invitationMode) {
      this.userDataForm.get('role')?.setValidators(Validators.required);
      this.userDataForm.get('repeatEmail')?.clearValidators();
    } else {
      this.userDataForm.get('role')?.clearValidators();
      this.userDataForm.get('repeatEmail')?.setValidators(Validators.required);
    }
  }

  get form(): { [key: string]: AbstractControl } {
    return this.userDataForm.controls;
  }

  onSubmit(): void {
    if (this.form.invalid) {
      return;
    }
    this.setState();
    if (this.invitationMode) {
      this.router.navigate(['terms-invitation']);
    } else this.router.navigate(['terms']);
  }

  getForm(): FormGroup {
    return this.userDataForm;
  }

  isFormGroupPending(): boolean {
    return this.userDataForm.pending;
  }

  setNavigationChangeSubscriber(): void {
    this.navigationChangeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.setState();
      }
    });
  }

  highlightInvalidFields(): void {
    if (this.shouldActivateAlertsOnHover) {
      this.formGroupUtilService.markAllFieldsAsDirtyAndUpdateValueAndValidity(this.getForm());
    }
  }

  private setPhoneCode(): void {
    if (this.registrationService.shouldUpdatePhoneCode) {
      this.registrationService.userData = {
        ...this.registrationService.userData,
        countryPhoneCode: this.registrationService.companyData.countryData?.phoneCode
          ? '+' + this.registrationService.companyData.countryData.phoneCode
          : ''
      };
      this.registrationService.shouldUpdatePhoneCode = false;
    }
    if (this.invitationMode && !this.phoneCodeByCountryCode) {
      this.countryService.getAllCountries().subscribe((result: CountryData[]) => {
        this.phoneCodeByCountryCode = result.filter(
          (country: CountryData) => country.countryCode === this.invitationRegistrationService.companyCountryCode
        )[0].phoneCode;
      });
    }
  }

  private setState(): void {
    this.registrationService.userData = {
      fullName: this.userDataForm.controls['fullname'].value,
      email: this.userDataForm.controls['email'].value,
      repeatEmail: this.userDataForm.controls['repeatEmail'].value,
      password: this.userDataForm.controls['password'].value,
      repeatPassword: this.userDataForm.controls['repeatPassword'].value,
      countryPhoneCode: this.userDataForm.controls['countryPhoneCode'].value,
      phoneNumber: this.userDataForm.controls['phoneNumber'].value,
      role: this.userDataForm.controls['role'] ? this.userDataForm.controls['role'].value : undefined
    };
    if (this.invitationMode) {
      this.invitationRegistrationService.validSteps[0] = this.userDataForm.valid;
    } else this.registrationService.validSteps[1] = this.userDataForm.valid;
  }

  ngOnDestroy(): void {
    this.navigationChangeSubscription.unsubscribe();
  }
}

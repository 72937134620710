import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { OrderCreationRequest, SubCommentPostRequest } from '../views/shipment-order/model/order-creation-request';
import { OrderResponseDto } from './dto/order-response-dto';
import { Observable } from 'rxjs';
import { ContactDetails } from '../views/shipment-details/model/shipment-details.model';
import { OrderDraftResponseDto } from './dto/response/order-draft-response';
import { TerminationDataResponse } from './dto/response/termination-data-response';
import { OrderEditResponseDto } from './dto/response/order-edit-response';
import { Share } from '@ngspot/rxjs/decorators';
import { map } from 'rxjs/operators';
import { Comment, Comments } from './comments.service';
import { OrderHistoryResponseDto } from './dto/response/order-history-response.dto';

@Injectable({
  providedIn: 'root'
})
export class ShipmentOrderRestService {
  private apiUrl = environment.config.apiUrl;
  private ordersEndpoint = environment.config.getOrdersEndpoint;
  private ordersEditEndpoint = environment.config.getOrdersEditEndpoint;
  private draftEndpoint = environment.config.draftEndpoint;
  private companyInfoEndpoint = environment.config.companyInfoEndpoint;
  private terminateDealEndpoint = environment.config.terminateDealEndpoint;
  private acceptTerminationEndpoint = environment.config.acceptTerminationEndpoint;
  private getTerminationDataEndpoint = environment.config.getTerminationDataEndpoint;
  private getOrderHistoryEndpoint = environment.config.getOrderHistoryEndpoint;

  constructor(private http: HttpClient) {}

  @Share()
  public confirmShipmentOrder(orderCreationRequest: OrderCreationRequest): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}${this.ordersEndpoint}`, orderCreationRequest);
  }

  public saveShipmentOrderAsDraft(orderCreationRequest: OrderCreationRequest): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}${this.draftEndpoint}`, orderCreationRequest);
  }

  public getOrderByIdWithOwnOffers(orderId: string): Observable<OrderResponseDto> {
    return this.http.get<OrderResponseDto>(`${this.apiUrl}${this.ordersEndpoint}/carrier/${orderId}`);
  }

  public getOrderById(orderId: string): Observable<OrderResponseDto> {
    return this.http.get<OrderResponseDto>(`${this.apiUrl}${this.ordersEndpoint}/${orderId}`);
  }
  public getOrderDraftById(orderId: string): Observable<OrderDraftResponseDto> {
    return this.http.get<OrderDraftResponseDto>(`${this.apiUrl}${this.ordersEndpoint}/${orderId}`);
  }

  public getOrderEditById(orderId: string): Observable<OrderEditResponseDto> {
    return this.http.get<OrderEditResponseDto>(`${this.apiUrl}${this.ordersEditEndpoint.replace(/orderId/, orderId)}`);
  }

  public postSubComment(request: SubCommentPostRequest): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/orders/sub-comment`, request);
  }

  public getCompanyInfo(orderId: string): Observable<ContactDetails> {
    return this.http.get<ContactDetails>(
      `${this.apiUrl}${this.ordersEndpoint}${this.companyInfoEndpoint.replace(/orderId/, orderId)}`
    );
  }

  public cancelOrder(orderId: string): Observable<void> {
    return this.http.patch<void>(`${this.apiUrl}/orders/${orderId}`, {});
  }

  public updateDraft(updateDraftRequest: OrderCreationRequest, orderDraftId: string): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}${this.draftEndpoint}/${orderDraftId}`, updateDraftRequest);
  }

  @Share()
  public publishDraft(publishDraftRequest: OrderCreationRequest, orderDraftId: string): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}${this.draftEndpoint}/${orderDraftId}/publish`, publishDraftRequest);
  }

  public terminateDeal(orderId: string, reason: string): Observable<void> {
    return this.http.patch<void>(`${this.apiUrl}${this.terminateDealEndpoint.replace(/orderId/, orderId)}`, {
      reason: reason
    });
  }

  public acceptTermination(orderId: string): Observable<void> {
    return this.http.patch<void>(`${this.apiUrl}${this.acceptTerminationEndpoint.replace(/orderId/, orderId)}`, {});
  }

  public getTerminationData(orderId: string): Observable<TerminationDataResponse> {
    return this.http.get<TerminationDataResponse>(
      `${this.apiUrl}${this.getTerminationDataEndpoint.replace(/orderId/, orderId)}`
    );
  }

  @Share()
  public orderHistory(orderId: string): Observable<OrderHistoryResponseDto> {
    return this.http.get<OrderHistoryResponseDto>(
      `${this.apiUrl}${this.getOrderHistoryEndpoint.replace(/orderId/, orderId)}`
    );
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { CompanyDocument } from '../model/company-document';
import { FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-upload-company-document',
  templateUrl: './upload-company-document.component.html',
  styleUrls: ['./upload-company-document.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadCompanyDocumentComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() chooseLabel: string;
  @Input() companyDocument: CompanyDocument;
  @Input() fileForm: FormGroup;
  @Input() tooltipContent: string;

  @Output()
  companyDocumentChanged: EventEmitter<CompanyDocument> = new EventEmitter();

  @ViewChild(FileUpload) fileUpload: FileUpload;

  today: Date = new Date();
  subscriptions: (Subscription | undefined)[] = [];

  ngOnInit(): void {
    this.subscriptions.push(
      this.fileForm
        .get('validUntil')
        ?.valueChanges.pipe(debounceTime(200), distinctUntilChanged())
        .subscribe((validUntil) => {
          this.emitCompanyDocumentToParent({ ...this.companyDocument, validUntil: validUntil });
        })
    );
    this.initValidators();
  }

  private initValidators(): void {
    if (this.companyDocument.file) {
      this.applyValidators();
    }
  }

  onFileUpload(event: any): void {
    this.applyValidators();
    this.emitCompanyDocumentToParent({ ...this.companyDocument, file: event.files[0] });
  }

  private applyValidators(): void {
    const validUntilControl = this.fileForm.get('validUntil');
    validUntilControl?.setValidators([Validators.required]);
    validUntilControl?.updateValueAndValidity();
  }

  clearFile(): void {
    this.clearValidators();
    this.emitCompanyDocumentToParent({ ...this.companyDocument, file: null });
  }

  private clearValidators(): void {
    const validUntilControl = this.fileForm.get('validUntil');
    validUntilControl?.clearValidators();
    validUntilControl?.updateValueAndValidity();
  }

  private emitCompanyDocumentToParent(companyDocument: CompanyDocument): void {
    this.companyDocumentChanged.emit(companyDocument);
  }

  hasControlError(fieldName: string, errorCode: string): boolean | undefined {
    const controlByName = this.fileForm.get(fieldName);
    return (controlByName?.dirty || controlByName?.touched) && controlByName?.hasError(errorCode);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s?.unsubscribe());
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Position } from '../../services/map/here-map.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-static-map',
  templateUrl: './static-map.component.html',
  styleUrls: ['./static-map.component.sass']
})
export class StaticMapComponent implements OnInit, OnDestroy {
  loadings: Position[];
  unloadings: Position[];
  private sub: Subscription;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.sub = this.route.queryParamMap.subscribe((params) => {
      this.loadings = params.getAll('loadings').map((e) => this.mapStringToPosition(e));
      this.unloadings = params.getAll('unloadings').map((e) => this.mapStringToPosition(e));
    });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private mapStringToPosition(text: string): Position {
    return { lat: Number(text.split(',')[0]), lng: Number(text.split(',')[1]) };
  }
}

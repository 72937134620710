import { RegistrationService } from '../../../services/registration.service';
import { Injectable } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UniqueEmailValidator implements AsyncValidator {
  constructor(private registrationService: RegistrationService) {}

  validate(ctrl: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return this.registrationService.isEmailExisting(ctrl.value).pipe(
      map((isExisting) => (isExisting.emailExists ? { uniqueEmail: true } : null)),
      catchError(() => of(null))
    );
  }
}

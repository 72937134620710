import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import TaxIdViesValidatorPatterns from '../../../utils/tax-id-vies-validator-patterns';
import { notOnlyWhiteCharacters } from '../../../shared/validators/not-only-white.characters';

@Injectable({ providedIn: 'root' })
export class DynamicValidatorApplier {
  companyTaxIdMinLength = 2;
  companyTaxIdMaxLength = 36;

  applyTaxIdValidatorBasedOnSelectedCountry(countryCode: string, companyTaxIdControl: AbstractControl): void {
    switch (countryCode) {
      case 'AT': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.AUSTRIA));
        break;
      }
      case 'BE': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.BELGIUM));
        break;
      }
      case 'BG': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.BULGARIA));
        break;
      }
      case 'CY': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.CYPRUS));
        break;
      }
      case 'CZ': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.CZECHIA));
        break;
      }
      case 'DE': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.GERMANY));
        break;
      }
      case 'DK': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.DENMARK));
        break;
      }
      case 'EE': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.ESTONIA));
        break;
      }
      case 'GR': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.GREECE));
        break;
      }
      case 'ES': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.SPAIN));
        break;
      }
      case 'FI': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.FINLAND));
        break;
      }
      case 'FR': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.FRANCE));
        break;
      }
      case 'HR': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.CROATIA));
        break;
      }
      case 'HU': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.HUNGARY));
        break;
      }
      case 'IE': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.IRELAND));
        break;
      }
      case 'IT': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.ITALY));
        break;
      }
      case 'LT': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.LITHUANIA));
        break;
      }
      case 'LU': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.LUXEMBOURG));
        break;
      }
      case 'LV': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.LATVIA));
        break;
      }
      case 'MT': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.MALTA));
        break;
      }
      case 'NL': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.NETHERLANDS));
        break;
      }
      case 'PL': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.POLAND));
        break;
      }
      case 'PT': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.PORTUGAL));
        break;
      }
      case 'RO': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.ROMANIA));
        break;
      }
      case 'SE': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.SWEDEN));
        break;
      }
      case 'SI': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.SLOVENIA));
        break;
      }
      case 'SK': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.SLOVAKIA));
        break;
      }
      case 'XI': {
        companyTaxIdControl.setValidators(this.buildValidators(TaxIdViesValidatorPatterns.NORTHERN_IRELAND));
        break;
      }
      default: {
        break;
      }
    }
  }

  buildValidators(pattern: string): ValidatorFn[] {
    return [
      Validators.required,
      Validators.minLength(this.companyTaxIdMinLength),
      Validators.maxLength(this.companyTaxIdMaxLength),
      Validators.pattern(pattern),
      notOnlyWhiteCharacters()
    ];
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'adamp-input-loader', // eslint-disable-line
  templateUrl: './input-loader.component.html',
  styleUrls: ['./input-loader.component.sass']
})
export class InputLoaderComponent {
  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() controlId: string;
  @Input() placeholder: string;
  @Input() isLoading = false;
  @Output() inputChanged: EventEmitter<void> = new EventEmitter<void>();

  handleInputChanged(): void {
    this.inputChanged.emit();
  }
}

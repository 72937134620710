import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FileUtilService {
  public toByteArray(file: File): Observable<number[]> {
    return new Observable((observer: Subscriber<number[]>) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.result != null && typeof reader.result !== 'string') {
          const array = new Int8Array(reader.result);
          observer.next([...array]);
        }
      };
      reader.onerror = (error: ProgressEvent<FileReader>) => {
        observer.error(error);
      };

      reader.readAsArrayBuffer(file);
    });
  }

  public toInt8ByteArray(file: File): Observable<Int8Array> {
    return new Observable((observer: Subscriber<Int8Array>) => {
      const reader = new FileReader();

      reader.onload = () => {
        if (reader.result != null && typeof reader.result !== 'string') {
          const array = new Int8Array(reader.result);
          observer.next(array);
        }
      };
      reader.onerror = (error: ProgressEvent<FileReader>) => {
        observer.error(error);
      };

      reader.readAsArrayBuffer(file);
    });
  }
}

import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-checkbox-button',
  templateUrl: './checkbox-button.component.html',
  styleUrls: ['./checkbox-button.component.sass']
})
export class CheckboxButtonComponent {
  @ViewChild('inputEl') inputEl: ElementRef;

  @Input() inputId: string;
  @Input() name: string;
  @Input() class: string;
  @Input() label: string;
  @Input() value: string;
  @Input() icon: string;

  @Output() selectedChange = new EventEmitter<string[]>();
  @Output() checkedChange = new EventEmitter<boolean>();

  selectedValues: string[];
  checkedValue = false;
  mouseOvered = false;

  toogle(): void {
    this.onChange(!this.checkedValue);
  }

  onChange(isChecked: boolean): void {
    this.checkedValue = isChecked;
    if (isChecked) {
      this.check();
    } else {
      this.uncheck();
    }
  }

  get selected(): string[] {
    return this.selectedValues;
  }

  @Input()
  set selected(val: string[]) {
    if (val.filter((obj) => obj === this.value).length > 0) {
      this.checked = true;
    }

    this.selectedValues = val;
  }

  @Input()
  set checked(val: boolean) {
    this.checkedValue = val;
  }

  private check(): void {
    this.checked = true;
    if (this.selectedValues && this.value) {
      this.selectedValues.push(this.value);
      this.selectedChange.emit(this.selectedValues);
    }
    this.checkedChange.emit(this.checkedValue);
  }

  private uncheck(): void {
    this.checked = false;
    if (this.selectedValues && this.value) {
      this.selectedValues = this.selectedValues.filter((obj) => obj !== this.value);
      this.selectedChange.emit(this.selectedValues);
    }
    this.mouseOvered = false;
    this.checkedChange.emit(this.checkedValue);
  }
}

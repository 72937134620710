<div
  class="height-100 flex align-items-center"
  *teleportTo="'sectionHeaderTeleport'"
>
  <img src="assets/logo/logo-dark.svg" />
</div>
<div class="a-page-registration-container-header">
  <h1 class="g-mt-0 g-mb-40">{{ 'signIn.label.welcomeBack' | translate }}</h1>
</div>
<div
  *ngIf="!accountBlocked"
  id="sign-in-view-container"
>
  <div class="a-page-registration-container">
    <form
      id="user-input-container"
      [formGroup]="signInForm"
      (ngSubmit)="signInUser()"
    >
      <div
        *ngIf="responseError !== false"
        id="login-alert"
        class="alert g-mb-40"
      >
        {{ alertMessage }}
      </div>
      <h3 class="g-mt-0 g-mb-12">{{ 'signIn.label.email' | translate }}</h3>
      <input
        id="email-input"
        [ngClass]="responseError ? 'warning-border-color' : ''"
        type="text"
        pInputText
        formControlName="email"
      />
      <div
        class="alert g-mt-8"
        *ngIf="(form.email.dirty || form.email.touched) && form.email.errors"
      >
        <small
          class="p-error"
          *ngIf="form.email.hasError('required')"
        >
          {{ 'common.alerts.required' | translate }}
        </small>
      </div>
      <h3 class="g-mt-30 g-mb-12">{{ 'signIn.label.password' | translate }}</h3>
      <adamp-input-password
        [form]="signInForm"
        [error]="responseError"
      ></adamp-input-password>
      <p id="forgot-password">
        <a
          routerLink="/password-recovery"
          class="description-underline"
          >{{ 'signIn.label.forgotPassword' | translate }}</a
        >
      </p>

      <div class="flex align-items-center g-mt-40">
        <div>
          <adamp-loader
            *ngIf="loading"
            [customStyleParent]="{ width: '30px', height: '30px' }"
            [customStyleChild]="{ width: '30px', height: '30px', margin: '4px', 'border-width': '4px' }"
          ></adamp-loader>
        </div>
        <div class="flex-grow-1"></div>
        <div>
          <p-button
            [disabled]="this.signInForm.invalid || loading"
            type="submit"
            label="{{ 'signIn.label.signIn' | translate }}"
            class="thin-yellow"
          ></p-button>
        </div>
      </div>
    </form>
  </div>
  <p
    id="account-question"
    class="description-underline"
  >
    <a routerLink="/role-selection">{{ 'signIn.label.accountQuestion' | translate }}</a>
  </p>
</div>
<div
  *ngIf="accountBlocked"
  id="blocked-account-container"
>
  <div class="a-page-registration-container">
    <img
      src="assets/icons/lock.svg"
      alt="account blocked"
      title="account blocked"
    />
    <div class="blocked-account-container-text">
      <p>{{ 'signIn.label.accountBlocked' | translate }}</p>
      <p>{{ 'signIn.label.contact' | translate }}</p>
    </div>
    <form>
      <p-button
        pButton
        pRipple
        type="submit"
        label="Customer Relation Manager"
        class="thin-yellow"
      ></p-button>
    </form>
  </div>
  <a
    routerLink=""
    id="account-question"
    class="description-underline"
    >{{ 'signIn.label.createAccount' | translate }}</a
  >
</div>

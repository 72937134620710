<div
  class="height-100 flex align-items-center"
  *teleportTo="'sectionHeaderTeleport'"
>
  <img src="assets/logo/logo-dark.svg" />
</div>
<div class="a-page-registration-container-header">
  <h1>{{ 'registration.title' | translate }}</h1>

  <div class="subtitle">{{ 'registration.subtitle' | translate }}</div>
</div>

<div class="a-page-registration-container">
  <h4 class="m-0">{{ 'roleSelection.title' | translate }}</h4>
  <div class="mono-font-2 mt-2">{{ 'roleSelection.description' | translate }}</div>

  <div class="formgroup-inline grid justify-content-center register-roles-selection">
    <div class="field-checkbox">
      <app-checkbox-button
        class="carrier-role"
        [(selected)]="selectedRoles"
        value="SHIPPER"
        inputId="shipper"
        name="roles"
        label="{{ 'roleSelection.registerShipper' | translate }}"
        icon="/assets/icons/shipper-role-icon.svg"
      ></app-checkbox-button>
    </div>
    <div class="field-checkbox">
      <app-checkbox-button
        class="carrier-role"
        [(selected)]="selectedRoles"
        value="CARRIER"
        inputId="carrier"
        name="roles"
        label="{{ 'roleSelection.registerCarrier' | translate }}"
        icon="/assets/icons/carrier-role-icon.svg"
      ></app-checkbox-button>
    </div>
  </div>

  <div class="grid justify-content-center md:justify-content-end">
    <p-button
      label="{{ 'common.nextButton' | translate }}"
      class="thin-yellow"
      (onClick)="goToRegistration()"
      [disabled]="!selectedRoles.length"
    ></p-button>
  </div>
</div>

<ul>
  <li>
    <a routerLink="/shipment-order">Go to shipment-order</a>
  </li>
  <li>
    <a routerLink="/carrier-offer">Go to carrier-offer</a>
  </li>
  <li>
    <a routerLink="/order-offers">Go to order-offers</a>
  </li>
  <li>
    <a routerLink="/payments-list">Go to payments-list</a>
  </li>
  <li>
    <a routerLink="/search">Go to orders search</a>
  </li>
  <li>
    <a routerLink="/offer-search">Go to offers search</a>
  </li>
  <li>
    <a routerLink="/profile-trucks">Go to profile - Trucks</a>
  </li>
  <li>
    <a routerLink="/phone-numbers">Go to phone-numbers</a>
  </li>
  <li>
    <a routerLink="/drivers">Go to drivers-list</a>
  </li>
  <li>
    <a routerLink="/notifications">Go to notifications</a>
  </li>
</ul>

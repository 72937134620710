import { Injectable, OnDestroy } from '@angular/core';
import { Observable, ReplaySubject, Subscription, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as op from 'rxjs/operators';
import hash from 'object-hash';

import { GlobalErrorHandler } from './global-error-handler.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppReloadService implements OnDestroy {
  private _reloadNeededSubject = new ReplaySubject<any>(1);
  private _timer$: Observable<any>;
  private _timerSub: Subscription;
  private _buildstamp: string | undefined;
  private _checking = false;

  reloadNeeded$: Observable<any> = this._reloadNeededSubject.asObservable().pipe(op.throttleTime(30 * 1000));

  constructor(
    private errorHandler: GlobalErrorHandler,
    private http: HttpClient
  ) {
    this.errorHandler.chunkErrorOccured$.subscribe(this.signal.bind(this));
    this._timer$ = timer(1000, environment.appReload.watchInterval);
    this._timerSub = this._timer$.subscribe(this.checkBuildstamp.bind(this));
  }

  signal(): void {
    //this._timerSub?.unsubscribe();
    //console.log('reload needed');
    this._reloadNeededSubject.next(true);
  }

  checkBuildstamp(): void {
    if (this._checking) return;

    this._checking = true;
    this.http.get('/index.html?buildstamp,' + new Date().getTime(), { responseType: 'text' }).subscribe(
      (response: string) => {
        const buildstamp = hash.sha1(response);

        if (!this._buildstamp) {
          this._buildstamp = buildstamp;
        } else if (this._buildstamp != buildstamp) {
          this.signal();
        }
      },
      (error: any) => {
        console.error(error);
      },
      () => {
        this._checking = false;
      }
    );
  }

  ngOnDestroy(): void {
    this._timerSub?.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { PrimeNGConfig } from 'primeng/api';
import Dayjs from 'dayjs';
import { Locale } from 'date-fns';

import { LANGUAGES } from '../model/domain/languages';
import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';

// load date-fns locales as needed

import { enGB, pl, lt } from 'date-fns/locale';
const dateFnsLocales = {
  en: enGB,
  pl: pl,
  lt: lt
};

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private apiUrl = environment.config.apiUrl;
  private updateUserLanguageEndpoint = environment.config.updateUserLanguage;
  private changeLanguageSource = new Subject<string>();
  private _dateFnsLocale: Locale | undefined;
  private LANGUAGE_COOKIE_NAME = 'user_language';
  private DEFAULT_LANGUAGE = 'en';

  changeLanguageMessage = this.changeLanguageSource.asObservable();
  currentLanguage$: Observable<string>;

  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private authService: AuthService,
    private cookies: CookieService,
    private primeNgConfig: PrimeNGConfig
  ) {
    this.translate.setDefaultLang(this.DEFAULT_LANGUAGE);

    const languageCodeFromUrl = new URLSearchParams(window.location.search).get('language') || undefined;
    const languageCode = languageCodeFromUrl || this.getCookieLanguage() || this.getBrowserLanguage();

    const subject = new BehaviorSubject<string>(languageCode);
    this.currentLanguage$ = subject.asObservable();
    this.changeLanguageMessage.subscribe(subject);

    this.setLanguageLocally(languageCode);
    this.forceChangeLocallyLanguageAfterUserLogin();
  }

  get dateFnsLocale(): Locale | undefined {
    return this._dateFnsLocale;
  }

  private isLanguageSupported(languageCode: string): boolean {
    return LANGUAGES.findIndex((e: any) => e.languageCode == languageCode) >= 0;
  }

  private setCookieLanguage(languageCode: string) {
    this.cookies.set(this.LANGUAGE_COOKIE_NAME, languageCode, { expires: 365, path: '/' });
  }

  private getCookieLanguage() {
    return this.cookies.get(this.LANGUAGE_COOKIE_NAME);
  }

  private updateUserLanguage(languageCode: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}${this.updateUserLanguageEndpoint}`, { language: languageCode });
  }

  private useLanguage(languageCode: string): void {
    this._dateFnsLocale = (dateFnsLocales as any)[languageCode];
    this.translate.use(languageCode).subscribe((t: any) => {
      this.primeNgConfig.setTranslation(t['primeng']);
      Dayjs.updateLocale(languageCode, t['dateAndTime']);
      Dayjs.locale(languageCode);
    });
  }

  setLanguageLocally(languageCode: string): void {
    if (!this.isLanguageSupported(languageCode)) {
      languageCode = this.DEFAULT_LANGUAGE;
    }
    this.useLanguage(languageCode);
    this.changeLanguageSource.next(languageCode);
    this.setCookieLanguage(languageCode);
  }

  setLanguage(languageCode: string): void {
    this.setLanguageLocally(languageCode);

    if (this.authService.loggedIn) {
      this.updateUserLanguage(languageCode).subscribe(
        () => {
          this.refreshUser();
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  private refreshUser() {
    this.authService.refreshUser().subscribe();
  }

  private getBrowserLanguage() {
    return (window.navigator.language || 'en-EN').split('-')[0];
  }

  private forceChangeLocallyLanguageAfterUserLogin() {
    this.authService.loggedIn$
      .pipe(
        filter((e: boolean) => e),
        first()
      )
      .subscribe(() => {
        const userLanguage = this.authService.getUserInfo().language;
        if (userLanguage) {
          this.setLanguageLocally(userLanguage);
        }
      });
  }
}

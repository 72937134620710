import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GetAllNotificationsForUserIdQueryResponse,
  GetUnreadNotificationsForUserIdQueryResponse
} from './dto/notification.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotificationHttpService {
  private readonly apiUrl = environment.config.apiUrl;
  private readonly notificationsUrl = environment.config.notificationsEndpoint;

  constructor(private httpClient: HttpClient) {}

  getUnreadNotifications(): Observable<GetUnreadNotificationsForUserIdQueryResponse> {
    return this.httpClient.get<GetUnreadNotificationsForUserIdQueryResponse>(
      `${this.apiUrl}${this.notificationsUrl}/unread/user`
    );
  }

  getAllNotifications(): Observable<GetAllNotificationsForUserIdQueryResponse> {
    return this.httpClient.get<GetAllNotificationsForUserIdQueryResponse>(`${this.apiUrl}${this.notificationsUrl}/all`);
  }

  deleteNotification(id: string, creationTime: string): Observable<string> {
    return this.httpClient.delete<string>(`${this.apiUrl}${this.notificationsUrl}/${id}/${creationTime}`, {});
  }

  markNotificationRead(id: string, creationTime: string): Observable<string> {
    return this.httpClient.post<string>(`${this.apiUrl}${this.notificationsUrl}/read/${id}/${creationTime}`, {});
  }

  markAllNofiticationsAsRead(): Observable<null> {
    return this.httpClient.post<null>(`${this.apiUrl}${this.notificationsUrl}/read/all`, {});
  }

  deleteAllNotifications(): Observable<null> {
    return this.httpClient.delete<null>(`${this.apiUrl}${this.notificationsUrl}/delete/all`, {});
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SidenavComponent } from './sidenav.component';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { RouterModule } from '@angular/router';
import { TeleportModule } from '@ngneat/overview';



@NgModule({
  declarations: [SidenavComponent, MenuItemComponent],
  exports: [SidenavComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    TeleportModule,
  ]
})
export class SidenavModule {}

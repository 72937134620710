import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ListingSearchResult } from '../listings/model/ListingSearchResult';
import { OrderFavouritesResponse } from './dto/response/order-favourites-response';
import {
  FavouriteRoute,
  FavouriteRouteRequestDto,
  FavouriteRouteResponseDto,
  FavouriteRoutesCounts
} from './dto/favourites-results';

@Injectable({
  providedIn: 'root'
})
export class FavouritesService {
  private apiUrl = environment.config.apiUrl;
  private addOrderToFavouritesEndpoint = environment.config.addOrderToFavouritesEndpoint;
  private deleteOrderFromFavouritesEndpoint = environment.config.deleteOrderFromFavouritesEndpoint;
  private getOrderFavouritesEndpoint = environment.config.getOrderFavouritesEndpoint;
  private getOrderListFavouritesEndpoint = environment.config.getOrderListFavouritesEndpoint;
  private getRouteListFavouritesEndpoint = environment.config.getRouteListFavouritesEndpoint;
  private addRouteToFavouritesEndpoint = environment.config.addRouteToFavouritesEndpoint;
  private deleteRouteFromFavouritesEndpoint = environment.config.deleteRouteFromFavouritesEndpoint;
  private toggleRouteNotificationsEnabledEndpoint = environment.config.toggleRouteNotificationsEnabledEndpoint;
  private getFavouritesRoutesCountEndpoint = environment.config.getFavouritesRoutesCountEndpoint;

  constructor(private http: HttpClient) {}

  public addOrderToFavourites(orderId: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}${this.addOrderToFavouritesEndpoint}`, {
      orderId
    });
  }

  public deleteOrderFromFavourites(orderId: string): Observable<null> {
    return this.http.delete<null>(
      `${this.apiUrl}${this.deleteOrderFromFavouritesEndpoint.replace(/orderId/, orderId)}`
    );
  }

  public getOrderFavourites(orderId: string): Observable<OrderFavouritesResponse> {
    return this.http.get<OrderFavouritesResponse>(
      `${this.apiUrl}${this.getOrderFavouritesEndpoint.replace(/orderId/, orderId)}`
    );
  }

  public getOrderListFavourites(): Observable<ListingSearchResult> {
    return this.http.get<ListingSearchResult>(`${this.apiUrl}${this.getOrderListFavouritesEndpoint}`);
  }

  public addRouteToFavourites(data: FavouriteRouteRequestDto): Observable<FavouriteRouteResponseDto> {
    return this.http.post<FavouriteRouteResponseDto>(`${this.apiUrl}${this.addRouteToFavouritesEndpoint}`, {
      ...data
    });
  }

  public deleteRouteFromFavourites(routeId: string): Observable<null> {
    return this.http.delete<null>(
      `${this.apiUrl}${this.deleteRouteFromFavouritesEndpoint.replace(/routeId/, routeId)}`
    );
  }

  public getRouteListFavourites(pageNo: number): Observable<FavouriteRouteResponseDto> {
    return this.http.get<FavouriteRouteResponseDto>(
      `${this.apiUrl}${this.getRouteListFavouritesEndpoint}?page=${pageNo}`
    );
  }

  public toggleRouteEmailNotifications(routeId: string, enabledEmailNotifications: boolean): Observable<any> {
    return this.http.patch<any>(`${this.apiUrl}${this.toggleRouteNotificationsEnabledEndpoint}`, {
      routeId,
      enabledEmailNotifications
    });
  }

  public getFavouriteRoutesCount(routes: FavouriteRoute[]): Observable<FavouriteRoutesCounts> {
    return this.http.post<FavouriteRoutesCounts>(`${this.apiUrl}${this.getFavouritesRoutesCountEndpoint}`, {
      routes: routes
    });
  }
}

<div class="relative">
  <div
    class="flex align-items-center relative background-title-container py-2 justify-content-between"
    style="width: 100%"
  >
    <div class="flex justify-content-between flex-column">
      <h2 class="my-0">
        {{ 'carrierOffer.offerInfo.label.orderId' | translate: { orderId: data.orderPresentationId } }}
      </h2>
      <div
        class="externalid flex flex-column md:flex-row"
        *ngIf="data.idSourceApp"
      >
        <span
          class="block"
          style="white-space: nowrap"
          >{{ 'carrierOffer.offerInfo.label.externalId' | translate }}
        </span>
        <span
          class="md:pl-2"
          style="word-break: break-all"
          >{{ data.idSourceApp }}</span
        >
      </div>
    </div>
    <div class="btn-actions">
      <ng-container [ngTemplateOutlet]="orderHistoryButtonTpl"></ng-container>
      <app-manage-order
        *ngIf="!data.idSourceApp"
        [data]="data"
        class="manage-order"
        [menuStyleClass]="'manage-order-menu manage-order-menu-offer-info'"
        style="order: 3"
      ></app-manage-order>
    </div>
    <div class="background-title"></div>
  </div>
  <div class="flex justify-content-between flex-row-reverse xl:hidden pt-3">
    <ng-container [ngTemplateOutlet]="orderHistoryButtonTpl"></ng-container>
    <app-manage-order
      *ngIf="!data.idSourceApp"
      [data]="data"
      [menuStyleClass]="'manage-order-menu manage-order-menu-offer-info'"
      style="order: 0"
    ></app-manage-order>
  </div>
</div>

<ng-template #orderHistoryButtonTpl>
  <app-order-history-button
    *ngIf="data.orderStatus === 'PUBLISHED' || data.orderStatus === 'EXPIRED' || data.orderStatus === 'CANCELED'"
    [orderId]="data.orderId"
    style="order: 2"
  ></app-order-history-button>
</ng-template>

<div class="a-page-registration-container">
  <form
    [formGroup]="userDataForm"
    (ngSubmit)="onSubmit()"
  >
    <div class="body-large-bold">{{ 'userData.title' | translate }}</div>

    <div class="description-regular mono-font">{{ 'common.allFieldsAreMandatory' | translate }}</div>

    <div class="grid">
      <div [ngClass]="registrationService.responsiveClasses.label">
        <p class="body-bold">{{ 'userData.fullName' | translate }}</p>
      </div>
      <div [ngClass]="registrationService.responsiveClasses.input">
        <input
          id="fullname"
          type="text"
          pInputText
          formControlName="fullname"
          placeholder="{{ 'userData.placeholder.name' | translate }}"
        />

        <span class="p-error">
          <small
            id="fullname-required"
            *ngIf="(form.fullname.dirty || form.fullname.touched) && form.fullname.hasError('required')"
            >{{ 'common.alerts.required' | translate }}</small
          >
          <small
            id="fullname-maxLength"
            *ngIf="(form.fullname.dirty || form.fullname.touched) && form.fullname.hasError('maxlength')"
            >{{
              'common.alerts.maxLength' | translate: { value: form.fullname.getError('maxlength').requiredLength }
            }}</small
          >
          <small
            id="fullname-pattern"
            *ngIf="(form.fullname.dirty || form.fullname.touched) && form.fullname.hasError('pattern_not_spaces')"
            >{{ 'common.alerts.required' | translate }}</small
          >
          <small
            id="fullname-pattern2"
            *ngIf="
              (form.fullname.dirty || form.fullname.touched) &&
              form.fullname.hasError('pattern_not_special_characters_and_digit')
            "
            >{{ 'common.alerts.onlyLettersDashSpace' | translate }}</small
          >
        </span>
      </div>

      <div [ngClass]="registrationService.responsiveClasses.label">
        <p class="body-bold">{{ 'userData.email' | translate }}</p>
      </div>
      <div [ngClass]="registrationService.responsiveClasses.input">
        <adamp-input-loader
          [form]="userDataForm"
          [controlName]="'email'"
          [controlId]="'email'"
          [isLoading]="isHttpRequestPending"
          [placeholder]="'userData.placeholder.email' | translate"
          (inputChanged)="emailChecked = false"
        ></adamp-input-loader>

        <span class="p-error">
          <small
            id="email-required"
            *ngIf="(form.email.dirty || form.email.touched) && form.email.hasError('required')"
            >{{ 'common.alerts.required' | translate }}</small
          >
          <small
            id="email-pattern"
            *ngIf="(form.email.dirty || form.email.touched) && form.email.hasError('email')"
            >{{ 'common.alerts.notValid' | translate: { name: 'common.fieldNames.emailAddress' | translate } }}</small
          >
          <small
            id="email-unique"
            *ngIf="(form.email.dirty || form.email.touched) && form.email.hasError('uniqueEmail')"
            >{{ 'userData.alerts.emailExists' | translate }}</small
          >
        </span>
      </div>

      <div
        *ngIf="!invitationMode"
        class="col-12 grid"
      >
        <div [ngClass]="registrationService.responsiveClasses.label">
          <p class="body-bold">{{ 'userData.repeatEmail' | translate }}</p>
        </div>
        <div [ngClass]="registrationService.responsiveClasses.input">
          <input
            id="repeatEmail"
            type="text"
            pInputText
            formControlName="repeatEmail"
            placeholder="{{ 'userData.placeholder.email' | translate }}"
            (paste)="(false)"
          />

          <div class="p-error">
            <small
              id="repeatEmail-required"
              *ngIf="(form.repeatEmail.dirty || form.repeatEmail.touched) && form.repeatEmail.hasError('required')"
            >
              {{ 'common.alerts.required' | translate }}
            </small>

            <small
              id="repeatEmail-match"
              *ngIf="(form.repeatEmail.dirty || form.repeatEmail.touched) && userDataForm.hasError('mismatchedEmail')"
            >
              {{ 'userData.alerts.mustMatch' | translate: { fieldName: 'common.fieldNames.emails' | translate } }}
            </small>
          </div>
        </div>
      </div>

      <div
        *ngIf="invitationMode"
        class="col-12 grid"
      >
        <div [ngClass]="registrationService.responsiveClasses.label">
          <p class="body-bold">{{ 'userData.role' | translate }}</p>
        </div>
        <div [ngClass]="registrationService.responsiveClasses.input">
          <p-dropdown
            [options]="roles"
            [disabled]="invitationMode && invitationRegistrationService.roles.length === 1"
            [autoDisplayFirst]="false"
            formControlName="role"
            inputId="role"
            [(ngModel)]="selectedRole"
          >
            <ng-template pTemplate="selectedItem">
              <div *ngIf="selectedRole">
                <div *ngIf="selectedRole.length === 1">
                  <img
                    *ngIf="selectedRole.includes('CARRIER')"
                    src="/assets/icons/10px/carrier-role-icon.svg"
                  />
                  <img
                    *ngIf="selectedRole.includes('SHIPPER')"
                    src="/assets/icons/10px/shipper-role-icon.svg"
                  />
                  {{ 'common.userRoles.' + (selectedRole[0] | lowercase) | translate }}
                </div>
                <div *ngIf="selectedRole.includes('SHIPPER') && selectedRole.includes('CARRIER')">
                  <img src="/assets/icons/10px/shipper-role-icon.svg" />
                  {{ 'userData.shipperCarrierRole' | translate }}
                  <img src="/assets/icons/10px/carrier-role-icon.svg" />
                </div>
              </div>
            </ng-template>
            <ng-template
              let-role
              pTemplate="item"
            >
              <div *ngIf="role.length === 1">
                <img
                  *ngIf="role.includes('CARRIER')"
                  src="/assets/icons/10px/carrier-role-icon.svg"
                />
                <img
                  *ngIf="role.includes('SHIPPER')"
                  src="/assets/icons/10px/shipper-role-icon.svg"
                />
                {{ 'common.userRoles.' + (role[0] | lowercase) | translate }}
              </div>
              <div *ngIf="role.includes('SHIPPER') && role.includes('CARRIER')">
                <img src="/assets/icons/10px/shipper-role-icon.svg" />
                {{ 'userData.shipperCarrierRole' | translate }}
                <img src="/assets/icons/10px/carrier-role-icon.svg" />
              </div>
            </ng-template>
          </p-dropdown>

          <div class="p-error">
            <small
              id="role-required"
              *ngIf="(form.role.dirty || form.role.touched) && form.role.hasError('required')"
            >
              {{ 'common.alerts.required' | translate }}
            </small>
          </div>
        </div>
      </div>

      <div [ngClass]="registrationService.responsiveClasses.label">
        <p class="body-bold">{{ 'userData.phone' | translate }}</p>
      </div>
      <div class="col-3 md:col-2">
        <input
          id="phone"
          type="text"
          pInputText
          formControlName="countryPhoneCode"
          placeholder="{{ 'userData.placeholder.countryPhoneCode' | translate }}"
        />

        <span class="p-error">
          <small
            id="code-required"
            *ngIf="
              (form.countryPhoneCode.dirty || form.countryPhoneCode.touched) &&
              form.countryPhoneCode.hasError('required')
            "
            >{{ 'common.alerts.required' | translate }}
          </small>

          <small
            id="code-pattern"
            *ngIf="
              (form.countryPhoneCode.dirty || form.countryPhoneCode.touched) &&
              form.countryPhoneCode.hasError('pattern')
            "
          >
            {{ 'common.alerts.notValid' | translate: { name: 'common.fieldNames.countryCode' | translate } }}
          </small>
        </span>
      </div>

      <div class="col-9 md:col-6 pl-3">
        <input
          id="number"
          type="text"
          pInputText
          formControlName="phoneNumber"
          placeholder="{{ 'userData.placeholder.number' | translate }}"
        />

        <span class="p-error">
          <small
            id="number-required"
            *ngIf="(form.phoneNumber.dirty || form.phoneNumber.touched) && form.phoneNumber.hasError('required')"
          >
            {{ 'common.alerts.required' | translate }}
          </small>
          <small
            id="number-pattern"
            *ngIf="(form.phoneNumber.dirty || form.phoneNumber.touched) && form.phoneNumber.hasError('pattern')"
          >
            {{ 'common.alerts.notValid' | translate: { name: 'common.fieldNames.phoneNumber' | translate } }}
          </small>
        </span>
      </div>

      <div [ngClass]="registrationService.responsiveClasses.label">
        <p class="body-bold">{{ 'userData.password' | translate }}</p>
      </div>
      <div [ngClass]="registrationService.responsiveClasses.input">
        <adamp-input-password
          [form]="userDataForm"
          [ngbTooltip]="tooltipContent"
          tooltipClass="ngb-mobile-tooltip-underline"
          placement="right auto"
          triggers="focus click:blur"
        ></adamp-input-password>
        <ng-template #tooltipContent>
          <app-password-tooltip [form]="userDataForm"></app-password-tooltip>
        </ng-template>
      </div>

      <div [ngClass]="registrationService.responsiveClasses.label">
        <p class="body-bold">{{ 'userData.repeatPassword' | translate }}</p>
      </div>
      <div [ngClass]="registrationService.responsiveClasses.input">
        <adamp-input-password
          [form]="userDataForm"
          [controlName]="'repeatPassword'"
        ></adamp-input-password>
      </div>
    </div>

    <div class="col-12 flex justify-content-center md:justify-content-end pt-5">
      <p-button
        type="submit"
        label="{{ 'common.nextButton' | translate }}"
        class="thin-yellow"
        (mouseenter)="highlightInvalidFields()"
        [disabled]="userDataForm.invalid || isHttpRequestPending || !emailChecked"
      ></p-button>
    </div>
  </form>
</div>

import { Component, OnInit, OnDestroy } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { MenuItem, MenuItemsService } from '../../services/menu-items.service';
import { ChatService } from '../../services/chat.service';

const chatLink = '/chat';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.sass']
})
export class SidenavComponent implements OnInit, OnDestroy {
  items: MenuItem[];
  active: boolean;
  pointerEventsNone: boolean;
  componentDestroyed = false;
  unreadChatMessagesCount = 0;
  VERSION_BUILD_TIMESTAMP: string;

  constructor(
    private menuService: MenuItemsService,
    private chatService: ChatService
  ) {}

  private options: MenuItem[] = [
    {
      label: 'menu.dashboard',
      icon: '20px/dashboard.svg',
      link: '/dashboard',
      visibleFor: ['SHIPPER', 'CARRIER']
    },
    {
      label: 'menu.marketplace',
      icon: '20px/marketplace.svg',
      link: '/search',
      visibleFor: ['SHIPPER', 'CARRIER']
    },
    {
      label: 'menu.myOrders',
      icon: '20px/car.svg',
      link: '/order-search',
      visibleFor: ['SHIPPER']
    },
    {
      label: 'menu.myOffers',
      icon: '20px/deals.svg',
      link: '/offer-search',
      visibleFor: ['CARRIER']
    },
    {
      label: 'menu.tracking',
      icon: '20px/tracking.svg',
      link: '/carrier-tracking',
      visibleFor: ['CARRIER']
    },
    {
      label: 'menu.favourites',
      icon: '20px/heart.svg',
      link: '/favourites',
      visibleFor: ['SHIPPER', 'CARRIER']
    },
    {
      label: 'menu.financialSettlements',
      icon: '20px/money.svg',
      link: '/payments-list',
      visibleFor: ['SHIPPER', 'CARRIER']
    },
    {
      label: 'menu.chat',
      icon: '20px/chat.svg',
      link: chatLink,
      visibleFor: ['SHIPPER', 'CARRIER']
    }
  ];

  getBadgeText(menuItem: MenuItem): string | undefined {
    if (menuItem.link == chatLink && this.unreadChatMessagesCount) {
      return this.unreadChatMessagesCount > 10 ? '10+' : '' + this.unreadChatMessagesCount;
    }

    return undefined;
  }

  show(): void {
    this.active = true;
  }

  hide(): void {
    this.active = false;
    this.pointerEventsNone = true;

    setTimeout(() => {
      this.pointerEventsNone = false;
    }, 500);
  }

  ngOnInit(): void {
    this.items = this.menuService.filterMenuItems(this.options);

    this.chatService.unreadMessages$.pipe(takeWhile(() => !this.componentDestroyed)).subscribe((count) => {
      this.unreadChatMessagesCount = count || 0;
    });

    this.setVersionBuildTimestamp();
  }

  setVersionBuildTimestamp(): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const versionBuildTimestamp = (<any>window)['VERSION_BUILD_TIMESTAMP'];

    if (versionBuildTimestamp) {
      this.VERSION_BUILD_TIMESTAMP = String(versionBuildTimestamp);
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyed = true;
  }
}

export type Mode = 'SHIPPER' | 'CARRIER';

import { Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import ValidatorPatterns from '../validator-patterns';

export default class UserValidators {
  public static readonly FULL_NAME: Array<Validators | RxwebValidators> = [
    Validators.required,
    Validators.maxLength(50),
    RxwebValidators.pattern({
      expression: {
        'pattern_not_spaces': ValidatorPatterns.NOT_SPACES,
        'pattern_not_special_characters_and_digit': ValidatorPatterns.NOT_SPECIAL_CHARACTERS_AND_DIGITS_WITHOUT_DASH,
      },
    }),
  ]

  public static readonly COUNTRY_PHONE_CODE: Array<Validators | RxwebValidators> = [
    Validators.required,
    Validators.pattern(ValidatorPatterns.COUNTRY_PHONE_CODE),
  ]

  public static readonly PHONE_NUMBER: Array<Validators | RxwebValidators> = [
    Validators.required,
    Validators.pattern(ValidatorPatterns.PHONE_NUMBER_REGISTRATION),
  ]

  public static readonly EMAIL: Array<Validators | RxwebValidators> = [
    Validators.required,
    RxwebValidators.email(),
  ]

  public static readonly PASSWORD_V2: Array<Validators | RxwebValidators> = [
    RxwebValidators.required(),
    RxwebValidators.pattern({
      expression: {
        'one_or_more_lowercase': ValidatorPatterns.ONE_OR_MORE_LOWERCASE,
      }
    }),
    RxwebValidators.pattern({
      expression: {
        'one_or_more_capital': ValidatorPatterns.ONE_OR_MORE_CAPITAL,
      }
    }),
    RxwebValidators.pattern({
      expression: {
        'one_or_more_special_characters': ValidatorPatterns.ONE_OR_MORE_SPECIAL_CHARACTERS,
      }
    }),
    RxwebValidators.pattern({
      expression: {
        'one_or_more_number': ValidatorPatterns.ONE_OR_MORE_NUMBER,
      }
    }),
    RxwebValidators.minLength({value:8}),
  ]

  public static readonly REPEAT_PASSWORD: Array<Validators | RxwebValidators> = [
    Validators.required,
  ]
}

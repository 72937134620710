import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { CountryData } from './dto/country-data.model';
import { COUNTRIES, Country } from '../model/domain/countries';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  constructor(private translateService: TranslateService) {}

  getByCountryCode(countryCode: string): string | undefined {
    return this.allCountries().find((countryData: CountryData) => countryData.countryCode === countryCode)?.translation;
  }

  getAllCountries(): Observable<CountryData[]> {
    return of(this.allCountries());
  }

  getCountryCodeByCountryCode3(countryCode3: string): string | undefined {
    return COUNTRIES.find((country: Country) => country.code3 === countryCode3)?.code;
  }

  private allCountries(): CountryData[] {
    return COUNTRIES.map((countryRow: Country) => ({
      countryCode: countryRow.code,
      phoneCode: countryRow.phoneCode,
      isViesCountry: countryRow.isViesCountry,
      translation: this.getTranslatedCountry(countryRow),
      viesPlaceholder: countryRow.viesPlaceholder,
    }));
  }

  private getTranslatedCountry(country: Country): string {
    return (country[this.translateService.currentLang as keyof Country] as string) || country.en;
  }
}

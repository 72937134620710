import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusColorPipe } from './status-color.pipe';
import { FlagColorPipe } from './flag-color.pipe';
import { CompanyStatusColorPipe } from "./company-status.color.pipe";

@NgModule({
  declarations: [StatusColorPipe, FlagColorPipe, CompanyStatusColorPipe],
  exports: [StatusColorPipe, FlagColorPipe, CompanyStatusColorPipe],
  imports: [CommonModule]
})
export class StatusColorPipeModule {}

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { COUNTRIES, Country } from '../../model/domain/countries';

@Injectable()
export class CountryService {
  getCountriesList(): Observable<{ name: string; code: string }[]> {
    const countries = COUNTRIES.map((c: Country) => ({
      name: c.en,
      code: c.code
    }));
    return of(countries);
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class S3UploadService {
  private httpOptions = {
    headers: new HttpHeaders().append('Content-Type', 'application/octet-stream')
  };

  constructor(private httpClient: HttpClient) {}

  uploadFile(fileToUpload: { file: File; url: string }): Observable<string> {
    return this.httpClient.put<string>(fileToUpload.url, fileToUpload.file, { headers: this.httpOptions.headers });
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SectionHeaderItemComponent, SectionHeaderTitleData } from '../../section-header.model';

@Component({
  selector: 'app-simple-header',
  templateUrl: './simple-header.component.html',
  styleUrls: ['./simple-header.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleHeaderComponent implements SectionHeaderItemComponent {
  @Input() data: SectionHeaderTitleData;
  @Input() classList: string[];
}

import { Component, Input } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-file-upload-error-msgs[fileUpload]',
  templateUrl: './file-upload-error-msgs.component.html',
})
export class FileUploadErrorMsgsComponent {
  @Input() fileUpload: FileUpload;
  @Input() classes: string[];
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FoldableButtonComponent } from './foldable-button.component';

@NgModule({
  declarations: [FoldableButtonComponent],
  exports: [FoldableButtonComponent],
  imports: [CommonModule]
})
export class FoldableButtonModule {}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { UserRole } from '../model/domain/user-role.type';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const roles: UserRole[] = route.data.requiredRoles;

    if (roles.length === 0) {
      this.router.navigate(['dashboard']);
      return false;
    }

    return this.authService.loggedIn$.pipe(map((value: boolean): boolean => {
      const canActivate = roles.every((role) => {
        return this.authService.userHasRole(role);
      });
      if (canActivate) {
        return true;
      } else {
        this.router.navigate(['dashboard']);
        return false;
      }
    }));
  }
}

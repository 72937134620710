import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class DateParserService {
  fullDateFormat = 'dd.MM.yyyy';
  dateTimeFormat = 'dd.MM.yyyy HH:mm';
  timeFormat = 'HH:mm';

  constructor(private datePipe: DatePipe) {}

  public parseDate(date: Date): string | null {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }

  public parseDateTime(date: Date): string | null {
    return this.datePipe.transform(date, "yyyy-MM-dd'T'HH:mm:ss");
  }

  public formatDateTime(date: Date): string | null {
    return this.datePipe.transform(date, this.dateTimeFormat);
  }

  public formatDate(date: Date): string | null {
    return this.datePipe.transform(date, this.fullDateFormat);
  }

  public formatTime(date: Date): string | null {
    return this.datePipe.transform(date, this.timeFormat);
  }

  public formatRange(from: Date, to: Date): string | null {
    if (new Date(from).getFullYear() === new Date(to).getFullYear()) {
      return this.datePipe.transform(from, 'dd.MM') + '-' + this.datePipe.transform(to, this.fullDateFormat);
    } else
      return (
        this.datePipe.transform(from, this.fullDateFormat) + '-' + this.datePipe.transform(to, this.fullDateFormat)
      );
  }

  public getTimeFromString(value: string): Date {
    return new Date(new Date().setHours(Number(value.split(':')[0]), Number(value.split(':')[1])));
  }
  public toDate(value: Date): Date {
    return new Date(value);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalLoaderService {
  private readonly loaderSwitch: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly loaderState: Observable<boolean> = this.loaderSwitch.asObservable();

  show(): void {
    this.loaderSwitch.next(true);
  }

  hide(): void {
    this.loaderSwitch.next(false);
  }
}

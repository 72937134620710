import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { GetUnreadNotificationsForUserIdQueryResponse } from './dto/notification.model';
import { InactivityCheckerService } from './inactivity-checker.service';
import { NotificationHttpService } from './notification-http.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private readonly HTTP_CALL_SECONDS: number = 60;
  private checker: Observable<GetUnreadNotificationsForUserIdQueryResponse>;
  private checkerSub: Subscription;
  public readonly notifications: BehaviorSubject<GetUnreadNotificationsForUserIdQueryResponse> =
    new BehaviorSubject<GetUnreadNotificationsForUserIdQueryResponse>({ allPending: 0, notifications: [] });
  public readonly notificationState: Observable<GetUnreadNotificationsForUserIdQueryResponse> =
    this.notifications.asObservable();
  private readonly destroyer: Subject<void> = new Subject<void>();

  constructor(private inactivityChecker: InactivityCheckerService, private httpService: NotificationHttpService) {
    this.initInterval();
    this.inactivityChecker.activeState.subscribe((state) => {
      if (state && (!this.checkerSub || this.checkerSub.closed)) {
        this.checkerSub = this.checker.pipe(takeUntil(this.destroyer)).subscribe();
      } else if (!state) {
        this.destroyer.next();
      }
    });
  }

  private initInterval(): void {
    this.checker = interval(this.HTTP_CALL_SECONDS * 1000).pipe(
      startWith(() => this.checkNotifications()),
      switchMap(() => this.checkNotifications())
    );
  }

  private checkNotifications(): Observable<GetUnreadNotificationsForUserIdQueryResponse> {
    return this.httpService.getUnreadNotifications().pipe(
      catchError(() => of({ allPending: 0, notifications: [] })),
      tap((res) => this.notifications.next(res))
    );
  }

  checkNotificationRefresh(): void {
    this.checkNotifications().subscribe();
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormfieldWrapperComponent } from '../views/shared/formfield-wrapper/formfield-wrapper.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { CalendarModule } from 'primeng/calendar';
import { ButtonModule } from 'primeng/button';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DateFilterPipe } from './pipes/date-filter.pipe';
import { ValueToFilterPipe } from './pipes/value-to-filter.pipe';
import { ValueFromFilterPipe } from './pipes/value-from-filter.pipe';
import { MapComponent } from './map/map.component';
import { StatusFilterPipe } from './pipes/status-filter.pipe';
import { NumberFilterPipe } from './pipes/number-filter.pipe';
import { StringFilterPipe } from './pipes/string-filter.pipe';
import { DisplayInCurrentTimezonePipe } from './pipes/display-in-current-timezone.pipe';
import { DropdownFilterPipe } from './pipes/dropdown-filter.pipe';
import { HereMapComponent } from '../views/here-map/here-map.component';
import { HideableHereMapComponent } from '../views/hideable-here-map/hideable-here-map.component';
import { InputTextModule } from 'primeng/inputtext';
import { InputLoaderComponent } from '../views/shared/input-loader/input-loader.component';
import { LanguageInputComponent } from '../views/shared/language-input/language-input.component';
import { InputPasswordComponent } from '../views/shared/input-password/input-password.component';
import { TooltipButtonComponent } from '../views/shared/tooltip-button/tooltip-button.component';
import { PasswordModule } from 'primeng/password';
import { CustomFormUploadDirective } from './directives/custom-form-upload.directive';
import { FileUploadErrorMsgsComponent } from './components/file-upload-error-msgs/file-upload-error-msgs.component';
import { CountryCodeModule } from '../pipes/country-code/country-code.module';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { LoaderModule } from '../views/shared/loader/loader.module';
import { PasswordTooltipComponent } from './components/password-tooltip/password-tooltip.component';
import { CurrencyPriloPipe } from './pipes/currency-prilo.pipe';
import { ToastsComponent } from './components/toasts/toasts.component';
import { ToastModule } from 'primeng/toast';
import { OfferFavouritesToggleComponent } from './components/offer-favourites-toggle/offer-favourites-toggle.component';
import { BackgroundTitleDirective } from './directives/background-title.directive';
import { AsFormArrayPipe } from './pipes/as-form-array.pipe';
import { AsFormGroupPipe } from './pipes/as-form-group.pipe';
import { AsAnyFormPipe } from './pipes/as-any.pipe';
import { HideableFieldComponent } from './components/hideable-field/hideable-field/hideable-field.component';

@NgModule({
  declarations: [
    FormfieldWrapperComponent,
    MapComponent,
    HereMapComponent,
    HideableHereMapComponent,
    StringFilterPipe,
    StatusFilterPipe,
    NumberFilterPipe,
    DateFilterPipe,
    ValueToFilterPipe,
    ValueFromFilterPipe,
    DisplayInCurrentTimezonePipe,
    DropdownFilterPipe,
    InputLoaderComponent,
    InputPasswordComponent,
    CustomFormUploadDirective,
    FileUploadErrorMsgsComponent,
    LanguageInputComponent,
    TooltipButtonComponent,
    ClickOutsideDirective,
    PasswordTooltipComponent,
    CurrencyPriloPipe,
    ToastsComponent,
    OfferFavouritesToggleComponent,
    BackgroundTitleDirective,
    AsFormArrayPipe,
    AsFormGroupPipe,
    AsAnyFormPipe,
    HideableFieldComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    DropdownModule,
    InputNumberModule,
    InputTextModule,
    ButtonModule,
    CalendarModule,
    PasswordModule,
    CountryCodeModule,
    NgbTooltipModule,
    LoaderModule,
    ToastModule
  ],
  exports: [
    FormfieldWrapperComponent,
    MapComponent,
    HereMapComponent,
    HideableHereMapComponent,
    StringFilterPipe,
    StatusFilterPipe,
    NumberFilterPipe,
    DateFilterPipe,
    ValueToFilterPipe,
    ValueFromFilterPipe,
    DisplayInCurrentTimezonePipe,
    DropdownFilterPipe,
    InputLoaderComponent,
    InputPasswordComponent,
    CustomFormUploadDirective,
    FileUploadErrorMsgsComponent,
    LanguageInputComponent,
    TooltipButtonComponent,
    ClickOutsideDirective,
    PasswordTooltipComponent,
    CurrencyPriloPipe,
    ToastsComponent,
    OfferFavouritesToggleComponent,
    BackgroundTitleDirective,
    AsFormArrayPipe,
    AsFormGroupPipe,
    AsAnyFormPipe,
    HideableFieldComponent
  ]
})
export class SharedModule {}

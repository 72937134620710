import { NgModule } from '@angular/core';
import { EditEtaModalComponent } from './edit-eta-modal.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { CalendarModule } from 'primeng/calendar';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';

@NgModule({
  declarations: [EditEtaModalComponent],
  exports: [EditEtaModalComponent],
  imports: [ReactiveFormsModule, CalendarModule, TranslateModule, SharedModule, CommonModule, DialogModule]
})
export class EditEtaModalModule {}

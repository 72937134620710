import { Injectable } from '@angular/core';
import { NotificationService } from '../../../../../services/notification.service';
import { switchMap } from 'rxjs/operators';
import { NotificationHttpService } from '../../../../../services/notification-http.service';
import { LinkHandlerService } from '../../../../../services/link-handler.service';
import { NotificationDto, NotificationStatus } from '../../../../../services/dto/notification.model';
import { Observable, of } from 'rxjs';

@Injectable()
export class NotificationModalService {
  constructor(
    private notificationService: NotificationService,
    private notificationHttpService: NotificationHttpService,
    private linkHandler: LinkHandlerService
  ) {}

  tileActionBtnClicked(action: NotificationModalAction): void {
    this.markNotificationRead(action);
    if (action.link) {
      this.linkHandler.openLink(action.link);
    } else {
      throw new Error("No link specified, can't open");
    }
  }

  markReadBtnClicked(action: NotificationModalAction): void {
    this.markNotificationRead(action);
  }

  public deleteNotification(action: NotificationDeleteAction): void {
    this.notificationHttpService.deleteNotification(action.id, action.creationTime).subscribe(() => {
      this.notificationService.checkNotificationRefresh();
    });
  }

  private markNotificationRead(action: NotificationModalAction): void {
    this.notificationHttpService
      .markNotificationRead(action.id, action.creationTime)
      .pipe(switchMap(() => this.getListWithMarkedNotification(action)))
      .subscribe((res) => {
        this.notificationService.notifications.next({
          allPending: action.allPending === 0 ? 0 : action.allPending - 1,
          notifications: res
        });
      });
  }

  private getListWithMarkedNotification(action: NotificationModalAction): Observable<NotificationDto[]> {
    return of(
      action.notifications.map((item) => {
        if (item.id === action.id) {
          return {
            ...item,
            status: NotificationStatus.READ
          };
        } else {
          return item;
        }
      })
    );
  }
}

export type NotificationModalAction = Readonly<{
  id: string;
  creationTime: string;
  notifications: NotificationDto[];
  allPending: number;
  link?: string;
}>;

export type NotificationDeleteAction = {
  id: string;
  creationTime: string;
};

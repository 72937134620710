import { Component, EventEmitter, Input, OnInit, Optional, Output } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmDialogData } from '../../../services/custom-dialog.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent implements OnInit {
  private _display = false;
  @Output() hideEvent: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmEvent: EventEmitter<void> = new EventEmitter<void>();
  @Input() headerText?: string;
  @Input() text: string;
  @Input() confirmButtonText?: string;
  @Input() cancelButtonText?: string;

  get display(): boolean {
    return this._display;
  }

  @Input() set display(value: boolean) {
    this._display = value;
    if (!value) this.hideEvent.emit();
  }

  constructor(
    @Optional() public dynamicDialogRef: DynamicDialogRef,
    @Optional() public dynamicDialogConfig: DynamicDialogConfig,
  ) {
  }

  ngOnInit() {
    if(this.dynamicDialogRef){
      this.initOpenedFromDialogService();
    }
  }

  initOpenedFromDialogService(){
    const data: ConfirmDialogData = this.dynamicDialogConfig.data;

    this.headerText = data.headerText;
    this.text = data.text;
    this.confirmButtonText = data.confirmButtonText;
    this.cancelButtonText = data.cancelButtonText;
  }

  close(){
    if(this.dynamicDialogRef){
      this.dynamicDialogRef.close(false);
    } else {
      this.display = false
    }
  }

  confirm(): void {
    if(this.dynamicDialogRef){
      this.dynamicDialogRef.close(true);
    } else {
      this.confirmEvent.emit();
      this.display = false;
    }
  }
}

<div name="modal">
  <p-dialog
    [(visible)]="display"
    [modal]="true"
    [style]="{ width: '770px' }"
    [draggable]="false"
    [resizable]="false"
    (onHide)="emitHideModalEvent()"
    class="mono-ghost-background"
    (click)="$event.stopPropagation()"
  >
    <ng-template pTemplate="header">
      <h4 class="pl-6">{{ 'shipmentOrder.cancelOrder.header' | translate }}</h4>
    </ng-template>

    <div class="pl-6 pr-6">{{ 'shipmentOrder.cancelOrder.confirmationQuestion' | translate }}</div>

    <div class="grid justify-content-end mt-4">
      <p-button
        type="button"
        icon="pi pi-times"
        label="{{ 'shipmentOrder.cancelOrder.button.no' | translate }}"
        class="regular-text mr-3"
        (click)="cancel()"
      ></p-button>
      <p-button
        class="small-yellow"
        (onClick)="confirm()"
        label="{{ 'shipmentOrder.cancelOrder.button.yes' | translate }}"
      ></p-button>
    </div>
  </p-dialog>
</div>

<div
  *teleportTo="'sectionHeaderTeleport'"
  class="flex align-items-center"
>
  <i
    class="pi pi-bars primary-dark-purple hamburger-menu-icon mr-3"
    (click)="show()"
  ></i>

  <img
    src="assets/logo/logo-dark.svg"
    class="flex xl:hidden header-logo"
  />
</div>

<div
  *ngIf="active"
  class="tint"
  (click)="hide()"
></div>

<div
  class="side-nav"
  [class.active]="active"
  (mouseover)="show()"
  (mouseleave)="hide()"
  [class.pointer-events-none]="pointerEventsNone"
>
  <div
    class="side-nav__header"
    [class.side-nav__header--expanded]="active"
    [class.side-nav__header--hidden]="!active"
  >
    <ng-container *ngIf="!active"><img src="/assets/logo/short-logo.svg" /></ng-container>

    <ng-container *ngIf="active"><img src="/assets/logo/logo.svg" /></ng-container>

    <i
      class="pi pi-times close-menu-icon mono-white"
      (click)="hide()"
    ></i>
  </div>

  <app-menu-item
    *ngFor="let item of items"
    [label]="item.label | translate"
    [icon]="item.icon"
    [link]="item.link"
    [onlyIcon]="!active"
    [badgeText]="getBadgeText(item)"
    (click)="hide()"
  ></app-menu-item>

  <div
    *ngIf="VERSION_BUILD_TIMESTAMP"
    class="version-build-timestamp mono-elements"
  >
    v.{{ VERSION_BUILD_TIMESTAMP | slice: 0 : 7 }}
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalLoaderComponent } from './global-loader.component';
import { LoaderModule } from '../loader/loader.module';

@NgModule({
  declarations: [GlobalLoaderComponent],
  exports: [GlobalLoaderComponent],
  imports: [CommonModule, LoaderModule]
})
export class GlobalLoaderModule {}

/* eslint-disable @typescript-eslint/no-extra-non-null-assertion */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { DateParserService } from '../../services/date-parser.service';
import * as _ from 'lodash';
import {
  CarrierCompanyParamsGetter,
  CarrierCompanyParamsSetter,
  QueryMap,
  ShipperCompanyParamsGetter,
  ShipperCompanyParamsSetter
} from './query-params.types';

@Injectable({
  providedIn: 'root'
})
export class QueryParamsService {
  public static readonly LOADING_CAPTION = 'l';
  public static readonly LOADING_LATITUDE = 'llat';
  public static readonly LOADING_LONGITUDE = 'llon';
  public static readonly LOADING_RANGE = 'lran';
  public static readonly LOADING_ADMINISTRATIVE_AREA_TYPE = 'laat';
  public static readonly LOADING_LOCALITY_TYPE = 'llt';

  public static readonly UNLOADING_CAPTION = 'u';
  public static readonly UNLOADING_LATITUDE = 'ulat';
  public static readonly UNLOADING_LONGITUDE = 'ulon';
  public static readonly UNLOADING_RANGE = 'uran';
  public static readonly UNLOADING_ADMINISTRATIVE_AREA_TYPE = 'uaat';
  public static readonly UNLOADING_LOCALITY_TYPE = 'ult';

  public static readonly LOADING_DATE_FROM = 'ldf';
  public static readonly LOADING_DATE_TO = 'ldt';
  public static readonly UNLOADING_DATE_TO = 'udt';
  public static readonly MIN_NUMBER_OF_CARS = 'minc';
  public static readonly MAX_NUMBER_OF_CARS = 'maxc';
  public static readonly SHIPPER = 'shipper';
  public static readonly CARRIER = 'carrier';
  public static readonly NEGOTIABLE = 'neg';
  public static readonly MANAGE_BOOKING = 'mb';
  public static readonly FULL_TRUCK_LOAD = 'full';
  public static readonly HIDE_YOUR_COMPANYS_ORDERS = 'hideYourCompanysOrders';
  public static readonly SHOW_ONLY_MY_FAVOURITE_ORDERS = 'showOnlyMyFavouriteOrders';
  public static readonly ORDER_ID = 'orderId';
  public static readonly ORDER_PRESENTATION_ID = 'orderPresentationId';
  public static readonly ORDER_PRESENTATION_ID_ID_SOURCE_APP = 'orderPresentationId_idSourceApp';
  public static readonly STATUS = 'status';
  public static readonly STATUSES = 'statuses';
  public static readonly ALL_CARS_ASSIGNED_TO_TRUCK = 'notAllCarsAssignedToTrucks';

  public static readonly SOURCE_ORDER_ID = 'source';

  public static readonly SORT_ORDER_FIELD = 'sof';
  public static readonly SORT_ORDER_DIR = 'sod';
  public static readonly SHOW_MORE_FILTERS = 'smf';

  constructor(private dateService: DateParserService) {}

  public containsLoadingData(params: ParamMap): boolean {
    return (
      params.has(QueryParamsService.LOADING_CAPTION) &&
      params.has(QueryParamsService.LOADING_LATITUDE) &&
      params.has(QueryParamsService.LOADING_LONGITUDE) &&
      params.has(QueryParamsService.LOADING_RANGE)
    );
  }

  public getLoadingAdministrativeAreaType(params: ParamMap): string {
    return params.get(QueryParamsService.LOADING_ADMINISTRATIVE_AREA_TYPE)!?.trim();
  }

  public getLoadingLocalityType(params: ParamMap): string {
    return params.get(QueryParamsService.LOADING_LOCALITY_TYPE)!?.trim();
  }

  public getLoadingCountry(params: ParamMap): string {
    return params.get(QueryParamsService.LOADING_CAPTION)!.split(',')[0].trim();
  }

  public getLoadingCaption(params: ParamMap): string {
    return params.get(QueryParamsService.LOADING_CAPTION)!.trim();
  }

  public getLoadingLatitude(params: ParamMap): number {
    return +params.get(QueryParamsService.LOADING_LATITUDE)!;
  }

  public getLoadingLongitude(params: ParamMap): number {
    return +params.get(QueryParamsService.LOADING_LONGITUDE)!;
  }

  public getLoadingRange(params: ParamMap): number {
    return params.has(QueryParamsService.LOADING_RANGE) ? +params.get(QueryParamsService.LOADING_RANGE)! : 100;
  }

  public containsUnloadingData(params: ParamMap): boolean {
    return (
      params.has(QueryParamsService.UNLOADING_CAPTION) &&
      params.has(QueryParamsService.UNLOADING_LATITUDE) &&
      params.has(QueryParamsService.UNLOADING_LONGITUDE) &&
      params.has(QueryParamsService.UNLOADING_RANGE)
    );
  }

  public getUnloadingAdministrativeAreaType(params: ParamMap): string {
    return params.get(QueryParamsService.UNLOADING_ADMINISTRATIVE_AREA_TYPE)!?.trim();
  }

  public getUnloadingLocalityType(params: ParamMap): string {
    return params.get(QueryParamsService.UNLOADING_LOCALITY_TYPE)!?.trim();
  }

  public getUnloadingCountry(params: ParamMap): string {
    return params.get(QueryParamsService.UNLOADING_CAPTION)!.split(',')[0].trim();
  }

  public getUnloadingCaption(params: ParamMap): string {
    return params.get(QueryParamsService.UNLOADING_CAPTION)!.trim();
  }

  public getUnloadingLatitude(params: ParamMap): number {
    return +params.get(QueryParamsService.UNLOADING_LATITUDE)!;
  }

  public getUnloadingLongitude(params: ParamMap): number {
    return +params.get(QueryParamsService.UNLOADING_LONGITUDE)!;
  }

  public getUnloadingRange(params: ParamMap): number {
    return params.has(QueryParamsService.UNLOADING_RANGE) ? +params.get(QueryParamsService.UNLOADING_RANGE)! : 100;
  }

  public hasLoadingDates(params: ParamMap): boolean {
    return params.has(QueryParamsService.LOADING_DATE_FROM) && params.has(QueryParamsService.LOADING_DATE_TO);
  }

  public getLoadingDates(params: ParamMap): Date[] | null {
    return this.hasLoadingDates(params)
      ? [
          new Date(params.get(QueryParamsService.LOADING_DATE_FROM)!),
          new Date(params.get(QueryParamsService.LOADING_DATE_TO)!)
        ]
      : null;
  }

  public hasUnloadingDate(params: ParamMap): boolean {
    return params.has(QueryParamsService.UNLOADING_DATE_TO);
  }

  public getUnloadingDate(params: ParamMap): Date | undefined {
    return this.hasUnloadingDate(params) ? new Date(params.get(QueryParamsService.UNLOADING_DATE_TO)!) : undefined;
  }

  public getMinNumberOfCars(params: ParamMap): number | undefined {
    return params.has(QueryParamsService.MIN_NUMBER_OF_CARS)
      ? +params.get(QueryParamsService.MIN_NUMBER_OF_CARS)!
      : undefined;
  }

  public getMaxNumberOfCars(params: ParamMap): number | undefined {
    return params.has(QueryParamsService.MAX_NUMBER_OF_CARS)
      ? +params.get(QueryParamsService.MAX_NUMBER_OF_CARS)!
      : undefined;
  }

  private setShipperQueryParamsValue(shipper: ShipperCompanyParamsSetter | string | null) {
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (shipper) {
      if (_.isString(shipper)) {
        shipper = {
          shipperCompanyName: shipper,
          shipperCompanyId: null
        };
      }
    }

    return shipper ? JSON.stringify(shipper) : null;
  }

  private setCarrierQueryParamsValue(carrier: CarrierCompanyParamsSetter | string | null) {
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (carrier) {
      if (_.isString(carrier)) {
        carrier = {
          carrierCompanyName: carrier,
          carrierCompanyId: null
        };
      }
    }

    return carrier ? JSON.stringify(carrier) : null;
  }

  public getShipper(params: ParamMap): ShipperCompanyParamsGetter | undefined {
    return params.has(QueryParamsService.SHIPPER) ? JSON.parse(params.get(QueryParamsService.SHIPPER)!) : undefined;
  }

  public getCarrier(params: ParamMap): CarrierCompanyParamsGetter | undefined {
    return params.has(QueryParamsService.CARRIER) ? JSON.parse(params.get(QueryParamsService.CARRIER)!) : undefined;
  }

  public getManageBooking(params: ParamMap): boolean | undefined {
    return params.has(QueryParamsService.MANAGE_BOOKING)
      ? params.get(QueryParamsService.MANAGE_BOOKING)!.toLowerCase() == 'true'
      : undefined;
  }

  public getNegotiable(params: ParamMap): boolean | undefined {
    return params.has(QueryParamsService.NEGOTIABLE)
      ? params.get(QueryParamsService.NEGOTIABLE)!.toLowerCase() == 'true'
      : undefined;
  }

  public getFullTruckLoad(params: ParamMap): boolean | undefined {
    return params.has(QueryParamsService.FULL_TRUCK_LOAD)
      ? params.get(QueryParamsService.FULL_TRUCK_LOAD)!.toLowerCase() == 'true'
      : undefined;
  }

  public getHideYourCompanysOrders(params: ParamMap): boolean | undefined {
    return params.has(QueryParamsService.HIDE_YOUR_COMPANYS_ORDERS)
      ? params.get(QueryParamsService.HIDE_YOUR_COMPANYS_ORDERS)!.toLowerCase() == 'true'
      : undefined;
  }

  public getShowOnlyMyFavouriteOrders(params: ParamMap): boolean | undefined {
    return params.has(QueryParamsService.SHOW_ONLY_MY_FAVOURITE_ORDERS)
      ? params.get(QueryParamsService.SHOW_ONLY_MY_FAVOURITE_ORDERS)!.toLowerCase() == 'true'
      : undefined;
  }

  public getSourceOrderId(params: ParamMap): string | undefined {
    return params.has(QueryParamsService.SOURCE_ORDER_ID) ? params.get(QueryParamsService.SOURCE_ORDER_ID)! : undefined;
  }

  public getOrderId(params: ParamMap): string | undefined {
    return params.has(QueryParamsService.ORDER_ID) ? params.get(QueryParamsService.ORDER_ID)! : undefined;
  }

  public getOrderPresentationId(params: ParamMap): string | undefined {
    return params.has(QueryParamsService.ORDER_PRESENTATION_ID)
      ? params.get(QueryParamsService.ORDER_PRESENTATION_ID)!
      : undefined;
  }

  public getOrderPresentationAndSourceAppId(params: ParamMap): string | undefined {
    return params.has(QueryParamsService.ORDER_PRESENTATION_ID_ID_SOURCE_APP)
      ? params.get(QueryParamsService.ORDER_PRESENTATION_ID_ID_SOURCE_APP)!
      : undefined;
  }

  public getnotAllCarsAssignedToTrucks(params: ParamMap): boolean | undefined {
    return params.has(QueryParamsService.ALL_CARS_ASSIGNED_TO_TRUCK)
      ? params.get(QueryParamsService.ALL_CARS_ASSIGNED_TO_TRUCK)!.toLowerCase() == 'true'
      : undefined;
  }

  public getStatus(params: ParamMap): string | undefined {
    return params.has(QueryParamsService.STATUS) ? params.get(QueryParamsService.STATUS)! : undefined;
  }

  public getStatuses(params: ParamMap): string[] | undefined {
    return params.has(QueryParamsService.STATUSES) ? params.getAll(QueryParamsService.STATUSES)! : undefined;
  }

  public getShowMoreFilters(params: ParamMap): boolean | undefined {
    return params.has(QueryParamsService.SHOW_MORE_FILTERS)
      ? params.get(QueryParamsService.SHOW_MORE_FILTERS)!.toLowerCase() == 'true'
      : undefined;
  }

  public getSortOrderField(params: ParamMap): string | undefined {
    return params.has(QueryParamsService.SORT_ORDER_FIELD)
      ? params.get(QueryParamsService.SORT_ORDER_FIELD)!
      : undefined;
  }

  public getSortOrderDir(params: ParamMap): string | undefined {
    return params.has(QueryParamsService.SORT_ORDER_DIR) ? params.get(QueryParamsService.SORT_ORDER_DIR)! : undefined;
  }

  public getSortOrder(params: ParamMap): { field: string; dir: string } | undefined {
    const sortOrderField = this.getSortOrderField(params);
    const sortOrderDir = this.getSortOrderDir(params);

    if (sortOrderField && sortOrderDir) {
      return {
        field: sortOrderField,
        dir: sortOrderDir
      };
    } else {
      return undefined;
    }
  }

  public buildQueryParamsFromCriteria(rawValue: any): QueryMap {
    const map: QueryMap = {};

    map[QueryParamsService.LOADING_CAPTION] = rawValue?.loadingPlace?.city?.displayValue;
    map[QueryParamsService.LOADING_LATITUDE] = rawValue?.loadingPlace?.city?.lat;
    map[QueryParamsService.LOADING_LONGITUDE] = rawValue?.loadingPlace?.city?.lon;
    map[QueryParamsService.LOADING_RANGE] = rawValue?.loadingPlace?.range;
    map[QueryParamsService.LOADING_ADMINISTRATIVE_AREA_TYPE] = rawValue?.loadingPlace?.city?.administrativeAreaType;
    map[QueryParamsService.LOADING_LOCALITY_TYPE] = rawValue?.loadingPlace?.city?.localityType;

    map[QueryParamsService.UNLOADING_CAPTION] = rawValue?.unloadingPlace?.city?.displayValue;
    map[QueryParamsService.UNLOADING_LATITUDE] = rawValue?.unloadingPlace?.city?.lat;
    map[QueryParamsService.UNLOADING_LONGITUDE] = rawValue?.unloadingPlace?.city?.lon;
    map[QueryParamsService.UNLOADING_RANGE] = rawValue?.unloadingPlace?.range;
    map[QueryParamsService.UNLOADING_ADMINISTRATIVE_AREA_TYPE] = rawValue?.unloadingPlace?.city?.administrativeAreaType;
    map[QueryParamsService.UNLOADING_LOCALITY_TYPE] = rawValue?.unloadingPlace?.city?.localityType;

    map[QueryParamsService.LOADING_DATE_FROM] = this.dateService.parseDate(rawValue?.loadingDateRange?.[0]);
    map[QueryParamsService.LOADING_DATE_TO] = this.dateService.parseDate(rawValue?.loadingDateRange?.[1]);
    map[QueryParamsService.UNLOADING_DATE_TO] = this.dateService.parseDate(rawValue?.unloadingDate);
    map[QueryParamsService.MIN_NUMBER_OF_CARS] = rawValue?.minNumberOfCars;
    map[QueryParamsService.MAX_NUMBER_OF_CARS] = rawValue?.maxNumberOfCars;
    map[QueryParamsService.SHIPPER] = this.setShipperQueryParamsValue(rawValue?.shipper);
    map[QueryParamsService.CARRIER] = this.setCarrierQueryParamsValue(rawValue?.carrier);
    map[QueryParamsService.NEGOTIABLE] = rawValue?.negotiable;
    map[QueryParamsService.FULL_TRUCK_LOAD] = rawValue?.fullTruckLoad;
    map[QueryParamsService.HIDE_YOUR_COMPANYS_ORDERS] = rawValue?.hideYourCompanysOrders;
    map[QueryParamsService.SHOW_ONLY_MY_FAVOURITE_ORDERS] = rawValue?.showOnlyMyFavouriteOrders;
    map[QueryParamsService.ORDER_ID] = rawValue?.orderId;
    map[QueryParamsService.ORDER_PRESENTATION_ID_ID_SOURCE_APP] = rawValue?.orderPresentationId_idSourceApp;
    map[QueryParamsService.ORDER_PRESENTATION_ID] = rawValue?.orderPresentationId;
    map[QueryParamsService.STATUS] = rawValue?.status;
    map[QueryParamsService.STATUSES] = rawValue?.statuses;
    map[QueryParamsService.MANAGE_BOOKING] = rawValue?.manageBooking;
    map[QueryParamsService.ALL_CARS_ASSIGNED_TO_TRUCK] = rawValue?.notAllCarsAssignedToTrucks;

    return map;
  }

  public buildQueryParams(
    name: string,
    lat: number,
    lon: number,
    range: number,
    dateFrom: Date,
    dateTo: Date,
    skipId: string
  ): QueryMap {
    const map: QueryMap = {};

    map[QueryParamsService.LOADING_CAPTION] = name;
    map[QueryParamsService.LOADING_LATITUDE] = lat.toString();
    map[QueryParamsService.LOADING_LONGITUDE] = lon.toString();
    map[QueryParamsService.LOADING_RANGE] = range.toString();

    map[QueryParamsService.LOADING_DATE_FROM] = this.dateService.parseDate(dateFrom);
    map[QueryParamsService.LOADING_DATE_TO] = this.dateService.parseDate(dateTo);

    map[QueryParamsService.SOURCE_ORDER_ID] = skipId;

    return map;
  }
}

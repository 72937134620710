<div
  data-test-id="notification-tile"
  class="notification-tile flex"
  [ngClass]="{ active: notification.status === notificationStatus.READ }"
>
  <div style="flex-grow: 1">
    <div
      class="notification-tile__info"
      [innerHTML]="'notifications.texts.' + (notification.type | notificationType) | translate: notification.data"
    ></div>
    <a
      *ngIf="notification.link"
      (click)="clicked()"
      class="regular-dark w-min my-2"
      target="_blank"
      [routerLink]="['/' + notification.link]"
      >{{ 'notifications.buttons.' + (notification.type | notificationType) | translate }}</a
    >
    <div class="notification-tile__date">
      {{ notification.creationTime | notificationTime }} {{ 'notifications.tile.ago' | translate }}
    </div>
  </div>

  <div class="flex flex-column justify-content-between">
    <button
      type="button"
      data-test-id="notification-tile-mark"
      class="notification-tile__mark btn"
      [ngClass]="{ active: notification.status === notificationStatus.READ }"
      (click)="setRead()"
      icon="assets/icons/15px/read.svg"
    >
      <img
        src="assets/icons/15px/read.svg"
        alt="read"
      />
    </button>
    <button
      type="button"
      (click)="deleteClicked.emit()"
      class="btn"
    >
      <img src="assets/icons/15px/trash.svg" />
    </button>
  </div>
</div>

import { Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TermsContentComponent } from './terms-content/terms-content.component';
import { RegistrationStepI } from '../../guards/registration-step.guard';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.sass']
})
export class TermsComponent implements RegistrationStepI {
  @ViewChild(TermsContentComponent) termsContentComponent: TermsContentComponent;

  getForm(): FormGroup {
    return this.termsContentComponent.getForm();
  }

  isFormGroupPending(): boolean {
    return this.termsContentComponent.isFormGroupPending();
  }

  setNavigationChangeSubscriber(): void {
    this.termsContentComponent.setNavigationChangeSubscriber();
  }
}

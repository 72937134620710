export default class TaxIdViesValidatorPatterns {
  public static readonly AUSTRIA: string = 'ATU\\d{8}$';
  public static readonly BELGIUM: string = 'BE[01]\\d{9}$';
  public static readonly BULGARIA: string = 'BG\\d{9,10}$';
  public static readonly CYPRUS: string = 'CY\\d{8}[A-Za-z]$';
  public static readonly CZECHIA: string = 'CZ\\d{8,10}$';
  public static readonly GERMANY: string = 'DE\\d{9}$';
  public static readonly DENMARK: string = 'DK\\d{2}\\s\\d{2}\\s\\d{2}\\s\\d{2}$';
  public static readonly ESTONIA: string = 'EE\\d{9}$';
  public static readonly GREECE: string = 'EL\\d{9}$';
  public static readonly SPAIN: string = 'ES[A-Za-z0-9]\\d{7}[A-Za-z0-9]$';
  public static readonly FINLAND: string = 'FI\\d{8}$';
  public static readonly FRANCE: string = 'FR[A-Za-z0-9]{2}\\s\\d{9}$';
  public static readonly CROATIA: string = 'HR\\d{11}$';
  public static readonly HUNGARY: string = 'HU\\d{8}$';
  public static readonly IRELAND: string = '(IE\\d[A-Za-z0-9+*]\\d{5}[A-Za-z])$|(IE\\d{7}WI)$';
  public static readonly ITALY: string = 'IT\\d{11}$';
  public static readonly LITHUANIA: string = '(LT\\d{9})$|(LT\\d{12})$';
  public static readonly LUXEMBOURG: string = 'LU\\d{8}$';
  public static readonly LATVIA: string = 'LV\\d{11}$';
  public static readonly MALTA: string = 'MT\\d{8}$';
  public static readonly NETHERLANDS: string = 'NL[A-Za-z0-9+*]{12}$';
  public static readonly POLAND: string = 'PL\\d{10}$';
  public static readonly PORTUGAL: string = 'PT\\d{9}$';
  public static readonly ROMANIA: string = 'RO\\d{2,10}$';
  public static readonly SWEDEN: string = 'SE\\d{12}$';
  public static readonly SLOVENIA: string = 'SI\\d{8}$';
  public static readonly SLOVAKIA: string = 'SK\\d{10}$';
  public static readonly NORTHERN_IRELAND: string =
    'XI((\\d{3}\\s\\d{4}\\s\\d{2})|(\\d{3}\\s\\d{4}\\s\\d{2}\\s\\d{3})|(GD\\d{3})|(HA\\d{3}))$';
}

import { Component, ViewChild } from '@angular/core';
import { RegistrationStepI } from '../../../guards/registration-step.guard';
import { TermsContentComponent } from '../../terms/terms-content/terms-content.component';
import { FormGroup } from '@angular/forms';
import { InvitationRegistrationService } from '../../../services/invitation-registration.service';

@Component({
  selector: 'app-terms-invitation',
  templateUrl: './terms-invitation.component.html',
  styleUrls: ['./terms-invitation.component.sass']
})
export class TermsInvitationComponent implements RegistrationStepI {
  @ViewChild(TermsContentComponent) termsContentComponent: TermsContentComponent;

  constructor(public invitationRegistrationService: InvitationRegistrationService) {}

  getForm(): FormGroup {
    return this.termsContentComponent.getForm();
  }

  isFormGroupPending(): boolean {
    return this.termsContentComponent.isFormGroupPending();
  }

  setNavigationChangeSubscriber(): void {
    this.termsContentComponent.setNavigationChangeSubscriber();
  }
}

<div class="wrapper">
  <h4>
    {{ 'terminationModal.' + (data.itemType | lowercase) + '.title' | translate }}
  </h4>
  <div>
    <p [innerHTML]="'terminationModal.' + (data.itemType | lowercase) + '.text' | translate"></p>

    <form [formGroup]="form">
      <div class="my-4">
        <app-formfield-wrapper
          label="{{ 'terminationModal.reasonInputText' | translate }}"
          image="icons/10px/info.svg"
        >
          <textarea
            pInputTextarea
            rows="4"
            formControlName="reason"
            [autoResize]="true"
          ></textarea>
          <div validation>
            <small
              class="p-error"
              *ngIf="hasError('reason', 'maxlength')"
            >
              {{ 'common.alerts.maxLength' | translate: { value: reasonMaxLength } }}
            </small>
            <small
              class="p-error"
              *ngIf="hasError('reason', 'minlength')"
            >
              {{ 'terminationModal.alerts.minLength' | translate }}
            </small>
            <small
              class="p-error"
              *ngIf="hasError('reason', 'required')"
            >
              {{ 'common.alerts.required' | translate }}
            </small>
          </div>
        </app-formfield-wrapper>
      </div>
    </form>

    <div [innerHTML]="'terminationModal.contactNote' | translate"></div>
    <div class="grid justify-content-end mt-6">
      <p-button
        type="button"
        icon="pi pi-times"
        label="{{ 'common.cancel' | translate | uppercase }}"
        class="regular-text mr-3"
        (click)="cancel()"
      ></p-button>
      <p-button
        class="small-plum"
        (onClick)="confirm()"
        label="{{ 'terminationModal.buttonText' | translate }}"
        [disabled]="form.invalid && !isBusy"
      ></p-button>
    </div>
  </div>
</div>

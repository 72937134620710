import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function MatchValue(checkedControlName: string, confirmedControlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const checkedValue = control.get(checkedControlName)?.value;
    const confirmedValue = control.get(confirmedControlName)?.value;

    if (checkedValue && confirmedValue && checkedValue !== confirmedValue) {
      return { ['mismatched' + capitalizeFirstLetter(checkedControlName)]: true };
    }
    return null;
  };
}

function capitalizeFirstLetter(text: string): string {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

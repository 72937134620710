import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpRequestChecker } from '../../interceptors/services/http-request-checker.service';
import { delay } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { Login } from '../../auth/login';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.sass']
})
export class SignInComponent implements OnInit {
  signInForm!: FormGroup;
  responseError = false;
  alertMessage = '';
  loading: boolean;
  accountBlocked = false;
  returnUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private spinnerService: HttpRequestChecker,
    private route: ActivatedRoute,
    private auth: AuthService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';

    this.auth.logout().subscribe(
      (response) => {
        console.log('user logged out: ' + response);
      },
      (error) => {
        console.error('errors in logging out user: ' + error);
      }
    );
    this.spinnerService.isHttpRequestPending
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading: boolean) => {
        this.loading = loading;
      });

    this.signInForm = this.formBuilder.group({
      email: this.formBuilder.control('', [Validators.required]),
      password: this.formBuilder.control('', Validators.required)
    });
  }

  signInUser(): void {
    const email = this.signInForm.controls['email'].value;
    const password = this.signInForm.controls['password'].value;
    this.loading = true;
    this.auth.login(new Login(email, password)).subscribe(
      () => {
        this.router.navigateByUrl(this.returnUrl);
        this.loading = false;
      },
      (err) => {
        this.loading = false;
        this.responseError = true;
        this.alertMessage = this.translateService.instant('signIn.label.badLoginOrPassword');
        console.error(this.alertMessage);
        console.error(err);
      }
    );
  }

  get form(): { [key: string]: AbstractControl } {
    return this.signInForm.controls;
  }
}

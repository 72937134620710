import { AfterViewChecked, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-formfield-wrapper',
  templateUrl: './formfield-wrapper.component.html',
  styleUrls: ['./formfield-wrapper.component.sass']
})
export class FormfieldWrapperComponent implements AfterViewChecked, OnInit {
  @Input() image: string;
  @Input() label: string;
  @Input() oneline = false;
  @Input() onelineDisabled = false;
  @Input() transparent = false;
  @Input() composed = false;
  @Input() labelFor: string;
  @Input() noIndentation = false;
  @Input() bordered = false;
  @Input() class: string;
  @Input() autoHeight = false;
  @Input() autoWidth = false;
  // a bug fix for the primeflex dropdown component: https://jira.altkomsoftware.pl/jira/browse/ADAMPOL-597
  @Input() customErrorCheck: () => boolean | undefined;
  hasError: boolean | undefined = false;

  @ViewChild('wrapper') wrapper: ElementRef;
  isWrapperTextContent$ = new BehaviorSubject(false);
  isWrapperTextContent: boolean;


  checkError(): void {
    this.hasError = this.customErrorCheck();
  }

  ngOnInit(): void {
    this.isWrapperTextContent$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
    ).subscribe((value) => {
      this.isWrapperTextContent = value;
    })
  }

  ngAfterViewChecked(): void{
    this.isWrapperTextContent$.next((this.wrapper?.nativeElement.textContent || '').length > 0);
  }
}

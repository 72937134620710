<div
  class="height-100 flex align-items-center"
  *teleportTo="'sectionHeaderTeleport'"
>
  <img src="assets/logo/logo-dark.svg" />
</div>
<div class="a-page-registration-container-header">
  <h1>{{ 'invitationRegistration.steps.title' | translate }}</h1>

  <div class="subtitle">{{ 'invitationRegistration.steps.subtitle' | translate: { companyName: companyName } }}</div>

  <div>
    <p-steps
      [model]="steps"
      class="registration"
      [readonly]="false"
    ></p-steps>
  </div>
</div>

<link rel="stylesheet" type="text/css" href="https://js.api.here.com/v3/3.1/mapsjs-ui.css" />
<div
  *ngIf="shouldDisplayMapPlaceholder"
  [style.width]="width"
  [style.height]="height"
  [class]="{ 'map-placeholder': true, 'map-placeholder--collapsed': isCollapsed }"
>
  <div class="map-placeholder__text">
    <img src="/assets/icons/15px/warning.svg" />
    <p class="regular-text">{{ 'common.tooltipHereMap' | translate }}</p>
  </div>
</div>
<div #map *ngIf="!shouldDisplayMapPlaceholder" [style.width]="width" [style.height]="height"></div>

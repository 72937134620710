<div
  class="height-100 flex align-items-center"
  *teleportTo="'sectionHeaderTeleport'"
>
  <img src="assets/logo/logo-dark.svg" />
</div>
<div class="a-page-registration-container-header">
  <h1 class="title">{{ 'invitationRegistration.title' | translate }}</h1>
</div>

<div
  class="a-page-registration-container"
  *ngIf="invitationRegistrationService.companyName || active === false"
>
  <p
    [innerHTML]="
      active
        ? ('invitationRegistration.invitationText'
          | translate: { companyName: invitationRegistrationService.companyName })
        : ('invitationRegistration.invitationExpired' | translate)
    "
  ></p>

  <p-button
    class="regular-yellow center"
    [label]="
      active
        ? ('invitationRegistration.button.register' | translate)
        : ('invitationRegistration.button.mainPage' | translate)
    "
    (onClick)="active ? goToRegistration() : goToMainPage()"
  >
  </p-button>
</div>

<div class="a-page-container">
  <app-listings-criteria></app-listings-criteria>
  <app-listings-results
    [listings]="listings"
    [totalCount]="totalCount"
    (loadMoreResults)="loadMoreResults($event)"
    [isLoadingMoreItems]="isLoadingMoreItems"
    [loadMoreItemsError]="loadMoreItemsError"
  ></app-listings-results>
</div>

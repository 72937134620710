import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-profile-menu-item',
  templateUrl: './profile-menu-item.component.html',
  styleUrls: ['./profile-menu-item.component.sass']
})
export class ProfileMenuItemComponent {
  @Input() icon: string;
  @Input() label: string;
  @Input() link: string;
  @Output() clickEmit: EventEmitter<void> = new EventEmitter<void>();

  handleOnClick(): void {
    this.clickEmit.emit();
  }
}

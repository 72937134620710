import { Pipe, PipeTransform } from '@angular/core';
import { NotificationType } from '../../../../../services/dto/notification.model';

@Pipe({
  name: 'notificationType'
})
export class NotificationTypePipe implements PipeTransform {
  transform(value: NotificationType): string {
    return value.toString().toLowerCase();
  }
}

<div
  *ngIf="fileUpload?.msgs?.length"
  [ngClass]="classes"
>
  <small
    class="p-error"
    *ngFor="let msg of fileUpload.msgs"
  >
    <b>{{ msg.summary }}</b> {{ msg.detail }}
  </small>
</div>

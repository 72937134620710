import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AlertDialogData } from '../../../services/custom-dialog.service';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.component.html',
})
export class AlertDialogComponent implements OnInit {
  data: AlertDialogData;

  constructor(
    public dynamicDialogConfig: DynamicDialogConfig,
    public dynamicDialogRef: DynamicDialogRef,
  ) {
  }

  ngOnInit() {
    this.initData();
  }

  initData(): void {
    this.data = this.dynamicDialogConfig.data;
  }

  close(){
    this.dynamicDialogRef.close();
  }
}

import { RWD_BREAKPOINT } from './../../../services/rwd.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Listing, Suggestion } from '../../model/ListingSearchResult';
import { Router } from '@angular/router';
import { QueryParamsService } from '../../service/query-params.service';
import { addDays, subDays } from 'date-fns';
import { StaticMapService } from '../../../services/map/static-map.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '../../../auth/auth.service';
import { FavouritesService } from '../../../services/favourites.service';
import { finalize } from 'rxjs/operators';
import { QueryMap } from '../../service/query-params.types';
import { RwdService } from '../../../services/rwd.service';

@Component({
  selector: 'app-listings-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.sass']
})
export class ItemComponent implements OnInit {
  @Input() listing: Listing;
  @Input() view: 'listings' | 'favourites' = 'listings';
  @Output() favouriteStatusChanged = new EventEmitter<void>();
  isCollapsed: boolean;
  imageUrl: any;
  isLoadingFavourite: boolean;
  RWD_BREAKPOINT = RWD_BREAKPOINT;

  constructor(
    private router: Router,
    private staticMapService: StaticMapService,
    private sanitizer: DomSanitizer,
    private query: QueryParamsService,
    public authService: AuthService,
    public favouritesService: FavouritesService,
    public rwdService: RwdService
  ) {}

  ngOnInit(): void {
    this.getStaticMapImage();
  }

  deliveryDeadline(): Date {
    return this.listing.items.map((item) => item.unloadingDate).reduce((a, b) => (a < b ? b : a));
  }

  availabilityFrom(): Date {
    return this.listing.items.map((item) => item.loadingDateFrom).reduce((a, b) => (a < b ? a : b));
  }

  availabilityTo(): Date {
    return this.listing.items.map((item) => item.loadingDateTo).reduce((a, b) => (a < b ? b : a));
  }

  getDistance(): number | null {
    return this.listing.items.length == 1 ? this.listing.items[0].distance : null;
  }

  goToDetails(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();

    const copySelection = window.getSelection();

    if (copySelection && copySelection.type === 'Range') {
      navigator.clipboard.writeText(copySelection.toString());
    }
    this.router.navigate(['carrier-offer/' + this.listing.id], { state: undefined });
  }

  generateSuggestionQueryParams(suggestion: Suggestion, orderId: string): QueryMap {
    return this.query.buildQueryParams(
      suggestion.name,
      suggestion.lat,
      suggestion.lon,
      100,
      subDays(new Date(suggestion.date), 2),
      addDays(new Date(suggestion.date), 2),
      orderId
    );
  }

  private getStaticMapImage(): void {
    this.staticMapService.getImage(this.listing.id, this.listing.version).subscribe((image: string) => {
      this.imageUrl = this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + image);
    });
  }

  toggleCollapse(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    this.isCollapsed = !this.isCollapsed;
  }

  addFav(): void {
    this.isLoadingFavourite = true;

    this.favouritesService
      .addOrderToFavourites(this.listing.id)
      .pipe(
        finalize(() => {
          this.isLoadingFavourite = false;
          this.favouriteStatusChanged.emit();
        })
      )
      .subscribe(() => {
        this.listing.alreadyInFavourites = true;
      });
  }

  removeFav(): void {
    this.isLoadingFavourite = true;

    this.favouritesService
      .deleteOrderFromFavourites(this.listing.id)
      .pipe(
        finalize(() => {
          this.isLoadingFavourite = false;
          this.favouriteStatusChanged.emit();
        })
      )
      .subscribe(() => {
        this.listing.alreadyInFavourites = false;
      });
  }

  toggleFavourite(event: MouseEvent): void {
    event.stopPropagation();
    event.preventDefault();
    if (this.listing.alreadyInFavourites) {
      this.removeFav();
    } else {
      this.addFav();
    }
  }
}

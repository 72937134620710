<div
  class="height-100 flex align-items-center"
  *teleportTo="'sectionHeaderTeleport'"
>
  <img src="assets/logo/logo-dark.svg" />
</div>
<div
  *ngIf="verified || error"
  class="a-page-registration-container-header"
>
  <h1>
    <ng-container *ngIf="verified">
      {{ 'registration-verificated-confirmation.successHeader' | translate }}
    </ng-container>
    <ng-container *ngIf="error">
      {{ 'registration-verificated-confirmation.errorHeader' | translate }}
    </ng-container>
  </h1>
</div>
<div
  *ngIf="verified || error"
  class="a-page-registration-container"
>
  <div class="text">
    <p>
      <ng-container *ngIf="verified">
        <span [innerHtml]="'registration-verificated-confirmation.successInfo' | translate"></span>
      </ng-container>
      <ng-container *ngIf="error === 'EXPIRED'">
        <span [innerHtml]="'registration-verificated-confirmation.expiredInfo' | translate"></span>
      </ng-container>
      <ng-container *ngIf="error && error !== 'EXPIRED'">
        <span [innerHtml]="'registration-verificated-confirmation.errorInfo' | translate"></span>
      </ng-container>
    </p>
  </div>
  <div class="button-container">
    <ng-container *ngIf="verified">
      <p-button
        class="regular-yellow no-wrap"
        (onClick)="goToSignIn()"
        label="{{ 'registration-verificated-confirmation.successButton' | translate }}"
      >
      </p-button>
    </ng-container>
    <ng-container *ngIf="error">
      <p-button
        class="regular-yellow no-wrap"
        (onClick)="retry()"
        label="{{ 'registration-verificated-confirmation.retryButton' | translate }}"
      >
      </p-button>
    </ng-container>
  </div>
</div>

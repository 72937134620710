/* eslint-disable sonarjs/no-duplicate-string */
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-date-element',
  templateUrl: './date-element.component.html',
  styleUrls: ['./date-element.component.sass']
})
export class DateElementComponent implements OnInit {
  @Input() date: Date;
  @Input() label: string;
  @Input() format: string;
  @Input() dateTo?: Date;
  @Input() dateToFormat? = 'dd.MM.YYYY';
  @Input() monoFilter = false;
  @Input() withoutIcon = false;

  ngOnInit(): void {
    if (this.format) {
      return;
    }
    if (this.isYearDifference()) {
      this.format = 'dd.MM.YYYY';
    } else {
      this.format = 'dd.MM';
    }
  }

  isYearDifference(): boolean {
    if (!this.dateTo) {
      return true;
    }
    const newDateFrom = new Date(this.date);
    const newDateTo = new Date(this.dateTo);
    return newDateFrom.getFullYear() !== newDateTo.getFullYear();
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {ButtonModule} from "primeng/button";
import {InputTextareaModule} from 'primeng/inputtextarea';

import {SharedModule} from "../../shared/shared.module";
import {TerminationModalComponent} from './termination-modal.component';

@NgModule({
  declarations: [
    TerminationModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ButtonModule,
    TranslateModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    InputTextareaModule
  ],
  exports: [TerminationModalComponent]
})
export class TerminationModalModule {}

import { Pipe, PipeTransform } from '@angular/core';
import { formatDistance } from 'date-fns';

import { LanguageService } from '../../../../../services/language.service';

@Pipe({
  name: 'notificationTime',
  pure: false
})
export class NotificationTimePipe implements PipeTransform {
  constructor(private languageService: LanguageService) {}

  transform(value: Date | string): string {
    if (!(value instanceof Date)) {
      value = new Date(value);
    }
    const now = new Date();
    return formatDistance(value, now, { locale: this.languageService.dateFnsLocale });
  }
}

export class UserTermsData {
  constructor(
    public termsAndConditions: boolean = false,
    public privacyPolicy: boolean = false,
    public transferPersonalData: boolean = false,
    public processingPersonalData: boolean = false,
    public useEmailAddress: boolean = false,
    public usePhoneNumber: boolean = false
  ) {}
}

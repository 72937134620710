<p-toast position="bottom-right">
  <ng-template
    let-message
    pTemplate="message"
  >
    <div class="flex align-items-center">
      <img
        *ngIf="message.data?.iconSrc"
        [src]="message.data.iconSrc"
        [style]="message.data?.iconStyle"
        class="mr-2"
      />
      <span
        class="body-bold primary-dark-purple mr-2"
        style="font-weight: 400"
      >
        {{ message.summary }}
      </span>

      <p
        *ngIf="message.detail"
        class="body-bold primary-dark-purple"
      >
        {{ message.detail }}
      </p>
    </div>
  </ng-template>
</p-toast>

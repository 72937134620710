import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Router, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class HistoryScrollPositionService {
  historyPositionScroll: {
    [key: string]: [number, number] | null,
  } = {}

  constructor(
    private router: Router,
    private viewportScroller: ViewportScroller,
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof Scroll),
      )
      .subscribe((event) => {
        if(event instanceof Scroll){
          this.historyPositionScroll[event.routerEvent.url] = event.position;
        }
      })
  }

  scrollToHistoryPosition(){
    const historyPositionScrollUrl = this.historyPositionScroll[this.router.routerState.snapshot.url];
    if(historyPositionScrollUrl?.length){
      setTimeout(() => {
        this.viewportScroller.scrollToPosition(historyPositionScrollUrl);
      })
    }
  }
}

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuItemComponent {
  @Input() icon: string;
  @Input() label: string;
  @Input() link: string;
  @Input() onlyIcon: boolean;
  @Input() badgeText?: string;
}

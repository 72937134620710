import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CompanyTaxIdUniqueValidator } from './validator/company-tax-id-unique-validator';
import { DynamicValidatorApplier } from './validator/dynamic-validator-applier';
import { RegistrationService } from '../../services/registration.service';
import { NavigationStart, Router } from '@angular/router';
import { delay, take, concat, distinctUntilChanged } from 'rxjs/operators';
import { HttpRequestChecker } from '../../interceptors/services/http-request-checker.service';
import { RegistrationStepI } from '../../guards/registration-step.guard';
import { CountryService } from '../../services/country.service';
import { FormGroupUtilService } from '../../services/form-group-util.service';
import { Observable, Subscription, of } from 'rxjs';
import { CountryData } from '../../services/dto/country-data.model';
import { ViesService } from '../../services/vies.service';
import { notOnlyWhiteCharacters } from '../../shared/validators/not-only-white.characters';
import { UserType } from '../../model/domain/user-type';
import { CompanyDocument } from './model/company-document';

@Component({
  selector: 'app-company-data',
  templateUrl: './company-data.component.html',
  styleUrls: ['./company-data.component.sass']
})
export class CompanyDataComponent implements OnInit, OnDestroy, RegistrationStepI {
  companyDataForm!: FormGroup;
  allCountries: Observable<CountryData[]>;
  companyTaxIdControlName = 'companyTaxId';
  isHttpRequestPending: boolean;
  autoFillFromViesPending: boolean;
  companyTaxIdMinLength = 2;
  companyTaxIdMaxLength = 36;
  companyNameMinLength = 2;
  companyNameMaxLength = 36;
  streetNameAndNumberMinLength = 3;
  streetNameAndNumberMaxLength = 160;
  postCodeMinLength = 2;
  postCodeMaxLength = 10;
  cityMinLength = 2;
  cityMaxLength = 30;
  shouldActivateAlertsOnHover = false;
  filteredCountries: CountryData[];
  shouldUpdatePhoneCode = false;
  _shouldShowCompanyDocuments: boolean;
  private subscriptions: Subscription[] = [];
  public _insuranceFormGroup: FormGroup;
  public _licenceFormGroup: FormGroup;

  constructor(
    private countryService: CountryService,
    private formBuilder: FormBuilder,
    private companyTaxIdUniqueValidator: CompanyTaxIdUniqueValidator,
    private dynamicValidatorApplier: DynamicValidatorApplier,
    public registrationService: RegistrationService,
    private router: Router,
    private httpRequestChecker: HttpRequestChecker,
    private formGroupUtilService: FormGroupUtilService,
    private viesService: ViesService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.initFormGroup();
    this.setNavigationChangeSubscriber();
    this.subscriptions.push(
      this.httpRequestChecker.isHttpRequestPending
        .pipe(delay(0)) // This prevents a ExpressionChangedAfterI tHasBeenCheckedError for subsequent requests
        .subscribe((isHttpRequestPending: boolean) => {
          this.isHttpRequestPending = isHttpRequestPending;
        }),
      of(1).pipe(concat(this.translate.onLangChange)).subscribe(this.onLangChanged.bind(this))
    );
    this.setInputFieldSubscribers();
    this.applyTaxIdValidators(this.companyDataForm.controls['country'].value);
    this.subscribeTrimTaxId();
    this.companyDataForm.updateValueAndValidity();
    setTimeout(() => {
      this.shouldActivateAlertsOnHover = true;
    }, 3000);
    this._shouldShowCompanyDocuments = this.shouldShowCompanyDocuments();
    this._licenceFormGroup = this.getLicenceFormGroup();
    this._insuranceFormGroup = this.getInsuranceFormGroup();
  }

  private onLangChanged(): void {
    this.allCountries = this.countryService.getAllCountries();
    this.allCountries.pipe(take(1)).subscribe((countries: CountryData[]) => {
      const countryCode = this.companyDataForm.get('country')?.value?.countryCode;
      if (countryCode) {
        const country = countries.filter((e: CountryData) => e.countryCode == countryCode)[0];
        if (country) {
          this.companyDataForm.get('country')?.setValue(country);
        }
      }
    });
  }

  private initFormGroup(): void {
    this.companyDataForm = this.formBuilder.group(
      {
        companyTaxId: [
          this.registrationService.companyData.companyTaxId,
          {
            validators: [
              Validators.required,
              Validators.minLength(this.companyTaxIdMinLength),
              Validators.maxLength(this.companyTaxIdMaxLength),
              notOnlyWhiteCharacters()
            ],
            asyncValidators: [this.companyTaxIdUniqueValidator]
          }
        ],
        country: [
          this.registrationService.companyData.countryData,
          {
            validators: [Validators.required],
            updateOn: 'change'
          }
        ],
        companyName: this.formBuilder.control(this.registrationService.companyData.companyName, [
          Validators.required,
          Validators.minLength(this.companyNameMinLength),
          Validators.maxLength(this.companyNameMaxLength),
          notOnlyWhiteCharacters()
        ]),
        streetNameAndNumber: this.formBuilder.control(this.registrationService.companyData.streetNameAndNumber, [
          Validators.required,
          Validators.minLength(this.streetNameAndNumberMinLength),
          Validators.maxLength(this.streetNameAndNumberMaxLength),
          notOnlyWhiteCharacters()
        ]),
        postCode: this.formBuilder.control(this.registrationService.companyData.postCode, [
          Validators.required,
          Validators.minLength(this.postCodeMinLength),
          Validators.maxLength(this.postCodeMaxLength),
          notOnlyWhiteCharacters()
        ]),
        city: this.formBuilder.control(this.registrationService.companyData.city, [
          Validators.required,
          Validators.minLength(this.cityMinLength),
          Validators.maxLength(this.cityMaxLength),
          notOnlyWhiteCharacters()
        ]),
        uploadCompanyDocumentsCheckbox: this.formBuilder.control(
          this.registrationService.companyData.shouldUploadCompanyDocuments,
          { updateOn: 'change' }
        ),
        licence: this.formBuilder.group({
          validUntil: this.formBuilder.control(
            {
              value: this.registrationService.companyData.licenceDocument.validUntil,
              disabled: false
            },
            { updateOn: 'change' }
          )
        }),
        insurance: this.formBuilder.group({
          validUntil: this.formBuilder.control(
            {
              value: this.registrationService.companyData.insuranceDocument.validUntil,
              disabled: false
            },
            { updateOn: 'change' }
          )
        })
      },
      { updateOn: 'blur' }
    );
  }

  private subscribeTrimTaxId(){
    this.subscriptions.push(
      this.companyDataForm.controls[this.companyTaxIdControlName].valueChanges
      .pipe(
        distinctUntilChanged(),
      )
      .subscribe((value) => {
        const newValue = String(value).trim();

        this.companyDataForm.controls[this.companyTaxIdControlName].patchValue(newValue)
      })
    )
  }

  private setInputFieldSubscribers(): void {
    this.subscriptions.push(
      this.companyDataForm.controls['country'].valueChanges.subscribe((selectedValue: CountryData) => {
        if (selectedValue) {
          this.shouldUpdatePhoneCode = true;
          this.applyTaxIdValidators(selectedValue);
        }
      })
    );
    this.subscriptions.push(
      this.companyDataForm.controls['uploadCompanyDocumentsCheckbox'].valueChanges.subscribe((value: boolean) => {
        if (!value) {
          this._licenceFormGroup.controls['validUntil'].clearValidators();
          this._insuranceFormGroup.controls['validUntil'].clearValidators();

          this._licenceFormGroup.controls['validUntil'].updateValueAndValidity();
          this._insuranceFormGroup.controls['validUntil'].updateValueAndValidity();
        }
      })
    );
  }

  nextStep(): void {
    this.formGroupUtilService.markAllFieldsAsDirtyAndUpdateValueAndValidity(this.getForm());
    if (this.companyDataForm.valid) {
      this.router.navigate(['user-data']);
    }
  }

  get form(): { [key: string]: AbstractControl } {
    return this.companyDataForm.controls;
  }

  private applyTaxIdValidators(country: CountryData): void {
    if (country && country.isViesCountry) {
      this.dynamicValidatorApplier.applyTaxIdValidatorBasedOnSelectedCountry(
        country.countryCode,
        this.companyDataForm.controls[this.companyTaxIdControlName]
      );
    } else {
      this.companyDataForm.controls[this.companyTaxIdControlName].setValidators([
        Validators.required,
        Validators.minLength(this.companyTaxIdMinLength),
        Validators.maxLength(this.companyTaxIdMaxLength),
        notOnlyWhiteCharacters()
      ]);
    }
    this.companyDataForm.controls[this.companyTaxIdControlName].updateValueAndValidity();
  }

  getForm(): FormGroup {
    return this.companyDataForm;
  }

  isFormGroupPending(): boolean {
    return this.companyDataForm.pending;
  }

  setNavigationChangeSubscriber(): void {
    this.subscriptions.push(
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.setState();
        }
      })
    );
  }

  highlightInvalidFields(): void {
    if (this.shouldActivateAlertsOnHover) {
      this.formGroupUtilService.markAllFieldsAsTouched(this.getForm());
    }
  }

  searchCountry($event: any, countries: CountryData[]): void {
    this.filteredCountries = countries.filter((element: CountryData) => {
      return element.translation?.toLowerCase().includes($event.query.toLowerCase());
    });
  }

  private setState(): void {
    this.registrationService.companyData = {
      companyTaxId: this.companyDataForm.controls[this.companyTaxIdControlName].value,
      countryData: this.companyDataForm.controls['country'].value,
      companyName: this.companyDataForm.controls['companyName'].value,
      streetNameAndNumber: this.companyDataForm.controls['streetNameAndNumber'].value,
      postCode: this.companyDataForm.controls['postCode'].value,
      city: this.companyDataForm.controls['city'].value,
      shouldUploadCompanyDocuments: JSON.parse(this.companyDataForm.controls['uploadCompanyDocumentsCheckbox'].value),
      insuranceDocument: this.registrationService.companyData.insuranceDocument,
      licenceDocument: this.registrationService.companyData.licenceDocument
    };
    this.registrationService.shouldUpdatePhoneCode = this.shouldUpdatePhoneCode;
    this.registrationService.validSteps[0] = this.companyDataForm.valid;
  }

  onAutoFillFromViesClick(): void {
    const companyTaxIdControl: AbstractControl = this.companyDataForm.controls[this.companyTaxIdControlName];
    if (companyTaxIdControl.pending) {
      companyTaxIdControl.statusChanges.pipe(take(1)).subscribe((status) => {
        if (status === 'VALID') {
          this.getDataFromVies();
        }
      });
    } else {
      this.getDataFromVies();
    }
  }

  private getDataFromVies(): void {
    this.autoFillFromViesPending = true;
    const companyTaxId: string = this.companyDataForm.controls[this.companyTaxIdControlName].value;
    this.viesService
      .getCompanyDetailsFromVies(companyTaxId)
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.companyDataForm.controls['companyName'].setValue(response.companyName);
          this.companyDataForm.controls['streetNameAndNumber'].setValue(response.streetNameAndNumber);
          this.companyDataForm.controls['postCode'].setValue(response.postalCode);
          this.companyDataForm.controls['city'].setValue(response.city);
          this.companyDataForm.updateValueAndValidity();
          this.autoFillFromViesPending = false;
        },
        () => {
          this.autoFillFromViesPending = false;
        }
      );
  }

  shouldEnableAutofillFromVies(): boolean {
    const companyTaxIdControl: AbstractControl = this.companyDataForm.controls[this.companyTaxIdControlName];
    const countryData: CountryData = this.companyDataForm.controls['country'].value;
    return (
      countryData &&
      countryData.isViesCountry !== undefined &&
      countryData.isViesCountry &&
      (companyTaxIdControl.valid || companyTaxIdControl.pending)
    );
  }

  companyDocumentChangedHandler(companyDocument: CompanyDocument): void {
    if (companyDocument.fileType === 'LICENCE') {
      this.registrationService.companyData = {
        ...this.registrationService.companyData,
        licenceDocument: companyDocument
      };
    } else if (companyDocument.fileType === 'INSURANCE') {
      this.registrationService.companyData = {
        ...this.registrationService.companyData,
        insuranceDocument: companyDocument
      };
    }
    this.companyDataForm.updateValueAndValidity();
  }

  private getInsuranceFormGroup(): FormGroup {
    return this.companyDataForm.controls['insurance'] as FormGroup;
  }

  private getLicenceFormGroup(): FormGroup {
    return this.companyDataForm.controls['licence'] as FormGroup;
  }

  private shouldShowCompanyDocuments(): boolean {
    return this.registrationService.selectedRoles.includes(UserType[UserType.CARRIER]);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
  }
}

/* eslint-disable sonarjs/no-identical-functions */
import { Injectable } from '@angular/core';
import { Login } from './login';
import { Auth } from '@aws-amplify/auth';
import { Observable, ReplaySubject, Subscriber } from 'rxjs';
import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import { environment } from '../../environments/environment';
import { Amplify } from '@aws-amplify/core';
import { InactivityCheckerService } from '../services/inactivity-checker.service';
import { UserRole } from '../model/domain/user-role.type';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: CognitoUser | null = null;
  error: Error | null = null;
  private userInfo: UserInfo;
  private _loggedIn = false;
  private _loggedInSubject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  public loggedIn$: Observable<boolean> = this._loggedInSubject.asObservable();
  role$ = new ReplaySubject<UserRole>(1);

  constructor(private inactivityChecker: InactivityCheckerService) {
    Amplify.configure(environment.amplify);
  }

  private setLoggedIn(value: boolean) {
    if (this._loggedIn == value) return;

    this._loggedIn = value;

    if (value) {
      this.inactivityChecker.init();
    } else {
      this.inactivityChecker.destroy();
    }

    this._loggedInSubject.next(value);
  }

  get loggedIn(): boolean {
    return this._loggedIn;
  }

  auth(user: CognitoUser): void {
    this.user = user;
    this.userInfo = this.parseUserAttributes(user, user.getSignInUserSession()!.getIdToken().getJwtToken());
    this.setLoggedIn(true);
    this.setDataForGTM();

    this.initRole();
  }

  private setDataForGTM() {
    (<any>window).user = this.userInfo;

    if ((<any>window).user) {
      (<any>window).user.roleGTM = {
        SHIPPER: this.userHasRole('SHIPPER'),
        CARRIER: this.userHasRole('CARRIER')
      };
    }
  }

  public refreshUser(): Observable<CognitoUser> {
    return new Observable<CognitoUser>((observer) => {
      Auth.currentAuthenticatedUser({ bypassCache: true })
        .then((user: CognitoUser) => {
          this.auth(user);
          observer.next(user);
        })
        .catch((err: any) => {
          observer.error(err);
        });
    });
  }

  public isAuthenticated(bypassCache = false): Promise<boolean> {
    return Auth.currentAuthenticatedUser({ bypassCache })
      .then((login) => {
        this.auth(login);
        return true;
      })
      .catch((err: Error | null) => {
        console.error(err);
        this.error = err;
        this.setLoggedIn(false);
        localStorage.clear();
        return false;
      });
  }

  public isNotAuthenticated(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      Auth.currentAuthenticatedUser()
        .then(() => {
          observer.next(false);
        })
        .catch(() => {
          observer.next(true);
        });
    });
  }

  login(login: Login): Observable<void> {
    return new Observable<void>((observer) => {
      Auth.signIn(login.loginField, login.password)
        .then((login: any) => {
          this.auth(login);
          observer.next(login);
        })
        .catch((err: Error | null) => {
          console.error(err);
          this.error = err;
          observer.error(err);
          this.setLoggedIn(false);
        });
    });
  }

  logout(): Observable<void> {
    return new Observable<void>((observer) => {
      localStorage.removeItem(`streamChatToken_${this.userInfo.userId}`);
      Auth.signOut().finally(() => {
        this.setLoggedIn(false);
        observer.next();
        observer.complete();
      });
    });
  }

  verify(verification: UserVerification): Observable<void> {
    return new Observable<void>((observer) => {
      Auth.confirmSignUp(verification.userName, verification.code)
        .then((confirm: any) => {
          observer.next(confirm);
        })
        .catch((error: Error | null) => {
          console.error(error);
          this.error = error;
          observer.error(error);
        });
    });
  }

  resendEmail(username: string): Observable<void> {
    return new Observable<void>((observer) => {
      Auth.resendSignUp(username)
        .then((confirm: any) => {
          observer.next(confirm);
        })
        .catch((error: Error | null) => {
          console.error(error);
          this.error = error;
          observer.error(error);
        });
    });
  }

  resetPassword(username: string): Observable<void> {
    return new Observable<void>((observer) => {
      Auth.forgotPassword(username)
        .then((confirm: any) => {
          observer.next(confirm);
        })
        .catch((error: Error | null) => {
          console.error(error);
          this.error = error;
          observer.error(error);
        });
    });
  }

  newPasswordSubmit(username: string, code: string, newPassword: string): Observable<string> {
    return new Observable<string>((observer) => {
      Auth.forgotPasswordSubmit(username, code, newPassword)
        .then((confirm: any) => {
          observer.next(confirm);
        })
        .catch((error: Error | null) => {
          console.error(error);
          this.error = error;
          observer.error(error);
        });
    });
  }

  /*
  //developer test use only. Keep it commented if you are not using it!!!!
  register(register: Register){
    return new Observable<any>((observer)=> {
      Auth.signUp({
        username: register.username,
        password: register.password,
        attributes: {
          email: register.email
        }
      }).then(reg => {
        observer.next(reg);
      }).catch(err => {
        console.error(err);
        this.error = err;
        observer.error(err);
      });
    });
  }
   */

  public getUserInfo(): UserInfo {
    return this.userInfo;
  }

  public userHasRole(role: UserRole): boolean {
    return this.userInfo?.groups.indexOf(role) > -1 || false;
  }

  public companyHasRole(role: UserRole): boolean {
    return this.userInfo?.companyRoles.indexOf(role) > -1 || false;
  }

  getIdToken(): Observable<string | undefined> {
    return new Observable<string | undefined>((subscriber: Subscriber<string | undefined>) => {
      if (this.loggedIn) {
        Auth.currentSession()
          .then((session: CognitoUserSession) => {
            subscriber.next(session.getIdToken().getJwtToken());
          })
          .catch((error: any) => {
            console.error(error);
            this.logout().subscribe(undefined, undefined, () => {
              window.location.assign('/sign-in');
            });
            subscriber.next(undefined);
          })
          .finally(() => {
            subscriber.complete();
          });
      } else {
        subscriber.next(undefined);
        subscriber.complete();
      }
    });
  }

  // eslint-disable-next-line
  private parseUserAttributes(login: any, jwt: string): UserInfo {
    const attributes = login.attributes;
    let groups = [];
    try {
      groups = JSON.parse(atob(jwt.split('.')[1]))['cognito:groups'];
    } catch (error) {
      groups = attributes['custom:roles'].split(',');
      groups.push(attributes['custom:companyId']);
    }
    return {
      userId: attributes.sub,
      email: attributes.email,
      name: attributes.name,
      companyId: attributes['custom:companyId'],
      language: attributes['custom:language'],
      companyRoles: attributes['custom:companyRoles']?.split(',') || [],
      groups
    };
  }

  private initRole() {
    const defaultRole = this.getAvailableUserRole()?.[0];
    const localStorageRole = this.getRoleFromLocalStorage();
    const isExistingRoleInAvailable = this.getAvailableUserRole().find((role) => role === localStorageRole);

    if (isExistingRoleInAvailable) {
      this.setRole(localStorageRole);
    } else {
      this.setRole(defaultRole);
    }
  }

  setRole(role: UserRole): void {
    this.role$.next(role);
    this.setRoleToLocalStorage(role);
  }

  private setRoleToLocalStorage(role: UserRole) {
    localStorage.setItem(this.getUserInfo().userId, role);
  }

  private getRoleFromLocalStorage(): UserRole {
    return <UserRole>localStorage.getItem(this.getUserInfo().userId);
  }

  getAvailableUserRole(): UserRole[] {
    return <UserRole[]>this.getUserInfo().groups.filter((role) => role === 'CARRIER' || role === 'SHIPPER');
  }
}

export type UserInfo = {
  readonly userId: string;
  readonly companyId: string;
  readonly email: string;
  readonly name: string;
  readonly groups: (string | UserRole)[];
  readonly language: string | undefined;
  readonly companyRoles: string;
};

export type UserVerification = {
  readonly userName: string;
  readonly code: string;
};

import { Directive, HostBinding } from '@angular/core';
import { FileUpload } from 'primeng/fileupload';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[appCustomPFileUpload]'
})
export class CustomFormUploadDirective {
  @HostBinding('class.hide-messages') hideMessageClass = true;

  constructor(private fileUpload: FileUpload, private translate: TranslateService) {
    this.setNewText();
  }

  setNewText(): void {
    this.fileUpload.invalidFileSizeMessageSummary = this.translate.instant('common.alerts.fileUpload.invalidFileSizeMessageSummary');
    this.fileUpload.invalidFileSizeMessageDetail = this.translate.instant('common.alerts.fileUpload.invalidFileSizeMessageDetail');
    this.fileUpload.invalidFileTypeMessageSummary = this.translate.instant('common.alerts.fileUpload.invalidFileTypeMessageSummary');
    this.fileUpload.invalidFileTypeMessageDetail = this.translate.instant('common.alerts.fileUpload.invalidFileTypeMessageDetail');
    this.fileUpload.invalidFileLimitMessageDetail = this.translate.instant('common.alerts.fileUpload.invalidFileLimitMessageDetail');
    this.fileUpload.invalidFileLimitMessageSummary = this.translate.instant('common.alerts.fileUpload.invalidFileLimitMessageSummary');

    // TODO: set messages dynamically in reaction to `this.translate.onLangChange`? (may be not necessary)
  }
}



import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Password } from 'primeng/password';

@Component({
  selector: 'adamp-input-password', // eslint-disable-line
  templateUrl: './input-password.component.html',
  styleUrls: ['./input-password.component.sass']
})
export class InputPasswordComponent {
  @ViewChild('passwordInput') input: Password;
  @Input() form: FormGroup;
  @Input() controlName = 'password';
  @Input() error = false;

  private masked = false;
  iconType = PasswordIconType.CLOSED;

  changeType(): void {
    this.iconType = this.masked ? PasswordIconType.CLOSED : PasswordIconType.OPEN;
    this.input.input.nativeElement.type = this.masked ? 'password' : 'text';
    this.masked = this.iconType === PasswordIconType.OPEN;
  }
}

enum PasswordIconType {
  CLOSED = 'eye-closed',
  OPEN = 'eye-open'
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { concat } from 'rxjs/operators';
import { TerminationModalComponent } from '../../../../termination-modal/termination-modal.component';
import { MenuItem } from 'primeng/api';
import { Router } from '@angular/router';
import { AuthService } from '../../../../../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ShipmentOrderRestService } from '../../../../../services/shipment-order-rest.service';
import { GlobalLoaderService } from '../../../global-loader/global-loader.service';
import { DialogService } from 'primeng/dynamicdialog';
import { CustomizableDialogService } from '../../../../../services/customizable-dialog.service';
import { OrderHeaderData } from '../order-header/order-header.component';

@Component({
  selector: 'app-manage-order',
  templateUrl: './manage-order.component.html',
  styleUrls: ['./manage-order.component.sass']
})
export class ManageOrderComponent implements OnInit, OnDestroy {
  @Input() data: OrderHeaderData;
  @Input() classList: string[];
  @Input() buttonStyle = 'regular-text uppercase small-white';
  @Input() menuStyleClass = 'manage-order-menu';
  cancelOrderDialogVisible = false;
  menuItems: MenuItem[];
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private authService: AuthService,
    private translate: TranslateService,
    private shipmentOrderService: ShipmentOrderRestService,
    private globalLoaderService: GlobalLoaderService,
    private dialogService: DialogService,
    private customizableDialog: CustomizableDialogService
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(of(1).pipe(concat(this.translate.onLangChange)).subscribe(this.setMenuItems.bind(this)));
  }

  ngOnDestroy(): void {
    for (const subs of this.subscriptions) {
      subs.unsubscribe();
    }
  }

  setMenuItems(): void {
    const editOrderTranslation = this.translate.instant('shipmentOrder.manage.menus.edit');
    const duplicateOrderTranslation = this.translate.instant('shipmentOrder.manage.menus.duplicate');
    const cancelOrderTranslation = this.translate.instant('shipmentOrder.manage.menus.cancel');
    const terminateOrderTranslation = this.translate.instant('shipmentOrder.manage.menus.terminate');

    this.menuItems = [
      {
        label: editOrderTranslation,
        icon: 'prilo-icon prilo-icon-edit',
        visible: this.editable,
        url: this.navigateToEdit,
        target: '_self'
      },
      {
        label: duplicateOrderTranslation,
        icon: 'prilo-icon prilo-icon-duplicate',
        visible: !this.data.idSourceApp,
        url: this.navigateToDuplicate,
        target: '_self'
      },
      { separator: true, visible: this.cancelable || this.terminable },
      {
        label: cancelOrderTranslation,
        icon: 'pi pi-ban',
        visible: this.cancelable,
        command: () => {
          this.showCancelOrderDialog();
        }
      },
      {
        label: terminateOrderTranslation,
        icon: 'pi pi-ban',
        visible: this.terminable,
        command: () => {
          this.showTerminationDialog();
        }
      }
    ];
  }

  get navigateToEdit(): string {
    return '/order-edit/' + this.data.orderId;
  }

  get navigateToDuplicate(): string {
    return 'shipment-order/duplicate/' + this.data.orderId;
  }

  showCancelOrderDialog(): void {
    this.cancelOrderDialogVisible = true;
  }

  hideCancelOrderDialog(): void {
    this.cancelOrderDialogVisible = false;
  }

  showTerminationDialog(): void {
    this.dialogService
      .open(TerminationModalComponent, {
        ...this.customizableDialog.dialogConfig(),
        height: 'auto',
        data: {
          itemType: 'ORDER',
          orderId: this.data.orderId
        }
      })
      .onClose.subscribe((result: any) => {
        if (result) {
          this.reloadTheSameUrl();
        }
      });
  }

  cancelOrder(): void {
    this.globalLoaderService.show();
    this.shipmentOrderService.cancelOrder(this.data.orderId).subscribe(
      () => {
        setTimeout(() => {
          this.globalLoaderService.hide();
          this.reloadTheSameUrl();
        }, 2000);
      },
      (error) => {
        this.globalLoaderService.hide();
      }
    );
  }

  get isOrderOwner(): boolean {
    return this.data.createdBy === this.authService.getUserInfo().userId;
  }

  get cancelable(): boolean {
    return this.isOrderOwner && this.data.orderStatus == 'PUBLISHED' && !this.data.idSourceApp;
  }

  get terminable(): boolean {
    return this.isOrderOwner && this.data.orderStatus == 'ONGOING' && !this.data.idSourceApp;
  }

  get editable(): boolean {
    return this.isOrderOwner && this.data.orderStatus == 'PUBLISHED' && !this.data.idSourceApp;
  }

  reloadTheSameUrl(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([this.router.url]);
  }
}

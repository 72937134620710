import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { UserRole } from '../model/domain/user-role.type';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public authService: AuthService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.isAuthenticated().then((isAuth) => {
      if (!isAuth) {
        this.router.navigate(['sign-in'], {
          queryParams: {
            returnUrl: state.url
          }
        });
        return false;
      }
      if (!route.data.roles) return true;
      for (const role of route.data.roles) {
        if (this.authService.userHasRole(role as UserRole)) return true;
      }
      this.router.navigate(['dashboard']);
      return false;
    });
  }
}

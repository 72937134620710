<div class="a-page-registration-container">
  <div class="body-large-bold terms-title">{{ 'terms.title' | translate }}</div>
  <div class="field-checkbox align-items-start">
    <p-checkbox
      class="column underline"
      [binary]="true"
      label="{{ 'terms.selectAll' | translate }}"
      (onChange)="changeAllValues($event.checked)"
    ></p-checkbox>
  </div>
  <div [formGroup]="formGroup">
    <div class="field-checkbox align-items-start">
      <p-checkbox
        class="column underline"
        inputId="termsAndConditions"
        formControlName="termsAndConditions"
        [binary]="true"
      ></p-checkbox>
      <label
        class="column"
        for="termsAndConditions"
        [innerHTML]="'terms.termsAndConditions' | translate"
      ></label>
    </div>

    <div class="field-checkbox align-items-start">
      <p-checkbox
        class="column underline"
        inputId="privacyPolicy"
        formControlName="privacyPolicy"
        [binary]="true"
      ></p-checkbox>
      <label
        class="column"
        for="privacyPolicy"
        [innerHTML]="'terms.privacyPolicy' | translate"
      ></label>
    </div>

    <div class="field-checkbox align-items-start">
      <p-checkbox
        inputId="transferPersonalData"
        formControlName="transferPersonalData"
        [binary]="true"
      ></p-checkbox>
      <label
        class="column"
        for="transferPersonalData"
        [innerHTML]="'terms.transferPersonalData' | translate"
      ></label>
    </div>

    <div class="field-checkbox align-items-start">
      <p-checkbox
        inputId="processingPersonalData"
        formControlName="processingPersonalData"
        [binary]="true"
      ></p-checkbox>
      <label
        class="column"
        for="processingPersonalData"
        [innerHTML]="'terms.processingPersonalData' | translate"
      ></label>
    </div>
    <div class="field-checkbox g-pl-20 align-items-start">
      <p-checkbox
        inputId="useEmailAddress"
        formControlName="useEmailAddress"
        [binary]="true"
      ></p-checkbox>
      <label
        class="column"
        for="useEmailAddress"
        [innerHTML]="'terms.useEmailAddress' | translate"
      ></label>
    </div>
    <div class="field-checkbox g-pl-20 align-items-start">
      <p-checkbox
        inputId="usePhoneNumber"
        formControlName="usePhoneNumber"
        [binary]="true"
      ></p-checkbox>
      <label
        class="column"
        for="usePhoneNumber"
        [innerHTML]="'terms.usePhoneNumber' | translate"
      ></label>
    </div>
  </div>

  <div class="info-note">
    {{ 'terms.infoNote' | translate }}
  </div>

  <div class="grid justify-content-center md:justify-content-end">
    <p-button
      [disabled]="!formGroup.valid"
      type="submit"
      label="{{ 'common.createAccountButton' | translate }}"
      class="thin-yellow"
      (mouseenter)="highlightInvalidFields()"
      (onClick)="invitationMode ? createUser() : createAccount()"
    ></p-button>
  </div>
</div>

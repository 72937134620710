<div class="notification-modal">
  <div class="notification-modal__title">{{ 'notifications.modal.title' | translate }}</div>
  <div class="notification-modal__content">
    <ng-container *ngFor="let notification of notifications; trackBy: trackNotification">
      <app-notification-tile
        [notification]="notification"
        (buttonClicked)="tileActionBtnClicked($event, notification.id, notification.creationTime)"
        (markClicked)="markReadBtnClicked(notification.id, notification.creationTime)"
        (deleteClicked)="deleteNotification(notification.id, notification.creationTime)"
      ></app-notification-tile>
    </ng-container>
  </div>
  <div class="notification-modal__button">
    <a
      [routerLink]="['/notifications']"
      target="_self"
      class="regular-dark"
      (click)="additionalNotificationsRouteAction()"
      >{{
        notificationsCount <= 0
          ? ('notifications.modal.buttonSimple' | translate)
          : ('notifications.modal.button' | translate: { number: notificationsCount })
      }}</a
    >
  </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-cancel-order-dialog',
  templateUrl: './cancel-order-dialog.component.html',
  styleUrls: ['./cancel-order-dialog.component.sass']
})
export class CancelOrderDialogComponent {
  private _display = false;
  @Output() hideEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() confirmCancel: EventEmitter<any> = new EventEmitter<any>();

  @Input() set display(value: boolean) {
    this._display = value;
  }

  get display(): boolean {
    return this._display;
  }

  emitHideModalEvent(): void {
    this.hideEvent.emit(true);
  }

  cancel(): void {
    this.emitHideModalEvent();
  }

  confirm() {
    this.confirmCancel.emit();
    this.emitHideModalEvent();
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { NotificationHttpService } from '../../../../services/notification-http.service';
import { NotificationService } from '../../../../services/notification.service';
import { GetUnreadNotificationsForUserIdQueryResponse } from '../../../../services/dto/notification.model';

@Injectable()
export class NotificationIconService {
  private readonly switchView: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly switchViewState: Observable<boolean> = this.switchView.asObservable();

  constructor(
    private router: Router,
    private httpService: NotificationHttpService,
    private notificationService: NotificationService
  ) {}

  iconClicked(withUnread: boolean): void {
    if (withUnread) {
      this.getNewestAndOpenModal();
    } else {
      this.navigateToPage();
    }
  }

  hideModal(): void {
    this.switchView.next(false);
  }

  navigateToPage(): void {
    this.router.navigate(['/notifications']).then(() => this.hideModal());
  }

  private getNewestAndOpenModal(): void {
    this.httpService.getUnreadNotifications().subscribe((res: GetUnreadNotificationsForUserIdQueryResponse) => {
      this.notificationService.notifications.next(res);
      this.switchView.next(true);
    });
  }
}

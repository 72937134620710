import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function notOnlyWhiteCharacters(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (control.value && control.value.trim().length == 0) {
      return { onlyWhiteCharacters: true };
    } else {
      return null;
    }
  };
}

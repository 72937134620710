import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { environment } from '../../../environments/environment';
import { Position } from '../../services/map/map.service';
import { MapIconsService } from '../../services/map-icons.service';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../services/language.service';
import { Subscription } from 'rxjs';
import { CustomDialogService } from '../../services/custom-dialog.service';
import { Router } from '@angular/router';

declare let H: any;

@Component({
  selector: 'app-here-map',
  templateUrl: './here-map.component.html',
  styleUrls: ['./here-map.component.sass']
})
export class HereMapComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  private apiKey = environment.hereMap.apiKey;
  private apiVersion = environment.hereMap.apiVersion;
  private vanillaColor = '#FFFCEB';

  @ViewChild('map', { static: false }) public mapElement?: ElementRef;
  @Input() width = '720px';
  @Input() height = '300px';
  @Input() mapZoom = 6;
  @Input() mapCenter: Position = { lat: 52.339, lng: 19.0712 };
  @Input() points: Position[];
  @Input() positions: (Position | undefined)[];
  @Input() loadings: Position[];
  @Input() unloadings: Position[];
  @Input() truckDimensions = { grossWeight: 36000, weightPerAxle: 11500, height: 400, length: 2075, width: 250 };
  @Output() distanceEmitter: EventEmitter<number> = new EventEmitter<number>();
  @Output() routeCalculationError: EventEmitter<void> = new EventEmitter<void>();
  @Input() staticMode = false;
  @Input() allTrucksMode = false;
  @Input() reload: any;
  @Input() mapHereContainerWidthHelper: string;
  @Input() isCollapsed: boolean | undefined;
  @Output() isMapPlaceholderVisible = new EventEmitter<boolean>();
  private platform: any;
  private map: any;
  private router: any;
  private routeStyle = { strokeColor: 'rgba(126, 36, 255, 0.45)', lineWidth: 8 };
  private alphabet: string[];
  private languageCode = this.translate.currentLang || environment.hereMap.languageCode;
  private sub: Subscription;
  shouldDisplayMapPlaceholder = false;

  constructor(
    private elRef: ElementRef,
    private mapIconsService: MapIconsService,
    private translate: TranslateService,
    private languageService: LanguageService,
    private customDialogService: CustomDialogService,
    private angularRouter: Router
  ) {}

  ngOnInit(): void {
    this.sub = this.languageService.changeLanguageMessage.subscribe((languageCode: string) => {
      this.changeMapLanguage(languageCode);
      this.reloadMap();
    });
    this.platform = new H.service.Platform({
      apikey: this.apiKey
    });
    this.router = this.platform.getRoutingService(null, this.apiVersion);
    const alpha = Array.from(Array(26)).map((e, i) => i + 65);
    this.alphabet = alpha.map((x) => String.fromCharCode(x));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.map) {
      if (changes.reload && !changes.reload.firstChange) {
        setTimeout(() => {
          this.reloadMap();
        });
      } else {
        this.drawDataOnMap();
      }
    }
  }

  ngAfterViewInit(): void {
    const defaultLayers = this.platform.createDefaultLayers({ lg: this.languageCode });
    if (this.mapElement) {
      const map = new H.Map(this.mapElement.nativeElement, defaultLayers.vector.normal.map, {
        zoom: this.mapZoom,
        center: this.mapCenter,
        padding: { top: 100, left: 100, bottom: 100, right: 100 }
      });
      this.map = map;

      new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
      const provider = map.getBaseLayer().getProvider();
      const style = new H.map.Style('/assets/map/here_map_style.yaml', window.location.origin + '/assets/map/');
      provider.setStyle(style);
      if (this.loadings && this.unloadings) {
        this.clearMap();
        this.drawMapForAllOrderItems(this.loadings, this.unloadings);
      }
      if (this.staticMode) {
        this.staticModeFunction();
      }
      this.drawDataOnMap();
    }
  }

  staticModeFunction(): void {
    let index = 0;
    const addEventListener = this.map.getEngine().addEventListener('render', (evt: any) => {
      if (this.map.getEngine() === evt.target && index == 0) {
        index++;
        this.map.getEngine().removeEventListener('render', { addEventListener });
        const el = document.createElement('ready');
        this.elRef.nativeElement.appendChild(el);
      }
    });
  }

  reloadMap(): void {
    if (this.platform) {
      this.map.dispose();
      this.ngAfterViewInit();
    }
  }

  private drawDataOnMap(): void {
    this.clearMap();
    this.drawMarkers();
    this.calculateAndDrawRoute();
    this.drawPositions();
    if (this.loadings && this.unloadings) this.drawMapForAllOrderItems(this.loadings, this.unloadings);
  }

  private calculateAndDrawRoute() {
    if (this.points !== undefined && this.points.length == 2) {
      this.router.calculateRoute(
        {
          routingMode: 'fast',
          transportMode: 'truck',
          origin: this.mapPointToString(this.points[0]),
          destination: this.mapPointToString(this.points[1]),
          truck: JSON.stringify(this.truckDimensions),
          return: 'polyline,summary'
        },
        (result: any) => {
          this.drawRoute(result);
        },
        (error: any) => {
          this.showErrorAlert();
          this.shouldDisplayMapPlaceholder = true;
          this.isMapPlaceholderVisible.emit(true);
          console.error(error);
        }
      );
    }
  }

  private mapPointToString(point: Position) {
    return point.lat + ',' + point.lng;
  }

  private drawMarkers(): void {
    const group = new H.map.Group();
    if (this.map && this.points !== undefined && this.points.length > 0 && this.points.length !== 2) {
      for (let i = 0; i < this.points.length; i++) {
        const marker = this.mapIconsService.getMarker(this.points[i], this.alphabet[i]);
        group.addObject(marker);
      }
      this.map.addObject(group);
      this.map.getViewModel().setLookAtData({
        bounds: group.getBoundingBox()
      });
    }
  }

  private drawPositions(): void {
    const group = new H.map.Group();
    if (this.map && this.positions !== undefined && this.positions.length > 0) {
      for (let i = 0; i < this.positions.length; i++) {
        if (this.positions[i]) {
          const marker = this.getTrackingIcon(i);
          group.addObject(marker);
        }
      }
      if (group.getObjects().length) {
        this.map.addObject(group);
        this.map.getViewModel().setLookAtData({
          bounds: group.getBoundingBox()
        });
      }
    }
  }

  private getTrackingIcon(index: number): any {
    if (this.allTrucksMode) {
      return this.mapIconsService.getTruckIcon(this.positions[index], this.positions[index]?.label || '');
    }
    return this.mapIconsService.getTrackingDot(this.positions[index], this.positions[index]?.label || '');
  }

  private drawRoute(result: any) {
    this.shouldDisplayMapPlaceholder = false;
    this.isMapPlaceholderVisible.emit(false);

    if (result.routes.length) {
      if (result.routes[0].sections.length === 1) {
        this.drawRouteForOneSection(result.routes[0].sections[0]);
      } else if (result.routes[0].sections.length > 1) {
        this.drawRouteForMultipleSections(result.routes[0].sections);
      }
    } else {
      this.routeCalculationError.emit();
    }
  }

  private drawRouteForOneSection(section: any) {
    const linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
    const group = new H.map.Group();
    const routeLine = new H.map.Polyline(linestring, {
      style: this.routeStyle
    });
    this.emitDistanceValue(section?.summary?.length);
    const startMarker = this.mapIconsService.getMarker(section.departure.place.location, 'A');
    const endMarker = this.mapIconsService.getMarker(section.arrival.place.location, 'B', this.vanillaColor);
    this.map.addObjects([routeLine, startMarker, endMarker]);
    group.addObject(startMarker);
    group.addObject(endMarker);
    group.addObject(routeLine);
    this.map.addObject(group);
    this.map.getViewModel().setLookAtData({ bounds: group.getBoundingBox() });
  }

  private drawRouteForMultipleSections(sections: any[]) {
    let distance = 0;
    let routeLine: any;
    const group = new H.map.Group();
    sections.forEach((section: any) => {
      const linestring = H.geo.LineString.fromFlexiblePolyline(section.polyline);
      routeLine = new H.map.Polyline(linestring, {
        style: this.routeStyle
      });
      distance += section?.summary?.length;
      this.map.addObjects([routeLine]);
    });
    const startMarker = this.mapIconsService.getMarker(sections[0].departure.place.location, 'A');
    const endMarker = this.mapIconsService.getMarker(
      sections[sections.length - 1].arrival.place.location,
      'B',
      this.vanillaColor
    );
    group.addObject(startMarker);
    group.addObject(endMarker);
    this.emitDistanceValue(distance);
    this.map.addObject(group);
    this.map.getViewModel().setLookAtData({ bounds: group.getBoundingBox() });
  }

  private emitDistanceValue(distance: number): void {
    this.distanceEmitter.emit(distance);
  }

  private clearMap(): void {
    this.map.removeObjects(this.map.getObjects());
  }

  private drawMapForAllOrderItems(loadings: Position[], unloadings: Position[]): void {
    if (this.map && loadings.length === 1 && unloadings.length === 1) {
      this.drawRouteForStaticMap(loadings[0], unloadings[0]);
    }
    if (this.map && loadings.length > 0 && unloadings.length > 0 && loadings.length !== 1 && unloadings.length !== 1) {
      loadings = this.getUniquePositions(loadings);
      unloadings = this.getUniquePositions(unloadings);
      unloadings = this.filterDupliacatedPositions(loadings, unloadings);
      this.drawMarkersForStaticMap(loadings, unloadings);
    }
  }

  private getUniquePositions(positions: Position[]): Position[] {
    return Array.from(new Set(positions.map((data) => JSON.stringify(data)))).map((data) => JSON.parse(data));
  }

  private filterDupliacatedPositions(loadings: Position[], unloadings: Position[]): Position[] {
    return unloadings.filter((unloading) =>
      loadings.every((loading) => loading.lng !== unloading.lng && loading.lat !== unloading.lat)
    );
  }

  private drawRouteForStaticMap(start: Position, end: Position): void {
    this.points = [];
    this.points[0] = start;
    this.points[1] = end;
    this.calculateAndDrawRoute();
    this.points = [];
  }

  private drawMarkersForStaticMap(loadings: Position[], unloadings: Position[]): void {
    const group = new H.map.Group();
    let counter = 0;
    for (let i = 0; i < loadings.length; i++) {
      const marker = this.mapIconsService.getMarker(loadings[i], this.alphabet[counter + i]);
      group.addObject(marker);
      counter++;
    }
    counter = 0;
    for (let i = 0; i < unloadings.length; i++) {
      const marker = this.mapIconsService.getMarker(unloadings[i], this.alphabet[counter + i + 1], this.vanillaColor);
      group.addObject(marker);
      counter++;
    }
    this.map.addObject(group);
    this.map.getViewModel().setLookAtData({
      bounds: group.getBoundingBox()
    });
  }

  public changeMapLanguage(languageCode: string): void {
    this.languageCode = languageCode;
  }

  private showErrorAlert(): void {
    this.customDialogService.openConfirm({
      config: {
        closable: true,
        data: {
          text: this.translate.instant('common.errorHereMap'),
          id: 'map-error-alert'
        }
      },
      callback: (confirm) => {
        if (confirm) {
          this.calculateAndDrawRoute();
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.sub) this.sub.unsubscribe();
  }
}

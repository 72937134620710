import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SectionHeaderItemComponent } from '../../section-header.model';
import { OrderStatus } from '../../../../orders-search/model/order-criteria';

@Component({
  selector: 'app-order-header',
  templateUrl: './order-header.component.html',
  styleUrls: ['./order-header.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrderHeaderComponent implements SectionHeaderItemComponent {
  @Input() data: OrderHeaderData;
  @Input() classList: string[];
}

export type OrderHeaderData = Readonly<{
  orderPresentationId: string;
  orderId: string;
  orderStatus: OrderStatus;
  createdBy: string;
  idSourceApp?: string;
}>;

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RatingComponent } from './rating.component';
import { TranslateModule } from '@ngx-translate/core';
import { RatingModule as PrimengRatingModule } from 'primeng/rating';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [RatingComponent],
  exports: [RatingComponent],
  imports: [CommonModule, PrimengRatingModule, TranslateModule, FormsModule]
})
export class RatingModule {}

import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { OrderHistoryResponseDto } from '../../../services/dto/response/order-history-response.dto';
import { finalize } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ShipmentOrderRestService } from '../../../services/shipment-order-rest.service';

@Component({
  selector: 'app-order-history-button',
  templateUrl: './order-history-button.component.html',
  styleUrls: ['./order-history-button.component.sass'],
})
export class OrderHistoryButtonComponent implements OnInit {
  @Input() orderId: string;
  @Input() type: 'SHIPPER' | 'CARRIER' = 'SHIPPER';
  loadingOrderHistory: boolean;
  orderHistory: OrderHistoryResponseDto;

  get orderHistoryRoute(): string {
    let url = '/order-history/';

    if(this.type === 'CARRIER'){
      url += 'carrier/'
    }

    return `${url}${this.orderId}`;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private shipmentOrderRestService: ShipmentOrderRestService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params) => {
      this.getOrderHistory(params['orderId']);
    });
  }

  getOrderHistory(orderId: string): void {
    this.loadingOrderHistory = true;
    this.shipmentOrderRestService
      .orderHistory(orderId)
      .pipe(
        finalize(() => {
          this.loadingOrderHistory = false;
          this.changeDetectorRef.detectChanges();
        })
      )
      .subscribe((resp: OrderHistoryResponseDto) => {
        this.orderHistory = resp;
      })
  }
}

import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../../../../../services/language.service';
import { LanguageItem, LANGUAGES } from '../../../../../model/domain/languages';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.sass']
})
export class LanguageSelectorComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  languages: LanguageItem[] = LANGUAGES;
  selectedLanguage: LanguageItem;
  clicked = false;

  constructor(
    private languageService: LanguageService,
    private translate: TranslateService,
  ) {
    this.selectedLanguage = this.findLanguageItem(this.translate.currentLang);
  }

  private findLanguageItem(languageCode: string): LanguageItem {
    return this.languages.find((e: LanguageItem) => e.languageCode == languageCode)!;
  }

  ngOnInit(): void {
    this.subscriptions.push(this.languageService.changeLanguageMessage.subscribe((languageCode: string) => {
      this.selectedLanguage = this.findLanguageItem(languageCode);
    }));
  }

  ngOnDestroy(): void {
    for (const sub of this.subscriptions) {
      sub.unsubscribe();
    }
  }

  setSelectedLanguage(selectedValue: LanguageItem): void {
    this.languageService.setLanguage(selectedValue.languageCode);
  }
}

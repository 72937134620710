<div #container [ngClass]="'p-splitbutton p-component'" [ngStyle]="style" [class]="styleClass">
  <p-button
    type="button"
    [icon]="icon"
    [iconPos]="iconPos"
    [label]="label"
    (onClick)="onDropdownButtonClick($event)"
    [class]="buttonStyle"
    [disabled]="disabled"
    [attr.aria-label]="expandAriaLabel"
  >
  </p-button>
  <p-tieredMenu
    #menu
    [popup]="true"
    [model]="model"
    [style]="menuStyle"
    [styleClass]="menuStyleClass"
    [appendTo]="appendTo"
    [showTransitionOptions]="showTransitionOptions"
    [hideTransitionOptions]="hideTransitionOptions"
  >
  </p-tieredMenu>
</div>

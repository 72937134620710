import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationService } from '../../services/registration.service';

@Component({
  selector: 'app-role-selection',
  templateUrl: './role-selection.component.html',
  styleUrls: ['./role-selection.component.sass']
})
export class RoleSelectionComponent {
  selectedRoles: string[] = [];

  constructor(private router: Router, private registrationService: RegistrationService) {}

  goToRegistration(): void {
    this.registrationService.selectedRoles = this.selectedRoles;
    this.router.navigate(['company-data']);
  }
}

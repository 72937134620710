import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { CompanyDetailsViesResponse } from './dto/response/company-details-vies-response';

@Injectable({
  providedIn: 'root'
})
export class ViesService {
  private apiUrl = environment.config.apiUrl;
  private getCompanyDetailsFromViesEndpoint = environment.config.getCompanyDetailsFromViesEndpoint;
  private readonly COMPANY_TAX_ID_KEY: string = 'companyTaxId';

  constructor(private httpClient: HttpClient) {}

  public getCompanyDetailsFromVies(companyTaxId: string): Observable<CompanyDetailsViesResponse> {
    const queryParams = this.convertToQueryParams(companyTaxId);
    return this.httpClient.get<CompanyDetailsViesResponse>(`${this.apiUrl}${this.getCompanyDetailsFromViesEndpoint}`, {
      params: queryParams
    });
  }

  private convertToQueryParams(companyTaxId: string) {
    let params = new HttpParams();
    params = params.append(this.COMPANY_TAX_ID_KEY, companyTaxId);
    return params;
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RegistrationService } from '../services/registration.service';
import { ConfirmationComponent } from '../views/confirmation/confirmation.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationGuard implements CanActivate, CanDeactivate<ConfirmationComponent> {
  constructor(private registrationService: RegistrationService) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.registrationService.accountCreated;
  }

  canDeactivate(
    component: ConfirmationComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (nextState) {
      return !this.isPreviousStep(nextState);
    }
    return true;
  }

  private isPreviousStep(nextState: RouterStateSnapshot): boolean {
    switch (nextState.root.firstChild?.data.step) {
      case 1:
      case 2:
      case 3:
        return true;
      default:
        return false;
    }
  }
}

<div>
  {{ 'userData.tooltip.info' | translate }}

  <div
    class="icon-check-container"
    [class.correct]="isFormControlCorrect('minLength')"
  >
    <i class="pi pi-check"></i>
    {{ 'userData.tooltip.minLengthRequired' | translate }}
  </div>

  <div
    class="icon-check-container"
    [class.correct]="isFormControlCorrect('one_or_more_capital')"
  >
    <i class="pi pi-check"></i>
    {{ 'userData.tooltip.capitalLetterRequired' | translate }}
  </div>

  <div
    class="icon-check-container"
    [class.correct]="isFormControlCorrect('one_or_more_lowercase')"
  >
    <i class="pi pi-check"></i>
    {{ 'userData.tooltip.lowercaseLetterRequired' | translate }}
  </div>

  <div
    class="icon-check-container"
    [class.correct]="isFormControlCorrect('one_or_more_number')"
  >
    <i class="pi pi-check"></i>
    {{ 'userData.tooltip.numberRequired' | translate }}
  </div>

  <div
    class="icon-check-container"
    [class.correct]="isFormControlCorrect('one_or_more_special_characters')"
  >
    <i class="pi pi-check"></i>
    {{ 'userData.tooltip.specialLetterRequired' | translate }}
  </div>
</div>

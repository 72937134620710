<div class="mt-5">
  <div
    *ngIf="listings && listings.length > 0"
    class="grid"
  >
    <p class="description-bold col p-0">{{ 'common.search.resultCount' | translate }} ({{ totalCount }})</p>
    <div class="col-fixed p-0">
      <app-sort-dropdown
        [sortOptions]="sortOrders"
        (sortOrderChange)="sortChanged($event)"
        [disableLegend]="true"
      ></app-sort-dropdown>
    </div>
  </div>

  <div class="flex flex-column p-0 gap-2">
    <ng-template
      ngFor
      let-listing
      [ngForOf]="listings"
    >
      <app-listings-item [listing]="listing"></app-listings-item>
    </ng-template>
  </div>
  <div *ngIf="listings && listings.length === totalCount">
    <p class="description-bold">{{ 'common.search.allResultsDisplayed' | translate }} ({{ totalCount }})</p>
  </div>
  <div #intersectionObserver>
    <div
      class="flex align-items-center justify-content-center py-2"
      *ngIf="isLoadingMoreItems"
    >
      <adamp-loader></adamp-loader>
    </div>
    <div
      *ngIf="loadMoreItemsError && !isLoadingMoreItems"
      class="flex align-items-center justify-content-center py-3"
    >
      <p-button
        class="my-0 medium-yellow"
        label="Load more result"
        (click)="loadMoreResults.emit(true)"
      ></p-button>
    </div>
  </div>
  <div
    *ngIf="!listings || listings.length === 0"
    class="description-bold"
  >
    {{ 'common.search.noResults' | translate }}
  </div>
</div>

<div
  class="flex mb-2"
  [class.status-not-published]="view === 'favourites' && listing.status !== 'PUBLISHED'"
>
  <p-card
    class="item col-12 p-0 item-cardrounded overflow-hidden pointer"
    (click)="goToDetails($event)"
  >
    <div class="flex">
      <div class="hidden md:block p-3">
        <ng-container [ngTemplateOutlet]="toggleFavouriteTpl"></ng-container>
      </div>
      <div class="flex-1 flex flex-column p-0">
        <div class="flex flex-column">
          <div
            name="header"
            class="flex flex-wrap justify-content-between px-2 py-3 gap-1"
          >
            <div class="hidden md:flex flex-nowrap gap-5">
              <ng-container [ngTemplateOutlet]="additionalInfoTpl"></ng-container>
            </div>

            <div class="flex align-items-center gap-2">
              <div
                *ngIf="authService.userHasRole('CARRIER')"
                class="flex md:hidden align-items-center"
              >
                <ng-container [ngTemplateOutlet]="toggleFavouriteTpl"></ng-container>
              </div>

              <span class="item-header">ID {{ listing.presentationId }}</span>
              <span
                class="px-2 item-header"
                style="opacity: 0.4"
                >|</span
              >
              <span class="px-2 item-header">{{ listing.publishedOn | date: 'dd.MM.yyyy' }}</span>
              <span class="px-2 item-header">{{ listing.publishedOn | date: 'HH:mm' }}</span>
            </div>
          </div>

          <div class="col-12 flex p-0">
            <div class="flex flex-column w-full ml-2 md:ml-0">
              <div
                class="route"
                *ngFor="let item of listing.items; let i = index; let last = last"
              >
                <div class="flex w-full justify-content-between py-1">
                  <div
                    class="flex flex-column md:flex-row white-space-nowrap gap-2 align-items-start md:align-items-center"
                  >
                    <div>
                      <img
                        src="assets/images/flag_placeholder.png"
                        [class]="'mr-2 flag flag-' + (item.loadingCountry | countryCode)"
                      />
                      <span class="country-name">{{ item.loadingCountry }}, {{ item.loadingCity }}</span>
                      <span
                        *ngIf="listing.items.length > 1"
                        class="point-label mx-2"
                        >{{ item.loadingLabel }}</span
                      >
                    </div>

                    <span class="pi pi-chevron-right hidden md:block"></span>

                    <div class="block md:hidden">
                      <span class="pi pi-arrow-down mx-1"></span>
                      <ng-container
                        *ngIf="getDistance()"
                        [ngTemplateOutlet]="distanceTpl"
                      ></ng-container>
                    </div>

                    <div>
                      <img
                        src="assets/images/flag_placeholder.png"
                        [class]="'mr-2 flag flag-' + (item.unloadingCountry | countryCode)"
                      />
                      <span class="country-name">{{ item.unloadingCountry }}, {{ item.unloadingCity }}</span>
                      <span
                        *ngIf="listing.items.length > 1"
                        class="point-label to mx-1"
                        >{{ item.unloadingLabel }}</span
                      >
                    </div>
                  </div>

                  <div class="mx-3 flex gap-1 align-items-center">
                    <span class="font-bold body-large primary-dark-purple number-of-cars pt-1">
                      {{ item.numberOfCars }}
                    </span>
                    <img
                      src="assets/images/car_icon.png"
                      class="icon-car"
                    />
                  </div>
                </div>

                <div
                  class="py-2"
                  *ngIf="!last"
                >
                  <p-divider class="a-no-margin-divider col-12 block md:hidden"></p-divider>
                </div>
              </div>
            </div>

            <div class="hidden xl:flex mx-3 mb-1 align-self-end">
              <ng-container [ngTemplateOutlet]="priceTpl"></ng-container>
            </div>
          </div>
        </div>

        <div class="col flex align-items-end p-0 pr-1 mr-0">
          <p-divider class="a-no-margin-divider flex-grow-1"></p-divider>
        </div>

        <div class="flex flex-column md:flex-row justify-content-between my-3">
          <div class="hidden md:flex flex-column xl:flex-row gap-1 xl:gap-8">
            <ng-container [ngTemplateOutlet]="orderDataTpl"></ng-container>
          </div>

          <div
            class="flex flex-row md:flex-column align-items-center md:align-items-end justify-content-between gap-2 mx-3"
          >
            <div class="flex xl:hidden">
              <ng-container [ngTemplateOutlet]="priceTpl"></ng-container>
            </div>

            <ng-container
              *ngIf="
                authService.userHasRole('CARRIER') && (view === 'favourites' ? listing.status === 'PUBLISHED' : true)
              "
            >
              <a
                *ngIf="listing.offerAlreadyPlacedByRequesterCompany"
                class="small-dark"
                [routerLink]="['/carrier-offer/' + listing.id]"
                [state]="{ scrollTo: 'offers' }"
                [queryParams]="{ scrollTo: 'offers' }"
                >{{ 'listings.search.item.goToMyOffer' | translate }}</a
              >

              <a
                *ngIf="!listing.offerAlreadyPlacedByRequesterCompany"
                class="small-yellow"
                [routerLink]="['/carrier-offer/' + listing.id]"
                [state]="{ scrollTo: 'make-offer' }"
                [queryParams]="{ scrollTo: 'make-offer' }"
                >{{ 'listings.search.item.makeAnOffer' | translate }}</a
              >
            </ng-container>
          </div>

          <div
            class="col-12 md:hidden"
            [class]="{ block: isCollapsed, hidden: !isCollapsed }"
          >
            <p-divider class="a-no-margin-divider"></p-divider>
          </div>

          <div
            class="col-12 md:hidden flex-column gap-3"
            [class]="{ flex: isCollapsed, hidden: !isCollapsed }"
          >
            <ng-container [ngTemplateOutlet]="additionalInfoTpl"></ng-container>
            <ng-container [ngTemplateOutlet]="orderDataTpl"></ng-container>
          </div>
        </div>

        <div
          class="md:flex lg:hidden flex-row md:mb-2"
          [class]="{
            flex: isCollapsed,
            hidden: !isCollapsed,
            'flex-column': rwdService.currentBreakpointValue < RWD_BREAKPOINT.MD
          }"
        >
          <div class="map-section p-0">
            <ng-container [ngTemplateOutlet]="mapTpl"></ng-container>
          </div>

          <ng-container [ngTemplateOutlet]="relatedRoutesTpl"></ng-container>
        </div>
      </div>

      <div class="p-0 hidden lg:flex flex-column">
        <div class="map-section">
          <ng-container [ngTemplateOutlet]="mapTpl"></ng-container>
        </div>

        <ng-container [ngTemplateOutlet]="relatedRoutesTpl"></ng-container>
      </div>
    </div>

    <div
      class="flex md:hidden mono-ghost-background p-3 justify-content-center relative"
      [class.secondary-lilac-background]="isCollapsed"
      (click)="toggleCollapse($event)"
      style="z-index: 2"
    >
      <span
        class="pi"
        [class.pi-chevron-down]="!isCollapsed"
        [class.pi-chevron-up]="isCollapsed"
      ></span>
    </div>
  </p-card>
</div>

<ng-template #priceTpl>
  <div class="flex gap-2 align-items-baseline flex-column md:flex-row">
    <H4
      class="currency-price-prilo primary-dark-purple my-0"
      *ngIf="listing.expectedPrice"
    >
      {{ listing.expectedPrice | currencyPrilo }}
    </H4>
    <small
      class="negotiable white-space-nowrap"
      *ngIf="listing.negotiable"
    >
      {{ 'listings.search.item.negotiable' | translate }}
    </small>
  </div>
</ng-template>

<ng-template #mapTpl>
  <img
    [src]="imageUrl"
    *ngIf="imageUrl"
  />
  <div
    class="map-placeholder"
    *ngIf="!imageUrl"
  >
    <img src="/assets/images/map_placeholder_blank.svg" />
    <div [innerHTML]="'listings.search.item.mapIsBeingGenerated' | translate"></div>
  </div>
</ng-template>

<ng-template #orderDataTpl>
  <div class="flex xl:flex-column">
    <div class="item-header mr-2 p-0 md:pb-2">{{ 'listings.search.item.expiryDate' | translate }}</div>
    <span class="nowrap font-bold description-bold"
      >{{ listing.expiryDate | date: 'dd.MM.yyyy' }} {{ listing.expiryDate | date: 'HH:mm' }}</span
    >
  </div>

  <div class="flex xl:flex-column">
    <div class="item-header mr-2 p-0 md:pb-2">{{ 'listings.search.item.shipper' | translate }}</div>
    <div class="description-bold">{{ listing.shipperName }}</div>
  </div>

  <div
    class="flex xl:flex-column"
    *ngIf="listing.activeOffersCount"
  >
    <div class="item-header p-0 md:pb-2">{{ 'listings.search.item.offers' | translate }}</div>
    <span class="description-bold ml-2">{{ listing.activeOffersCount }}</span>
  </div>
</ng-template>

<ng-template #relatedRoutesTpl>
  <div
    (click)="$event.stopPropagation()"
    style="cursor: initial"
    class="related-routes p-2 pl-3 m-0 md:mr-3 lg:mr-0"
  >
    <div class="description-bold primary-dark-purple">
      {{ 'listings.search.item.relatedRoutes' | translate }}
    </div>
    <span
      *ngIf="listing.suggestions.length === 0"
      class="description-regular"
    >
      {{ 'listings.search.item.noRelatedRoutes' | translate }}
    </span>
    <ng-container *ngIf="listing.suggestions.length !== 0">
      <ng-template
        ngFor
        let-suggestion
        let-i="index"
        [ngForOf]="listing.suggestions"
      >
        <div class="flex flex-column py-1">
          <a
            *ngIf="suggestion.count > 0"
            [routerLink]="['/search']"
            [queryParams]="generateSuggestionQueryParams(suggestion, listing.id)"
          >
            <span class="suggestion-point-label mr-1">{{ suggestion.label }}</span>
            <span class="suggestion-link"
              >{{ suggestion.name
              }}<span class="suggestion-orders white-space-nowrap">
                ({{ 'listings.search.item.ordersCount' | translate: { count: suggestion.count } }})
              </span>
            </span>
          </a>
          <p-button
            *ngIf="suggestion.count === 0"
            styleClass="p-button-link suggestion-button small-padding-link-button"
          >
            <span class="suggestion-point-label mr-1 white-space-nowrap">{{ suggestion.label }}</span>
            <span class="suggestion-disabled-link"
              >{{ suggestion.name }}
              <span class="suggestion-disabled-orders">
                ({{ 'listings.search.item.ordersCount' | translate: { count: suggestion.count } }})
              </span>
            </span>
          </p-button>
        </div>
      </ng-template>
    </ng-container>
  </div>
</ng-template>

<ng-template #additionalInfoTpl>
  <app-date-element
    label="listings.search.item.availabilityDateRange"
    [date]="availabilityFrom()"
    [dateTo]="availabilityTo()"
    [monoFilter]="true"
  ></app-date-element>

  <app-date-element
    label="listings.search.item.deliveryDeadline"
    [date]="deliveryDeadline()"
    [monoFilter]="true"
  ></app-date-element>

  <ng-container
    *ngIf="getDistance()"
    [ngTemplateOutlet]="distanceTpl"
  ></ng-container>
</ng-template>

<ng-template #distanceTpl>
  <span class="description-bold primary-dark-purple white-space-nowrap hidden md:block"> {{ getDistance() }} km </span>
</ng-template>

<ng-template #toggleFavouriteTpl>
  <button
    *ngIf="authService.userHasRole('CARRIER')"
    class="toggle-favourite-button pointer"
    (click)="toggleFavourite($event)"
    [ngbTooltip]="tooltipContentToggleFavourite"
    placement="right"
  >
    <i
      *ngIf="isLoadingFavourite"
      class="pi pi-spin pi-spinner"
      style="font-size: 15px"
    ></i>

    <img
      *ngIf="!isLoadingFavourite"
      [src]="listing.alreadyInFavourites ? 'assets/icons/15px/heart-full.svg' : 'assets/icons/15px/heart-empty.svg'"
      alt="favourite"
    />
    <ng-template #tooltipContentToggleFavourite>
      <div
        class="p-text-left"
        style="max-width: 175px"
      >
        <span>{{
          'favourites.shipmentOrders.tooltip.' + (listing.alreadyInFavourites ? 'remove' : 'add') | translate
        }}</span>
      </div>
    </ng-template>
  </button>
</ng-template>

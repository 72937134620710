import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptorService implements HttpInterceptor {
  private apiUrl = environment.config.apiUrl;

  constructor(private authService: AuthService) {}

  // eslint-disable-next-line
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.getIdToken().pipe(
      switchMap((idToken: string | undefined) => {
        if (idToken && req.url.indexOf(this.apiUrl) >= 0) {
          const cloned = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + idToken)
          });
          return next.handle(cloned);
        }
        return next.handle(req);
      })
    );
  }
}

<div
  *ngIf="loadingOrderHistory"
  class="flex align-items-center"
>
  <i class="pi pi-spinner pi-spin"></i>
</div>

<a
  *ngIf="!loadingOrderHistory && orderHistory?.eventRows?.length"
  [routerLink]="[orderHistoryRoute]"
  target="_blank"
  class="flex align-items-center"
  style="text-decoration: none"
>
  <img src="/assets/icons/15px/eye-open.svg" />
  <span class="g-ml-8 description-bold pt-1">{{ 'orderHistoryButton' | translate | uppercase }}</span>
</a>

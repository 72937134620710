import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DocumentsProcessingService {
  constructor(private http: HttpClient) {}

  public downloadFile(url: string): void {
    this.getDocument(url).subscribe((data) => {
      this.downloadDocument(data);
    });
  }

  public openFile(url: string): void {
    this.getDocument(url).subscribe((data) => {
      this.openDocument(data);
    });
  }

  private getDocument(url: string): Observable<HttpResponse<Blob>> {
    const httpOptions = {
      observe: 'response' as 'body',
      responseType: 'blob' as 'json'
    };

    return this.http.get<HttpResponse<Blob>>(url, httpOptions);
  }

  private downloadDocument(data: HttpResponse<Blob>): void {
    const downloadURL = window.URL.createObjectURL(<Blob>data.body);
    const link = document.createElement('a');
    link.href = downloadURL;
    const contentDispositionHeader = <string>data.headers.get('Content-Disposition');
    link.download = this.getFileName(contentDispositionHeader);
    link.click();
  }

  private openDocument(data: HttpResponse<Blob>): void {
    const downloadURL = window.URL.createObjectURL(<Blob>data.body);
    window.open(downloadURL);
  }

  private getFileName(contentDisposition: string): string {
    let filename = '';
    if (
      contentDisposition &&
      (contentDisposition.indexOf('inline') !== -1 || contentDisposition.indexOf('attachment') !== -1)
    ) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }

    return filename;
  }
}

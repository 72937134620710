import { Component, Input, OnInit } from '@angular/core';
import { RwdService } from 'projects/adamp-frontend-endcust/src/app/services/rwd.service';

@Component({
  selector: 'app-simple-background-header',
  templateUrl: './simple-background-header.component.html',
  styleUrls: ['./simple-background-header.component.sass']
})
export class SimpleBackgroundHeaderComponent implements OnInit {
  @Input() headerTitle: string;

  constructor(private rwdService: RwdService) {}

  ngOnInit(): void {
    this.rwdService.setBackgroundTitleSize();
  }
}

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Listing } from '../model/ListingSearchResult';
import { ActivatedRoute, Router } from '@angular/router';
import { QueryParamsService } from '../service/query-params.service';
import { OnDestroy } from '@angular/core';
import { SortDirection, SortOrderOption } from '../../views/shared/sort-dropdown/sort-dropdown/sort-dropdown.component';

@Component({
  selector: 'app-listings-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.sass']
})
export class ResultsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() listings: Listing[];
  @Input() totalCount: number;
  @Input() isLoadingMoreItems = false;
  @Input() loadMoreItemsError = false;

  @Output() loadMoreResults = new EventEmitter<boolean>();

  sortOrders: SortOrderOption[];
  order: SortOrderOption;

  @ViewChild('intersectionObserver')
  intersection: ElementRef<HTMLElement>;
  observer: IntersectionObserver;
  options = {
    rootMargin: '10px',
    threshold: 1
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private queryParamsService: QueryParamsService
  ) {}

  ngOnInit(): void {
    this.sortOrders = [
      { label: 'listings.search.sortPlacingTime', key: 'PLACING_TIMESTAMP', dir: SortDirection.DESC },
      { label: 'listings.search.sortAvailability', key: 'AVAILABILITY_DATE', dir: SortDirection.ASC },
      { label: 'listings.search.sortNumberOfCarsDesc', key: 'NUMBER_OF_CARS', dir: SortDirection.DESC },
      { label: 'listings.search.sortNumberOfCarsAsc', key: 'NUMBER_OF_CARS', dir: SortDirection.ASC },
      { label: 'listings.search.sortPriceAsc', key: 'PRICE', dir: SortDirection.ASC },
      { label: 'listings.search.sortPriceDesc', key: 'PRICE', dir: SortDirection.DESC }
    ];

    this.order = this.sortOrders[0];
    this.subscribeRoteQueryParams();
  }

  ngAfterViewInit(): void {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (
          entry.isIntersecting &&
          !this.isLoadingMoreItems &&
          this.listings &&
          this.listings.length !== this.totalCount
        ) {
          this.loadMoreResults.emit(true);
        }
      });
    }, this.options);

    this.observer.observe(this.intersection.nativeElement);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  subscribeRoteQueryParams(): void {
    this.route.queryParamMap.subscribe((params) => {
      const sortOrderParams = this.queryParamsService.getSortOrder(params);

      this.order =
        this.sortOrders.find((sortItem) => {
          return sortItem.key === sortOrderParams?.field && sortItem.dir === sortOrderParams?.dir;
        }) || this.sortOrders[0];
    });
  }

  sortChanged(event: SortOrderOption): void {
    this.order = event;
    this.router.navigate(['/search'], {
      queryParams: {
        [QueryParamsService.SORT_ORDER_FIELD]: this.order.key,
        [QueryParamsService.SORT_ORDER_DIR]: this.order.dir
      },
      queryParamsHandling: 'merge'
    });
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, mergeMap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  CompanyDocumentType,
  ConfirmUploadCompanyFiles,
  FileMetadataWithKey,
  FileToUpload
} from './dto/request/confirm-upload-company.files';
import { CarrierDocumentsResponse } from './dto/response/carrier-documents-response';
import { CarrierDocument } from '../views/carrier-documents/carrier-document.model';
import { DocumentsProcessingService } from './documents-processing.service';
import { FileUploadPrepareResponse } from './dto/response/file-upload-prepare-response';
import { S3UploadService } from './s3-upload.service';
import { TempUrl } from './dto/register-response-dto';

@Injectable({
  providedIn: 'root'
})
export class CarrierDocumentsService {
  constructor(
    private http: HttpClient,
    private docProcessing: DocumentsProcessingService,
    private s3UploadService: S3UploadService
  ) {}

  private get endpointUrl(): string {
    return environment.config.apiUrl + environment.config.carrierDocumentsEndpoint;
  }

  private get endpointLicenceUrl(): string {
    return environment.config.apiUrl + environment.config.getLicenceDataEndpoint;
  }

  private get endpointInsuranceUrl(): string {
    return environment.config.apiUrl + environment.config.getInsuranceDataEndpoint;
  }

  getCarrierDocuments(): Observable<CarrierDocument[]> {
    return this.http.get<CarrierDocumentsResponse>(this.endpointUrl).pipe(
      map((files: CarrierDocumentsResponse) =>
        files.map<CarrierDocument>((e) => ({
          id: e.id,
          category: e.companyFileType,
          validUntil: e.validUntil,
          fileName: e.fileName,
          fileUrl: `${this.endpointUrl}/${e.id}`,
          fileDownloadUrl: `${this.endpointUrl}/${e.id}?download=1`
        }))
      )
    );
  }

  uploadCarrierDocuments(
    fileData: { file: File; companyFileType: CompanyDocumentType; validUntil: string },
    companyId: string
  ): Observable<string> {
    let fileToUpload: FileToUpload;
    return this.prepareUrl().pipe(
      take(1),
      mergeMap((response: TempUrl) => {
        fileToUpload = {
          file: fileData.file,
          fileName: fileData.file.name,
          validUntil: fileData.validUntil,
          companyFileType: fileData.companyFileType,
          key: response.key,
          url: response.url
        } as FileToUpload;
        return this.s3UploadService.uploadFile(fileToUpload);
      }),
      mergeMap((response) => {
        return this.confirmUploadCarrierDocumentsRegistration(
          {
            filesMetadata: [
              {
                fileName: fileToUpload.fileName,
                companyFileType: fileToUpload.companyFileType,
                key: fileToUpload.key,
                validUntil: fileToUpload.validUntil
              } as FileMetadataWithKey
            ]
          } as ConfirmUploadCompanyFiles,
          companyId
        );
      })
    );
  }

  prepareUrl(): Observable<FileUploadPrepareResponse> {
    return this.http.post<FileUploadPrepareResponse>(this.endpointUrl + '/prepare', null);
  }

  confirmUploadCarrierDocumentsRegistration(request: ConfirmUploadCompanyFiles, companyId: string): Observable<string> {
    return this.http.put<string>(this.endpointUrl + '/company/' + companyId + '/confirm-upload', request);
  }

  deleteCarrierDocument(documentId: string): Observable<void> {
    return this.http.delete<void>(`${this.endpointUrl}/${documentId}`);
  }

  previewCarrierDocument(documentId: string): void {
    this.docProcessing.openFile(`${this.endpointUrl}/${documentId}`);
  }

  downloadCarrierDocument(documentId: string): void {
    this.docProcessing.downloadFile(`${this.endpointUrl}/${documentId}`);
  }

  openInsuranceCorrected(documentId: string): void {
    this.previewCarrierDocument(documentId);
  }

  openLicenceCorrected(documentId: string): void {
    this.previewCarrierDocument(documentId);
  }

  getInsuranceCorrected(documentId: string): Observable<CarrierDocumentsResponse> {
    return this.http.get<CarrierDocumentsResponse>(`${this.endpointInsuranceUrl}/${documentId}`);
  }

  getLicenceCorrected(documentId: string): Observable<CarrierDocumentsResponse> {
    return this.http.get<CarrierDocumentsResponse>(`${this.endpointLicenceUrl}/${documentId}`);
  }
}

<div class="flex align-items-baseline">
  <img
    *ngIf="!withoutIcon"
    src="assets/icons/10px/calendar.svg"
    class="img"
    [class]="{ 'img--mono': monoFilter }"
  />
  <span
    class="description-regular mr-1"
    [class]="{ 'mono-font': monoFilter }"
    >{{ label | translate }}
  </span>
  <span
    *ngIf="!dateTo; else dateRange"
    class="description-bold primary-dark-purple"
    >{{ date | date: format }}</span
  >
</div>

<ng-template #dateRange>
  <span class="description-bold primary-dark-purple"
    >{{ date | date: format }} - {{ dateTo | date: dateToFormat }}</span
  >
</ng-template>

<div class="checkbox-button {{ class }}" [class.checkbox-button-checked]="checkedValue">
  <input
    #inputEl
    id="{{ inputId }}"
    name="{{ name }}"
    value="{{ value }}"
    (change)="onChange(inputEl.checked)"
    [(ngModel)]="checkedValue"
    class="checkbox-button"
    type="checkbox"
  />
  <div
    class="checkbox-button-container"
    [class.checkbox-button-container-active]="checkedValue"
    [class.checkbox-button-container-hover]="mouseOvered"
    (mouseover)="mouseOvered = true"
    (mouseout)="mouseOvered = false"
    (click)="toogle()"
  >
    <img
      *ngIf="icon"
      class="checkbox-button-container-icon"
      [class.checkbox-button-container-active-icon]="checkedValue"
      src="{{ icon }}"
    />

    <label>{{ label }}</label>
  </div>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationTileComponent } from './notification-tile.component';
import { ButtonModule } from 'primeng/button';
import { NotificationTypePipe } from './pipes/notification-type.pipe';
import { NotificationTimePipe } from './pipes/notification-time.pipe';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [NotificationTileComponent, NotificationTypePipe, NotificationTimePipe],
  imports: [CommonModule, TranslateModule.forChild(), ButtonModule, RouterModule],
  exports: [NotificationTileComponent]
})
export class NotificationTileModule {}

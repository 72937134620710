import { NgModule } from '@angular/core';
import { OrderHistoryButtonComponent } from './order-history-button.component';
import { CommonModule, UpperCasePipe } from '@angular/common';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [OrderHistoryButtonComponent],
  imports: [CommonModule, RouterLink, TranslateModule, UpperCasePipe],
  exports: [OrderHistoryButtonComponent]
})
export class OrderHistoryButtonModule {}

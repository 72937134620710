<div
  [formGroup]="form"
  class="input-loader-wrapper"
>
  <input
    [id]="controlId"
    [formControlName]="controlName"
    [placeholder]="placeholder"
    type="text"
    (input)="handleInputChanged()"
    pInputText
  />
  <adamp-loader
    *ngIf="isLoading"
    [customStyleParent]="{ width: '20px', height: '20px' }"
    [customStyleChild]="{ width: '20px', height: '20px', margin: '3px', 'border-width': '3px' }"
  ></adamp-loader>
</div>

<form [formGroup]="criteriaForm">
  <app-simple-background-header headerTitle="listings.search.title"></app-simple-background-header>

  <div
    *ngIf="auth.userHasRole('SHIPPER')"
    class="flex justify-content-end align-items-center mb-4 relative"
  >
    <a
      class="btn primary-dark-purple medium-yellow"
      styleClass="px-4"
      [routerLink]="['/shipment-order']"
    >
      <img src="/assets/icons/10px/plus.svg" />
      {{ 'listings.search.newOrder' | translate }}
    </a>
  </div>

  <div class="grid">
    <div class="col criteria-border">
      <div class="flex align-items-center justify-content-between mb-3 flex-column md:flex-row">
        <p class="m-0 body-large-bold align-self-start">
          {{ 'listings.search.subtitle' | translate }}
        </p>
        <p-button
          *ngIf="auth.userHasRole('CARRIER')"
          type="button"
          label="{{ 'listings.search.favouriteRoute.addThisRouteToFav' | translate | uppercase }}"
          class="regular-text small white-space-nowrap align-self-end"
          (onClick)="addRouteToFav()"
          [disabled]="routeAddedToFav || !allowAddRouteToFav"
        >
          <i
            *ngIf="isLoadingRouteToFav"
            class="pi pi-spin pi-spinner less-filters-icon mr-2"
            style="font-size: 16px"
          ></i>

          <img
            *ngIf="!isLoadingRouteToFav"
            [src]="routeAddedToFav ? 'assets/icons/15px/heart-full.svg' : 'assets/icons/15px/heart-empty.svg'"
            class="less-filters-icon mr-2"
          />
        </p-button>
      </div>

      <!--kryteria-->
      <div class="flex col-12 flex-column md:flex-row gap-3">
        <div class="col-12 md:col-6 p-0">
          <app-formfield-wrapper
            formGroupName="loadingPlace"
            [oneline]="false"
          >
            <div class="grid">
              <div class="col p-0">
                <app-formfield-wrapper
                  label="{{ 'listings.search.loadingPlace' | translate }}"
                  labelFor="loadingPlaceCity"
                  image="icons/10px/pin.svg"
                  [oneline]="false"
                  [composed]="true"
                >
                  <div class="flex p-0 align-items-baseline">
                    <img
                      *ngIf="searchService.loadingCountryCode"
                      src="assets/images/flag_placeholder.png"
                      [class]="'mr-2 flag flag-' + (searchService.loadingCountryCode | countryCode)"
                      style="width: 20px"
                    />

                    <p-autoComplete
                      placeholder="{{ 'listings.search.searchLoadingAddress' | translate }}"
                      [suggestions]="searchService.fullSearchResult"
                      (completeMethod)="searchService.searchLoadingPlace($event)"
                      (onSelect)="searchService.getLoadingPositionAndSetValues($event)"
                      (onClear)="searchService.clearLoadingCountry()"
                      [forceSelection]="true"
                      field="displayValue"
                      class="col pt-0 pb-0"
                      formControlName="city"
                      inputId="loadingPlaceCity"
                    >
                      <ng-template
                        let-v
                        pTemplate="item"
                      >
                        <img
                          src="assets/images/flag_placeholder.png"
                          [class]="'mr-2  flag flag-' + (v.countryCode | countryCode)"
                          style="width: 20px"
                        />
                        {{ v.displayValue }}
                      </ng-template>
                    </p-autoComplete>
                  </div>
                </app-formfield-wrapper>
              </div>
              <p-divider
                class="col-fixed a-no-margin-divider a-no-padding-divider mr-2 pt-0"
                layout="vertical"
              ></p-divider>
              <div class="col-fixed p-0">
                <app-formfield-wrapper
                  label="{{ 'listings.search.distance' | translate }}"
                  labelFor="loadingPlaceRange"
                  [oneline]="false"
                  [composed]="true"
                >
                  <p-dropdown
                    inputId="loadingPlaceRange"
                    [options]="ranges"
                    optionLabel="label"
                    optionValue="distance"
                    formControlName="range"
                    [disabled]="checkDisabled('criteriaForm.controls.loadingPlace.controls.range')"
                  ></p-dropdown>
                </app-formfield-wrapper>
              </div>
            </div>
          </app-formfield-wrapper>
        </div>
        <div class="col-12 md:col-6 p-0">
          <app-formfield-wrapper
            formGroupName="unloadingPlace"
            [oneline]="false"
          >
            <div class="grid">
              <div class="col p-0">
                <app-formfield-wrapper
                  label="{{ 'listings.search.unloadingPlace' | translate }}"
                  labelFor="unloadingPlaceCity"
                  image="icons/10px/pin.svg"
                  [oneline]="false"
                  [composed]="true"
                >
                  <div class="flex p-0 align-items-baseline">
                    <img
                      *ngIf="searchService.unloadingCountryCode"
                      src="assets/images/flag_placeholder.png"
                      [class]="'mx-2 flag flag-' + (searchService.unloadingCountryCode | countryCode)"
                      style="width: 20px"
                    />

                    <p-autoComplete
                      placeholder="{{ 'listings.search.searchUnloadingAddress' | translate }}"
                      [suggestions]="searchService.fullSearchResult"
                      (completeMethod)="searchService.searchUnloadingPlace($event)"
                      (onSelect)="searchService.getUnloadingPositionAndSetValues($event)"
                      (onClear)="searchService.clearUnloadingCountry()"
                      [forceSelection]="true"
                      field="displayValue"
                      class="col pt-0 pb-0"
                      formControlName="city"
                      inputId="unloadingPlaceCity"
                    >
                      <ng-template
                        let-v
                        pTemplate="item"
                      >
                        <img
                          src="assets/images/flag_placeholder.png"
                          [class]="'mr-2 flag flag-' + (v.countryCode | countryCode)"
                          style="width: 20px"
                        />
                        {{ v.displayValue }}
                      </ng-template>
                    </p-autoComplete>
                  </div>
                </app-formfield-wrapper>
              </div>
              <p-divider
                class="col-fixed a-no-margin-divider a-no-padding-divider mr-2 pt-0"
                layout="vertical"
              ></p-divider>
              <div class="col-fixed p-0">
                <app-formfield-wrapper
                  label="{{ 'listings.search.distance' | translate }}"
                  labelFor="unloadingPlaceRange"
                  [oneline]="false"
                  [composed]="true"
                >
                  <p-dropdown
                    inputId="unloadingPlaceRange"
                    [options]="ranges"
                    optionLabel="label"
                    optionValue="distance"
                    formControlName="range"
                    [disabled]="checkDisabled('criteriaForm.controls.unloadingPlace.controls.range')"
                  ></p-dropdown>
                </app-formfield-wrapper>
              </div>
            </div>
          </app-formfield-wrapper>
        </div>
      </div>

      <ng-container *ngIf="moreFilters">
        <div class="flex col-12 flex-column md:flex-row gap-3 pt-0">
          <div class="col-12 md:col-6 p-0">
            <app-formfield-wrapper
              label="{{ 'shipmentOrder.loadingDateRange' | translate }}"
              labelFor="loadingDateRange"
              image="icons/10px/calendar.svg"
              [oneline]="true"
            >
              <p-calendar
                [firstDayOfWeek]="1"
                [showIcon]="false"
                dateFormat="dd.mm.yy"
                selectionMode="range"
                [minDate]="today"
                #calendar
                inputId="loadingDateRange"
                [selectOtherMonths]="true"
                formControlName="loadingDateRange"
                (onSelect)="onDateRangeSelect()"
              ></p-calendar>
            </app-formfield-wrapper>
          </div>

          <div class="col-12 md:col-6 p-0">
            <app-formfield-wrapper
              label="{{ 'shipmentOrder.unloadingDate' | translate }}"
              labelFor="unloadingDate"
              image="icons/10px/calendar.svg"
              [oneline]="true"
            >
              <p-calendar
                [firstDayOfWeek]="1"
                [showIcon]="false"
                dateFormat="dd.mm.yy"
                [selectOtherMonths]="true"
                [minDate]="minimumUnloadingDate()"
                formControlName="unloadingDate"
                inputId="unloadingDate"
              ></p-calendar>
            </app-formfield-wrapper>
          </div>
        </div>

        <div class="flex col-12 flex-column md:flex-row gap-3 pt-0">
          <div class="flex col-12 md:col-6 flex p-0">
            <div class="col-6 p-0 pr-1">
              <app-formfield-wrapper
                label="{{ 'listings.search.minNumberOfCars' | translate }}"
                labelFor="minNoOfCars"
                image="icons/10px/car.svg"
                [oneline]="true"
              >
                <p-inputNumber
                  [showButtons]="false"
                  inputId="minNoOfCars"
                  formControlName="minNumberOfCars"
                  [size]="4"
                  [min]="1"
                  [max]="1000"
                  (onBlur)="recalculateUsedCriteria()"
                ></p-inputNumber>
              </app-formfield-wrapper>
            </div>

            <div class="col-6 p-0 pl-1">
              <app-formfield-wrapper
                label="{{ 'listings.search.maxNumberOfCars' | translate }}"
                labelFor="maxNoOfCars"
                image="icons/10px/car.svg"
                [oneline]="true"
              >
                <p-inputNumber
                  [showButtons]="false"
                  inputId="maxNoOfCars"
                  formControlName="maxNumberOfCars"
                  [size]="4"
                  [min]="1"
                  [max]="1000"
                  (onInput)="recalculateUsedCriteria()"
                ></p-inputNumber>
              </app-formfield-wrapper>
            </div>
          </div>

          <div class="col-12 md:col-6 p-0">
            <app-formfield-wrapper
              label="{{ 'listings.search.shipper' | translate }}"
              labelFor="shipper"
              image="icons/10px/user.svg"
              [oneline]="true"
            >
              <p-autoComplete
                placeholder="{{ 'listings.search.shipperPlaceHolder' | translate }}"
                [suggestions]="shippers"
                (completeMethod)="getCompaniesShipperHelper($event)"
                [minLength]="3"
                field="shipperCompanyName"
                class="col pt-0 pb-0 placeholder-bold"
                formControlName="shipper"
                inputId="shipper"
              >
              </p-autoComplete>
            </app-formfield-wrapper>
          </div>
        </div>

        <div class="grid flex-column md:flex-row">
          <app-formfield-wrapper
            label="{{ 'listings.search.negotiable' | translate }}"
            labelFor="negotiable"
            [oneline]="true"
            [transparent]="true"
          >
            <p-inputSwitch
              class="a-inputswitch"
              formControlName="negotiable"
              inputId="negotiable"
              (onChange)="recalculateUsedCriteria()"
            ></p-inputSwitch>
          </app-formfield-wrapper>

          <app-formfield-wrapper
            label="{{ 'listings.search.fullTruckLoad' | translate }}"
            labelFor="fullTruckLoad"
            [oneline]="true"
            [transparent]="true"
          >
            <p-inputSwitch
              class="a-inputswitch"
              formControlName="fullTruckLoad"
              inputId="fullTruckLoad"
              (onChange)="recalculateUsedCriteria()"
            ></p-inputSwitch>
          </app-formfield-wrapper>

          <app-formfield-wrapper
            *ngIf="auth.userHasRole('SHIPPER') || auth.companyHasRole('SHIPPER')"
            label="{{ 'listings.search.hideYourCompanysOrders' | translate }}"
            labelFor="hideYourCompanysOrders"
            [oneline]="true"
            [transparent]="true"
          >
            <p-inputSwitch
              class="a-inputswitch"
              formControlName="hideYourCompanysOrders"
              inputId="hideYourCompanysOrders"
              (onChange)="recalculateUsedCriteria()"
            ></p-inputSwitch>
          </app-formfield-wrapper>

          <app-formfield-wrapper
            *ngIf="auth.userHasRole('CARRIER')"
            label="{{ 'listings.search.showOnlyMyFavouriteOrders' | translate }}"
            labelFor="showOnlyMyFavouriteOrders"
            [oneline]="true"
            [transparent]="true"
          >
            <p-inputSwitch
              class="a-inputswitch"
              formControlName="showOnlyMyFavouriteOrders"
              inputId="showOnlyMyFavouriteOrders"
              (onChange)="recalculateUsedCriteria()"
            ></p-inputSwitch>
          </app-formfield-wrapper>
        </div>
      </ng-container>

      <div
        class="flex col-12 align-items-center justify-content-between md:justify-content-end flex-row-reverse md:flex-row sm:p-2 lg:p-0 gap-5 flex-wrap"
      >
        <p-button
          type="button"
          label="{{ 'common.clearFilters' | translate | uppercase }}"
          class="regular-text small white-space-nowrap"
          (click)="clearAdditionalFilters()"
        >
          <img
            src="/assets/icons/15px/trash.svg"
            class="less-filters-icon mr-2"
          />
        </p-button>

        <p-button
          *ngIf="!moreFilters && !usedCriteria"
          type="button"
          label="{{ 'listings.search.moreFilters' | translate }}"
          class="regular-text small white-space-nowrap"
          (click)="showMoreFilters()"
        >
          <img
            src="/assets/icons/15px/filter.svg"
            class="less-filters-icon mr-2"
          />
        </p-button>

        <p-button
          *ngIf="!moreFilters && usedCriteria"
          type="button"
          label="{{ 'listings.search.moreFiltersCount' | translate: { usedCriteria: usedCriteria } }}"
          class="regular-text small white-space-nowrap"
          (click)="showMoreFilters()"
        >
          <img
            src="/assets/icons/15px/filter.svg"
            class="less-filters-icon mr-2"
          />
        </p-button>

        <p-button
          type="button"
          label="{{ 'listings.search.lessFilters' | translate }}"
          class="regular-text small white-space-nowrap"
          (click)="hideMoreFilters()"
          *ngIf="moreFilters"
        >
          <img
            src="/assets/icons/15px/filter.svg"
            class="less-filters-icon mr-2"
          />
        </p-button>

        <p-button
          class="regular-dark without-padding"
          icon="pi pi-search"
          [class.smaller-padding]="rwdService.currentBreakpointValue <= RWD_BREAKPOINT.LG"
          label="{{ 'listings.search.search' | translate }}"
          (onClick)="onSearchClicked()"
        ></p-button>
      </div>
    </div>
  </div>
</form>

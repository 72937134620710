import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionHeaderComponent } from './section-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationIconModule } from '../notification/notification-icon/notification-icon.module';
import { SimpleHeaderComponent } from './components/simple-header/simple-header.component';
import { HeaderItemHostDirective } from './directives/header-item-host.directive';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MyProfileMenuComponent } from './components/my-profile-menu/my-profile-menu.component';
import { ProfileMenuItemComponent } from './components/my-profile-menu/components/profile-menu-item/profile-menu-item.component';
import { OrderHeaderComponent } from './components/order-header/order-header.component';
import { OfferHeaderComponent } from './components/offer-header/offer-header.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { DropdownButtonModule } from '../../dropdown-button/dropdown-button.module';
import { FormsModule } from '@angular/forms';
import { CountryCodeModule } from '../../../pipes/country-code/country-code.module';
import { OfferItemModule } from '../../offer-item/offer-item.module';
import { TerminationModalModule } from '../../termination-modal/termination-modal.module';
import { SharedModule } from '../../../shared/shared.module';
import { TeleportModule } from '@ngneat/overview';
import { ManageOrderComponent } from './components/manage-order/manage-order.component';
import { RouterModule } from '@angular/router';
import { OrderHistoryButtonModule } from '../order-history-button/order-history-button.module';
import { SimpleBackgroundHeaderComponent } from './components/simple-background-header/simple-background-header/simple-background-header.component';

@NgModule({
  declarations: [
    SectionHeaderComponent,
    SimpleHeaderComponent,
    HeaderItemHostDirective,
    MyProfileMenuComponent,
    ProfileMenuItemComponent,
    OrderHeaderComponent,
    OfferHeaderComponent,
    LanguageSelectorComponent,
    ManageOrderComponent,
    SimpleBackgroundHeaderComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    NotificationIconModule,
    InputSwitchModule,
    ButtonModule,
    DropdownModule,
    DropdownButtonModule,
    FormsModule,
    CountryCodeModule,
    OfferItemModule,
    TerminationModalModule,
    SharedModule,
    TeleportModule,
    RouterModule,
    OrderHistoryButtonModule
  ],
  exports: [
    SectionHeaderComponent,
    SimpleHeaderComponent,
    OrderHeaderComponent,
    ManageOrderComponent,
    OfferHeaderComponent,
    SimpleBackgroundHeaderComponent
  ]
})
export class SectionHeaderModule {}

import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { InvitationCompanyDataResponse } from './dto/response/invitation-company-data-response';
import { InvitationRegistrationRequest } from './dto/request/invitation-registration-request';
import { UserRole } from '../model/domain/user-role.type';

@Injectable({
  providedIn: 'root'
})
export class InvitationRegistrationService {
  private apiUrl = environment.config.apiUrl;
  private getInvitationCompanyDataEndpoint = environment.config.getInvitationCompanyDataEndpoint;
  private registerUserFromInvitationEndpoint = environment.config.registerUserFromInvitationEndpoint;
  validSteps: boolean[] = [false, false];
  invitationId: string;
  companyName: string;
  email: string;
  companyCountryCode: string;
  roles: UserRole[];

  constructor(private http: HttpClient) {}

  public getInvitationCompanyData(invitationId: string): Observable<InvitationCompanyDataResponse> {
    return this.http.get<InvitationCompanyDataResponse>(
      `${this.apiUrl}${this.getInvitationCompanyDataEndpoint.replace(/invitationId/, invitationId)}`
    );
  }

  registerUser(request: InvitationRegistrationRequest): Observable<void> {
    return this.http.post<void>(this.apiUrl + this.registerUserFromInvitationEndpoint, request);
  }
}

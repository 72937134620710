import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ListingSearchResult, SortValues } from '../model/ListingSearchResult';
import { OrderCriteria } from '../model/order-criteria';
import { environment } from '../../../environments/environment';
import { DateParserService } from '../../services/date-parser.service';
import { Sort } from '../../searchCommon/criteria';

@Injectable({
  providedIn: 'root'
})
export class ListingsSearchService {
  private apiUrl = environment.config.apiUrl;
  private listingsEndpoint = environment.config.searchListingsEndpoint;

  constructor(private httpClient: HttpClient, private dateParserService: DateParserService) {}

  // eslint-disable-next-line sonarjs/cognitive-complexity
  search(criteria: OrderCriteria, sort?: Sort, searchAfter?: SortValues[]): Observable<ListingSearchResult> {
    let params = new HttpParams();

    if (criteria.loading?.latitude) params = params.append('crit.loadLat', criteria.loading.latitude);
    if (criteria.loading?.longitude) params = params.append('crit.loadLong', criteria.loading.longitude);
    if (criteria.loading?.range) params = params.append('crit.loadRange', criteria.loading.range);
    if (criteria.loading?.countryCode) params = params.append('crit.loadCountryCode', criteria.loading.countryCode);

    if (criteria.unloading?.latitude) params = params.append('crit.unloadLat', criteria.unloading.latitude);
    if (criteria.unloading?.longitude) params = params.append('crit.unloadLong', criteria.unloading.longitude);
    if (criteria.unloading?.range) params = params.append('crit.unloadRange', criteria.unloading.range);
    if (criteria.unloading?.countryCode)
      params = params.append('crit.unloadCountryCode', criteria.unloading.countryCode);

    if (criteria.loadingDateFrom)
      params = params.append('crit.loadDateFrom', this.dateParserService.parseDate(criteria.loadingDateFrom) || '');
    if (criteria.loadingDateTo)
      params = params.append('crit.loadDateTo', this.dateParserService.parseDate(criteria.loadingDateTo) || '');
    if (criteria.unloadingDateTo)
      params = params.append('crit.unloadDateTo', this.dateParserService.parseDate(criteria.unloadingDateTo) || '');

    if (criteria.minNoOfCars) params = params.append('crit.minNoOfCars', criteria.minNoOfCars);
    if (criteria.maxNoOfCars) params = params.append('crit.maxNoOfCars', criteria.maxNoOfCars);

    if (criteria.shipper) {
      if (criteria.shipper.shipperCompanyId) {
        params = params.append('crit.shipperCompanyId', criteria.shipper.shipperCompanyId);
      } else if (criteria.shipper.shipperCompanyName) {
        params = params.append('crit.shipperCompanyName', criteria.shipper.shipperCompanyName);
      }
    }

    if (criteria.negotiable) params = params.append('crit.negotiable', criteria.negotiable);
    if (criteria.fullTruck) params = params.append('crit.fullTruck', criteria.fullTruck);
    if (criteria.hideYourCompanysOrders)
      params = params.append('crit.hideYourCompanysOrders', criteria.hideYourCompanysOrders);
    if (criteria.showOnlyMyFavouriteOrders)
      params = params.append('crit.showOnlyMyFavouriteOrders', criteria.showOnlyMyFavouriteOrders);

    if (criteria.skipId) params = params.append('crit.skipId', criteria.skipId);

    if (sort?.field) {
      params = params.append('sortBy', sort.field);
    } else {
      params = params.append('sortBy', 'PLACING_TIMESTAMP');
    }

    if (sort?.dir) {
      params = params.append('sortDir', sort.dir);
    } else {
      params = params.append('sortDir', 'DESC');
    }

    if (searchAfter) {
      params = params.append('searchAfter.sortValues', searchAfter.join(','));
    }

    return this.httpClient.get<ListingSearchResult>(`${this.apiUrl}${this.listingsEndpoint}`, { params: params });
  }
}

import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { ShipmentOrderRestService } from '../../services/shipment-order-rest.service';

@Component({
  selector: 'app-termination-modal',
  templateUrl: './termination-modal.component.html',
  styleUrls: ['./termination-modal.component.sass']
})
export class TerminationModalComponent implements OnInit {
  data: TerminationModalData;
  form: FormGroup;
  reasonMinLength = 3;
  reasonMaxLength = 400;
  isBusy = false;

  constructor(
    private ref: DynamicDialogRef,
    private config: DynamicDialogConfig,
    private formBuilder: FormBuilder,
    private shipmentOrderService: ShipmentOrderRestService
  ) {
    this.data = this.config.data;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group(
      {
        reason: [
          '',
          [Validators.required, Validators.maxLength(this.reasonMaxLength), Validators.minLength(this.reasonMinLength)]
        ]
      },
      { updateOn: 'change' }
    );
  }

  hasError(fieldName: string, errorCode: string): boolean {
    // FIXME: this is being called infinitely in a loop!! (how Angular is still alive?)
    const field = this.form.get(fieldName);
    return ((field?.dirty || field?.touched) && field?.hasError(errorCode)) || false;
  }

  cancel(): void {
    this.ref.close();
  }

  private terminateDeal(): Observable<void> {
    return this.shipmentOrderService.terminateDeal(this.data.orderId!, this.form.get('reason')?.value);
  }

  confirm(): void {
    if (this.form.invalid) return;
    this.isBusy = true;
    this.terminateDeal()
      .pipe(
        finalize(() => {
          this.isBusy = false;
        })
      )
      .subscribe(
        () => {
          this.ref.close(true);
        },
        (error: any) => {
          console.error(error);
        }
      );
  }
}

export interface TerminationModalData {
  itemType: 'OFFER' | 'ORDER';
  orderId?: string;
}

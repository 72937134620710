<!-- FROM SELECTOR -->
<div
  *ngIf="!dynamicDialogRef"
  name="confirm-dialog"
>
  <p-dialog
    [(visible)]="display"
    [modal]="true"
    [style]="{ width: '770px' }"
    [draggable]="false"
    [resizable]="false"
    class="mono-ghost-background"
  >
    <ng-template
      *ngIf="headerText"
      pTemplate="header"
    >
      <ng-container [ngTemplateOutlet]="headerTpl"></ng-container>
    </ng-template>

    <ng-container [ngTemplateOutlet]="textTpl"></ng-container>
    <ng-container [ngTemplateOutlet]="buttonsTpl"></ng-container>
  </p-dialog>
</div>

<!-- FROM DIALOG SERVICE -->
<div *ngIf="dynamicDialogRef">
  <ng-container [ngTemplateOutlet]="headerTpl"></ng-container>
  <ng-container [ngTemplateOutlet]="textTpl"></ng-container>
  <ng-container [ngTemplateOutlet]="buttonsTpl"></ng-container>
</div>

<!-- HEADER TPL -->
<ng-template #headerTpl>
  <h4 class="pl-6">{{ headerText }}</h4>
</ng-template>

<!-- TEXT TPL -->
<ng-template #textTpl>
  <div
    class="pl-6 pr-6"
    [innerHTML]="text"
  ></div>
</ng-template>

<!-- BUTTON TPL -->
<ng-template #buttonsTpl>
  <div class="grid justify-content-end mt-4">
    <p-button
      type="button"
      icon="pi pi-times"
      label="{{ cancelButtonText || ('common.cancel' | translate) }}"
      class="regular-text mr-3"
      (click)="close()"
    ></p-button>
    <p-button
      class="thin-yellow"
      (onClick)="confirm()"
      label="{{ confirmButtonText || ('common.confirm' | translate) }}"
    ></p-button>
  </div>
</ng-template>

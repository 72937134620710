export type Country = Readonly<{
  en: string;
  code: string;
  code3: string;
  pl: string | undefined;
  isViesCountry: boolean | undefined;
  viesPlaceholder?: string | undefined;
  phoneCode: number | undefined;
  lt: string | undefined;
}>;

export const COUNTRIES: Country[] = [
  {
    en: 'Afghanistan',
    code: 'AF',
    code3: 'AFG',
    isViesCountry: false,
    phoneCode: 93,
    pl: 'Afganistan',
    lt: "Afganistanas"
  },
  {
    en: 'Albania',
    code: 'AL',
    code3: 'ALB',
    isViesCountry: false,
    phoneCode: 355,
    pl: 'Albania',
    lt: "Albanija"
  },
  {
    en: 'Algeria',
    code: 'DZ',
    code3: 'DZA',
    isViesCountry: false,
    phoneCode: 213,
    pl: 'Algieria',
    lt: "Alžyras"
  },
  {
    en: 'American Samoa',
    code: 'AS',
    code3: 'ASM',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Andorra',
    code: 'AD',
    code3: 'AND',
    isViesCountry: false,
    phoneCode: 376,
    pl: 'Andora',
    lt: "Andora"
  },
  {
    en: 'Angola',
    code: 'AO',
    code3: 'AGO',
    isViesCountry: false,
    phoneCode: 244,
    pl: 'Angola',
    lt: "Angola"
  },
  {
    en: 'Anguilla',
    code: 'AI',
    code3: 'AIA',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Antarctica',
    code: 'AQ',
    code3: 'ATA',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Antigua and Barbuda',
    code: 'AG',
    code3: 'ATG',
    isViesCountry: false,
    phoneCode: 1268,
    pl: 'Antigua i Barbuda',
    lt: "Antigva ir Barbuda"
  },
  {
    en: 'Argentina',
    code: 'AR',
    code3: 'ARG',
    isViesCountry: false,
    phoneCode: 54,
    pl: 'Argentyna',
    lt: "Argentina"
  },
  {
    en: 'Armenia',
    code: 'AM',
    code3: 'ARM',
    isViesCountry: false,
    phoneCode: 374,
    pl: 'Armenia',
    lt: "Armėnija"
  },
  {
    en: 'Aruba',
    code: 'AW',
    code3: 'ABW',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Australia',
    code: 'AU',
    code3: 'AUS',
    isViesCountry: false,
    phoneCode: 61,
    pl: 'Australia',
    lt: "Australija"
  },
  {
    en: 'Austria',
    code: 'AT',
    code3: 'AUT',
    isViesCountry: true,
    viesPlaceholder: 'ATU99999999',
    phoneCode: 43,
    pl: 'Austria',
    lt: "Austrija"
  },
  {
    en: 'Azerbaijan',
    code: 'AZ',
    code3: 'AZE',
    isViesCountry: false,
    phoneCode: 994,
    pl: 'Azerbejd\u017can',
    lt: "Azerbaidžanas"
  },
  {
    en: 'Bahamas',
    code: 'BS',
    code3: 'BHS',
    isViesCountry: false,
    phoneCode: 1242,
    pl: 'Bahamy',
    lt: "Bahamos"
  },
  {
    en: 'Bahrain',
    code: 'BH',
    code3: 'BHR',
    isViesCountry: false,
    phoneCode: 973,
    pl: 'Bahrajn',
    lt: "Bahrain"
  },
  {
    en: 'Bangladesh',
    code: 'BD',
    code3: 'BGD',
    isViesCountry: false,
    phoneCode: 880,
    pl: 'Bangladesz',
    lt: "Bangladešas"
  },
  {
    en: 'Barbados',
    code: 'BB',
    code3: 'BRB',
    isViesCountry: false,
    phoneCode: 1246,
    pl: 'Barbados',
    lt: "Barbadosas"
  },
  {
    en: 'Belarus',
    code: 'BY',
    code3: 'BLR',
    isViesCountry: false,
    phoneCode: 375,
    pl: 'Bia\u0142oru\u015b',
    lt: "Baltarusija"
  },
  {
    en: 'Belgium',
    code: 'BE',
    code3: 'BEL',
    isViesCountry: true,
    viesPlaceholder: 'BE0999999999',
    phoneCode: 32,
    pl: 'Belgia',
    lt: "Belgija"
  },
  {
    en: 'Belize',
    code: 'BZ',
    code3: 'BLZ',
    isViesCountry: false,
    phoneCode: 501,
    pl: 'Belize',
    lt: "Belizas"
  },
  {
    en: 'Benin',
    code: 'BJ',
    code3: 'BEN',
    isViesCountry: false,
    phoneCode: 229,
    pl: 'Benin',
    lt: "Beninas"
  },
  {
    en: 'Bermuda',
    code: 'BM',
    code3: 'BMU',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Bhutan',
    code: 'BT',
    code3: 'BTN',
    isViesCountry: false,
    phoneCode: 975,
    pl: 'Bhutan',
    lt: "Butanas"
  },
  {
    en: 'Bolivia',
    code: 'BO',
    code3: 'BOL',
    isViesCountry: false,
    phoneCode: 591,
    pl: 'Boliwia',
    lt: "Bolivija"
  },
  {
    en: 'Bosnia and Herzegovina',
    code: 'BA',
    code3: 'BIH',
    isViesCountry: false,
    phoneCode: 387,
    pl: 'Bo\u015bnia i Hercegowina',
    lt: "Bosnija ir Hercegovina"
  },
  {
    en: 'Botswana',
    code: 'BW',
    code3: 'BWA',
    isViesCountry: false,
    phoneCode: 267,
    pl: 'Botswana',
    lt: "Botsvana"
  },
  {
    en: 'Bouvet Island',
    code: 'BV',
    code3: 'BVT',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Brazil',
    code: 'BR',
    code3: 'BRA',
    isViesCountry: false,
    phoneCode: 55,
    pl: 'Brazylia',
    lt: "Brazilija"
  },
  {
    en: 'British Indian Ocean Territory',
    code: 'IO',
    code3: 'IOT',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Brunei Darussalam',
    code: 'BN',
    code3: 'BRN',
    isViesCountry: false,
    phoneCode: 673,
    pl: 'Brunei',
    lt: "Brunėjus Darusalamas"
  },
  {
    en: 'Bulgaria',
    code: 'BG',
    code3: 'BGR',
    isViesCountry: true,
    viesPlaceholder: 'BG999999999',
    phoneCode: 359,
    pl: 'Bu\u0142garia',
    lt: "Bulgarija"
  },
  {
    en: 'Burkina Faso',
    code: 'BF',
    code3: 'BFA',
    isViesCountry: false,
    phoneCode: 226,
    pl: 'Burkina Faso',
    lt: "Burkina Fasas"
  },
  {
    en: 'Burundi',
    code: 'BI',
    code3: 'BDI',
    isViesCountry: false,
    phoneCode: 257,
    pl: 'Burundi',
    lt: "Burundis"
  },
  {
    en: 'Cabo Verde',
    code: 'CV',
    code3: 'CPV',
    isViesCountry: false,
    phoneCode: 238,
    pl: 'Wyspy Zielonego Przyl\u0105dka',
    lt: "Cabo Verde"
  },
  {
    en: 'Cambodia',
    code: 'KH',
    code3: 'KHM',
    isViesCountry: false,
    phoneCode: 855,
    pl: 'Kambod\u017ca',
    lt: "Kambodža"
  },
  {
    en: 'Cameroon',
    code: 'CM',
    code3: 'CMR',
    isViesCountry: false,
    phoneCode: 237,
    pl: 'Kamerun',
    lt: "Kamerūnas"
  },
  {
    en: 'Canada',
    code: 'CA',
    code3: 'CAN',
    isViesCountry: false,
    phoneCode: 1,
    pl: 'Kanada',
    lt: "Kanada"
  },
  {
    en: 'Cayman Islands',
    code: 'KY',
    code3: 'CYM',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Central African Republic',
    code: 'CF',
    code3: 'CAF',
    isViesCountry: false,
    phoneCode: 236,
    pl: 'Republika \u015arodkowoafryka\u0144ska',
    lt: "Centrinės Afrikos Respublika"
  },
  {
    en: 'Chad',
    code: 'TD',
    code3: 'TCD',
    isViesCountry: false,
    phoneCode: 235,
    pl: 'Czad',
    lt: "Čadas"
  },
  {
    en: 'Chile',
    code: 'CL',
    code3: 'CHL',
    isViesCountry: false,
    phoneCode: 56,
    pl: 'Chile',
    lt: "Čilė"
  },
  {
    en: 'China',
    code: 'CN',
    code3: 'CHN',
    isViesCountry: false,
    phoneCode: 86,
    pl: 'Chiny',
    lt: "Kinija"
  },
  {
    en: 'Colombia',
    code: 'CO',
    code3: 'COL',
    isViesCountry: false,
    phoneCode: 57,
    pl: 'Kolumbia',
    lt: "Kolumbija"
  },
  {
    en: 'Comoros',
    code: 'KM',
    code3: 'COM',
    isViesCountry: false,
    phoneCode: 269,
    pl: 'Komory',
    lt: "Komorai"
  },
  {
    en: 'Congo',
    code: 'CG',
    code3: 'COG',
    isViesCountry: false,
    phoneCode: 242,
    pl: 'Kongo',
    lt: "Kongas"
  },
  {
    en: 'Congo, The Democratic Republic of the',
    code: 'CD',
    code3: 'COD',
    isViesCountry: false,
    phoneCode: 243,
    pl: 'Demokratyczna Republika Konga',
    lt: "Demokratinė Kongo Respublika"
  },
  {
    en: 'Cook Islands',
    code: 'CK',
    code3: 'COK',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Costa Rica',
    code: 'CR',
    code3: 'CRI',
    isViesCountry: false,
    phoneCode: 506,
    pl: 'Kostaryka',
    lt: "Kosta Rika"
  },
  {
    en: 'Cote dIvoire',
    code: 'CI',
    code3: 'CIV',
    isViesCountry: false,
    phoneCode: 225,
    pl: 'Wybrze\u017ce Ko\u015bci S\u0142oniowej',
    lt: "Dramblio Kaulo Krantas"
  },
  {
    en: 'Croatia',
    code: 'HR',
    code3: 'HRV',
    isViesCountry: true,
    viesPlaceholder: 'HR99999999999',
    phoneCode: 385,
    pl: 'Chorwacja',
    lt: "Kroatija"
  },
  {
    en: 'Cuba',
    code: 'CU',
    code3: 'CUB',
    isViesCountry: false,
    phoneCode: 53,
    pl: 'Kuba',
    lt: "Kuba"
  },
  {
    en: 'Cyprus',
    code: 'CY',
    code3: 'CYP',
    isViesCountry: true,
    viesPlaceholder: 'CY99999999L',
    phoneCode: 357,
    pl: 'Cypr',
    lt: "Kipras"
  },
  {
    en: 'Czech Republic',
    code: 'CZ',
    code3: 'CZE',
    isViesCountry: true,
    viesPlaceholder: 'CZ99999999',
    phoneCode: 420,
    pl: 'Czechy',
    lt: "Čekijos Respublika"
  },
  {
    en: 'Denmark',
    code: 'DK',
    code3: 'DNK',
    isViesCountry: true,
    viesPlaceholder: 'DK99 99 99 99',
    phoneCode: 45,
    pl: 'Dania',
    lt: "Danija"
  },
  {
    en: 'Djibouti',
    code: 'DJ',
    code3: 'DJI',
    isViesCountry: false,
    phoneCode: 253,
    pl: 'D\u017cibuti',
    lt: "Džibutis"
  },
  {
    en: 'Dominica',
    code: 'DM',
    code3: 'DMA',
    isViesCountry: false,
    phoneCode: 1767,
    pl: 'Dominika',
    lt: "Dominika"
  },
  {
    en: 'Dominican The Republic,',
    code: 'DO',
    code3: 'DOM',
    isViesCountry: false,
    phoneCode: 1767,
    pl: 'Dominikana',
    lt: "Dominikos Respublika"
  },
  {
    en: 'Ecuador',
    code: 'EC',
    code3: 'ECU',
    isViesCountry: false,
    phoneCode: 593,
    pl: 'Ekwador',
    lt: "Ekvadoras"
  },
  {
    en: 'Egypt',
    code: 'EG',
    code3: 'EGY',
    isViesCountry: false,
    phoneCode: 20,
    pl: 'Egipt',
    lt: "Egiptas"
  },
  {
    en: 'El Salvador',
    code: 'SV',
    code3: 'SLV',
    isViesCountry: false,
    phoneCode: 503,
    pl: 'Salwador',
    lt: "Salvadoras"
  },
  {
    en: 'Equatorial Guinea',
    code: 'GQ',
    code3: 'GNQ',
    isViesCountry: false,
    phoneCode: 240,
    pl: 'Gwinea R\u00f3wnikowa',
    lt: "Pusiaujo Gvinėja"
  },
  {
    en: 'Eritrea',
    code: 'ER',
    code3: 'ERI',
    isViesCountry: false,
    phoneCode: 291,
    pl: 'Erytrea',
    lt: "Eritrėja"
  },
  {
    en: 'Estonia',
    code: 'EE',
    code3: 'EST',
    isViesCountry: true,
    viesPlaceholder: 'EE999999999',
    phoneCode: 372,
    pl: 'Estonia',
    lt: "Estija"
  },
  {
    en: 'Ethiopia',
    code: 'ET',
    code3: 'ETH',
    isViesCountry: false,
    phoneCode: 251,
    pl: 'Etiopia',
    lt: "Etiopija"
  },
  {
    en: 'Fiji',
    code: 'FJ',
    code3: 'FJI',
    isViesCountry: false,
    phoneCode: 679,
    pl: 'Fid\u017ci',
    lt: "Fidžis"
  },
  {
    en: 'Finland',
    code: 'FI',
    code3: 'FIN',
    isViesCountry: true,
    viesPlaceholder: 'FI99999999',
    phoneCode: 358,
    pl: 'Finlandia',
    lt: "Suomija"
  },
  {
    en: 'France',
    code: 'FR',
    code3: 'FRA',
    isViesCountry: true,
    viesPlaceholder: 'FRXX 999999999',
    phoneCode: 33,
    pl: 'Francja',
    lt: "Prancūzija"
  },
  {
    en: 'French Guiana',
    code: 'GF',
    code3: 'GUF',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'French Southern Territories',
    code: 'TF',
    code3: 'ATF',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Gabon',
    code: 'GA',
    code3: 'GAB',
    isViesCountry: false,
    phoneCode: 241,
    pl: 'Gabon',
    lt: "Gabonas"
  },
  {
    en: 'Gambia',
    code: 'GM',
    code3: 'GMB',
    isViesCountry: false,
    phoneCode: 220,
    pl: 'Gambia',
    lt: "Gambija"
  },
  {
    en: 'Georgia',
    code: 'GE',
    code3: 'GEO',
    isViesCountry: false,
    phoneCode: 995,
    pl: 'Gruzja',
    lt: "Gruzija"
  },
  {
    en: 'Germany',
    code: 'DE',
    code3: 'DEU',
    isViesCountry: true,
    viesPlaceholder: 'DE999999999',
    phoneCode: 49,
    pl: 'Niemcy',
    lt: "Vokietija"
  },
  {
    en: 'Ghana',
    code: 'GH',
    code3: 'GHA',
    isViesCountry: false,
    phoneCode: 233,
    pl: 'Ghana',
    lt: "Gana"
  },
  {
    en: 'Gibraltar',
    code: 'GI',
    code3: 'GIB',
    isViesCountry: false,
    phoneCode: 350,
    pl: 'Gibraltar',
    lt: "Gibraltaras"
  },
  {
    en: 'Greece',
    code: 'GR',
    code3: 'GRC',
    isViesCountry: true,
    viesPlaceholder: 'EL999999999',
    phoneCode: 30,
    pl: 'Grecja',
    lt: "Graikija"
  },
  {
    en: 'Greenland',
    code: 'GL',
    code3: 'GRL',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Grenada',
    code: 'GD',
    code3: 'GRD',
    isViesCountry: false,
    phoneCode: 1473,
    pl: 'Grenada',
    lt: "Grenados"
  },
  {
    en: 'Guadeloupe',
    code: 'GP',
    code3: 'GLP',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Guam',
    code: 'GU',
    code3: 'GUM',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Guatemala',
    code: 'GT',
    code3: 'GTM',
    isViesCountry: false,
    phoneCode: 502,
    pl: 'Gwatemala',
    lt: "Gvatemala"
  },
  {
    en: 'Guernsey',
    code: 'GG',
    code3: 'GGY',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Guinea',
    code: 'GN',
    code3: 'GIN',
    isViesCountry: false,
    phoneCode: 224,
    pl: 'Gwinea',
    lt: "Gvinėja"
  },
  {
    en: 'Guinea-Bissau',
    code: 'GW',
    code3: 'GNB',
    isViesCountry: false,
    phoneCode: 245,
    pl: 'Gwinea Bissau',
    lt: "Bisau Gvinėja"
  },
  {
    en: 'Guyana',
    code: 'GY',
    code3: 'GUY',
    isViesCountry: false,
    phoneCode: 592,
    pl: 'Gujana',
    lt: "Gajana"
  },
  {
    en: 'Haiti',
    code: 'HT',
    code3: 'HTI',
    isViesCountry: false,
    phoneCode: 509,
    pl: 'Haiti',
    lt: "Haitis"
  },
  {
    en: 'Heard Island and Mcdonald Islands',
    code: 'HM',
    code3: 'HMD',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Holy See (Vatican City State)',
    code: 'VA',
    code3: 'VAT',
    isViesCountry: false,
    phoneCode: 379,
    pl: 'Watykan',
    lt: "Vatikanas"
  },
  {
    en: 'Honduras',
    code: 'HN',
    code3: 'HND',
    isViesCountry: false,
    phoneCode: 504,
    pl: 'Honduras',
    lt: "Hondūras"
  },
  {
    en: 'Hong Kong',
    code: 'HK',
    code3: 'HKG',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Hungary',
    code: 'HU',
    code3: 'HUN',
    isViesCountry: true,
    viesPlaceholder: 'HU99999999',
    phoneCode: 36,
    pl: 'W\u0119gry',
    lt: "Vengrija"
  },
  {
    en: 'Iceland',
    code: 'IS',
    code3: 'ISL',
    isViesCountry: false,
    phoneCode: 354,
    pl: 'Islandia',
    lt: "Islandija"
  },
  {
    en: 'India',
    code: 'IN',
    code3: 'IND',
    isViesCountry: false,
    phoneCode: 91,
    pl: 'Indie',
    lt: "Indija"
  },
  {
    en: 'Indonesia',
    code: 'ID',
    code3: 'IDN',
    isViesCountry: false,
    phoneCode: 62,
    pl: 'Indonezja',
    lt: "Indonezija"
  },
  {
    en: 'Iran, Islamic Republic Of',
    code: 'IR',
    code3: 'IRN',
    isViesCountry: false,
    phoneCode: 98,
    pl: 'Iran',
    lt: "Iranas"
  },
  {
    en: 'Iraq',
    code: 'IQ',
    code3: 'IRQ',
    isViesCountry: false,
    phoneCode: 964,
    pl: 'Irak',
    lt: "Irakas"
  },
  {
    en: 'Ireland',
    code: 'IE',
    code3: 'IRL',
    isViesCountry: true,
    viesPlaceholder: 'IE9S99999L',
    phoneCode: 353,
    pl: 'Irlandia',
    lt: "Airija"
  },
  {
    en: 'Isle of Man',
    code: 'IM',
    code3: 'IMN',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Israel',
    code: 'IL',
    code3: 'ISR',
    isViesCountry: false,
    phoneCode: 972,
    pl: 'Izrael',
    lt: "Izraelis"
  },
  {
    en: 'Italy',
    code: 'IT',
    code3: 'ITA',
    isViesCountry: true,
    viesPlaceholder: 'IT99999999999',
    phoneCode: 39,
    pl: 'W\u0142ochy',
    lt: "Italija"
  },
  {
    en: 'Jamaica',
    code: 'JM',
    code3: 'JAM',
    isViesCountry: false,
    phoneCode: 1,
    pl: 'Jamajka',
    lt: "Jamaika"
  },
  {
    en: 'Japan',
    code: 'JP',
    code3: 'JPN',
    isViesCountry: false,
    phoneCode: 81,
    pl: 'Japonia',
    lt: "Japonija"
  },
  {
    en: 'Jersey',
    code: 'JE',
    code3: 'JEY',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Jordan',
    code: 'JO',
    code3: 'JOR',
    isViesCountry: false,
    phoneCode: 962,
    pl: 'Jordania',
    lt: "Jordanija"
  },
  {
    en: 'Kazakhstan',
    code: 'KZ',
    code3: 'KAZ',
    isViesCountry: false,
    phoneCode: 7,
    pl: 'Kazachstan',
    lt: "Kazachstanas"
  },
  {
    en: 'Kenya',
    code: 'KE',
    code3: 'KEN',
    isViesCountry: false,
    phoneCode: 254,
    pl: 'Kenia',
    lt: "Kenija"
  },
  {
    en: 'Kiribati',
    code: 'KI',
    code3: 'KIR',
    isViesCountry: false,
    phoneCode: 686,
    pl: 'Kiribati',
    lt: "Kiribatis"
  },
  {
    en: 'Korea, Democratic People"S Republic of',
    code: 'KP',
    code3: 'PRK',
    isViesCountry: false,
    phoneCode: 850,
    pl: 'Korea P\u00f3\u0142nocna',
    lt: "Šiaurės Korėja"
  },
  {
    en: 'Korea, Republic of',
    code: 'KR',
    code3: 'KOR',
    isViesCountry: false,
    phoneCode: 82,
    pl: 'Korea Po\u0142udniowa',
    lt: "Pietų Korėja"
  },
  {
    en: 'Kosovo',
    code: 'XK',
    code3: 'XXK',
    isViesCountry: false,
    phoneCode: 383,
    pl: 'Kosowo',
    lt: "Kosovas"
  },
  {
    en: 'Kuwait',
    code: 'KW',
    code3: 'KWT',
    isViesCountry: false,
    phoneCode: 965,
    pl: 'Kuwejt',
    lt: "Kuveitas"
  },
  {
    en: 'Kyrgyzstan',
    code: 'KG',
    code3: 'KGZ',
    isViesCountry: false,
    phoneCode: 996,
    pl: 'Kirgistan',
    lt: "Kirgizija"
  },
  {
    en: 'Lao People"S Democratic Republic',
    code: 'LA',
    code3: 'LAO',
    isViesCountry: false,
    phoneCode: 856,
    pl: 'Laos',
    lt: "Laosas"
  },
  {
    en: 'Latvia',
    code: 'LV',
    code3: 'LVA',
    isViesCountry: true,
    viesPlaceholder: 'LV99999999999',
    phoneCode: 371,
    pl: '\u0141otwa',
    lt: "Latvija"
  },
  {
    en: 'Lebanon',
    code: 'LB',
    code3: 'LBN',
    isViesCountry: false,
    phoneCode: 961,
    pl: 'Liban',
    lt: "Libanas"
  },
  {
    en: 'Lesotho',
    code: 'LS',
    code3: 'LSO',
    isViesCountry: false,
    phoneCode: 266,
    pl: 'Lesoto',
    lt: "Lesotas"
  },
  {
    en: 'Liberia',
    code: 'LR',
    code3: 'LBR',
    isViesCountry: false,
    phoneCode: 231,
    pl: 'Liberia',
    lt: "Liberija"
  },
  {
    en: 'Libya',
    code: 'LY',
    code3: 'LBY',
    isViesCountry: false,
    phoneCode: 218,
    pl: 'Libia',
    lt: "Libija"
  },
  {
    en: 'Liechtenstein',
    code: 'LI',
    code3: 'LIE',
    isViesCountry: false,
    phoneCode: 423,
    pl: 'Liechtenstein',
    lt: "Lichtenšteinas"
  },
  {
    en: 'Lithuania',
    code: 'LT',
    code3: 'LTU',
    isViesCountry: true,
    viesPlaceholder: 'LT999999999',
    phoneCode: 370,
    pl: 'Litwa',
    lt: "Lietuva "
  },
  {
    en: 'Luxembourg',
    code: 'LU',
    code3: 'LUX',
    isViesCountry: true,
    viesPlaceholder: 'LU99999999',
    phoneCode: 352,
    pl: 'Luksemburg',
    lt: "Liuksemburgas"
  },
  {
    en: 'Macao',
    code: 'MO',
    code3: 'MAC',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Macedonia, The Former Yugoslav Republic of',
    code: 'MK',
    code3: 'MKD',
    isViesCountry: false,
    phoneCode: 389,
    pl: 'Macedonia P\u00f3\u0142nocna',
    lt: "Šiaurės Makedonija"
  },
  {
    en: 'Madagascar',
    code: 'MG',
    code3: 'MDG',
    isViesCountry: false,
    phoneCode: 261,
    pl: 'Madagaskar',
    lt: "Madagaskaras"
  },
  {
    en: 'Malawi',
    code: 'MW',
    code3: 'MWI',
    isViesCountry: false,
    phoneCode: 265,
    pl: 'Malawi',
    lt: "Malavis"
  },
  {
    en: 'Malaysia',
    code: 'MY',
    code3: 'MYS',
    isViesCountry: false,
    phoneCode: 60,
    pl: 'Malezja',
    lt: "Malaizija"
  },
  {
    en: 'Maldives',
    code: 'MV',
    code3: 'MDV',
    isViesCountry: false,
    phoneCode: 960,
    pl: 'Malediwy',
    lt: "Maldyvai"
  },
  {
    en: 'Mali',
    code: 'ML',
    code3: 'MLI',
    isViesCountry: false,
    phoneCode: 223,
    pl: 'Mali',
    lt: "Malis"
  },
  {
    en: 'Malta',
    code: 'MT',
    code3: 'MLT',
    isViesCountry: true,
    viesPlaceholder: 'MT99999999',
    phoneCode: 356,
    pl: 'Malta',
    lt: "Malta"
  },
  {
    en: 'Marshall Islands',
    code: 'MH',
    code3: 'MHL',
    isViesCountry: false,
    phoneCode: 692,
    pl: 'Wyspy Marshalla',
    lt: "Maršalo Salos"
  },
  {
    en: 'Martinique',
    code: 'MQ',
    code3: 'MTQ',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Mauritania',
    code: 'MR',
    code3: 'MRT',
    isViesCountry: false,
    phoneCode: 222,
    pl: 'Mauretania',
    lt: "Mauritanija"
  },
  {
    en: 'Mauritius',
    code: 'MU',
    code3: 'MUS',
    isViesCountry: false,
    phoneCode: 230,
    pl: 'Mauritius',
    lt: "Mauricijus"
  },
  {
    en: 'Mayotte',
    code: 'YT',
    code3: 'MYT',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Mexico',
    code: 'MX',
    code3: 'MEX',
    isViesCountry: false,
    phoneCode: 52,
    pl: 'Meksyk',
    lt: "Meksika"
  },
  {
    en: 'Micronesia, Federated States of',
    code: 'FM',
    code3: 'FSM',
    isViesCountry: false,
    phoneCode: 691,
    pl: 'Mikronezja',
    lt: "Mikronezija"
  },
  {
    en: 'Moldova, Republic of',
    code: 'MD',
    code3: 'MDA',
    isViesCountry: false,
    phoneCode: 373,
    pl: 'Mo\u0142dawia',
    lt: "Moldavija"
  },
  {
    en: 'Monaco',
    code: 'MC',
    code3: 'MCO',
    isViesCountry: false,
    phoneCode: 377,
    pl: 'Monako',
    lt: "Monakas"
  },
  {
    en: 'Mongolia',
    code: 'MN',
    code3: 'MNG',
    isViesCountry: false,
    phoneCode: 976,
    pl: 'Mongolia',
    lt: "Mongolija"
  },
  {
    en: 'Montenegro',
    code: 'ME',
    code3: 'MNE',
    isViesCountry: false,
    phoneCode: 382,
    pl: 'Czarnog\u00f3ra',
    lt: "Juodkalnija"
  },
  {
    en: 'Montserrat',
    code: 'MS',
    code3: 'MSR',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Morocco',
    code: 'MA',
    code3: 'MAR',
    isViesCountry: false,
    phoneCode: 212,
    pl: 'Maroko',
    lt: "Marokas"
  },
  {
    en: 'Mozambique',
    code: 'MZ',
    code3: 'MOZ',
    isViesCountry: false,
    phoneCode: 258,
    pl: 'Mozambik',
    lt: "Mozambikas"
  },
  {
    en: 'Myanmar',
    code: 'MM',
    code3: 'MMR',
    isViesCountry: false,
    phoneCode: 95,
    pl: 'Birma',
    lt: "Mianmaras"
  },
  {
    en: 'Namibia',
    code: 'NA',
    code3: 'NAM',
    isViesCountry: false,
    phoneCode: 264,
    pl: 'Namibia',
    lt: "Namibija"
  },
  {
    en: 'Nauru',
    code: 'NR',
    code3: 'NRU',
    isViesCountry: false,
    phoneCode: 674,
    pl: 'Nauru',
    lt: "Nauru"
  },
  {
    en: 'Nepal',
    code: 'NP',
    code3: 'NPL',
    isViesCountry: false,
    phoneCode: 977,
    pl: 'Nepal',
    lt: "Nepalas"
  },
  {
    en: 'Netherlands',
    code: 'NL',
    code3: 'NLD',
    isViesCountry: true,
    viesPlaceholder: 'NLSSSSSSSSSSSS',
    phoneCode: 31,
    pl: 'Holandia',
    lt: "Nyderlandai"
  },
  {
    en: 'New Caledonia',
    code: 'NC',
    code3: 'NCL',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'New Zealand',
    code: 'NZ',
    code3: 'NZL',
    isViesCountry: false,
    phoneCode: 64,
    pl: 'Nowa Zelandia',
    lt: "Naujoji Zelandija"
  },
  {
    en: 'Nicaragua',
    code: 'NI',
    code3: 'NIC',
    isViesCountry: false,
    phoneCode: 505,
    pl: 'Nikaragua',
    lt: "Nikaragva"
  },
  {
    en: 'Niger',
    code: 'NE',
    code3: 'NER',
    isViesCountry: false,
    phoneCode: 227,
    pl: 'Niger',
    lt: "Nigeris"
  },
  {
    en: 'Nigeria',
    code: 'NG',
    code3: 'NGA',
    isViesCountry: false,
    phoneCode: 234,
    pl: 'Nigeria',
    lt: "Nigerija"
  },
  {
    en: 'Niue',
    code: 'NU',
    code3: 'NIU',
    isViesCountry: false,
    phoneCode: 683,
    pl: 'Niue',
    lt: "Niujė"
  },
  {
    en: 'Norfolk Island',
    code: 'NF',
    code3: 'NFK',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'North Korea',
    code: 'KP',
    code3: 'PRK',
    isViesCountry: false,
    phoneCode: 850,
    pl: 'Korea P\u00f3\u0142nocna',
    lt: "Šiaurės Korėja"
  },
  {
    en: 'North Macedonia',
    code: 'MK',
    code3: 'MKD',
    isViesCountry: false,
    phoneCode: 389,
    pl: 'Macedonia P\u00f3\u0142nocna',
    lt: "Šiaurės Makedonija"
  },
  {
    en: 'Northern Mariana Islands',
    code: 'MP',
    code3: 'MNP',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Norway',
    code: 'NO',
    code3: 'NOR',
    isViesCountry: false,
    phoneCode: 47,
    pl: 'Norwegia',
    lt: "Norvegija"
  },
  {
    en: 'Oman',
    code: 'OM',
    code3: 'OMN',
    isViesCountry: false,
    phoneCode: 968,
    pl: 'Oman',
    lt: "Omanas"
  },
  {
    en: 'Pakistan',
    code: 'PK',
    code3: 'PAK',
    isViesCountry: false,
    phoneCode: 92,
    pl: 'Pakistan',
    lt: "Pakistanas"
  },
  {
    en: 'Palau',
    code: 'PW',
    code3: 'PLW',
    isViesCountry: false,
    phoneCode: 680,
    pl: 'Palau',
    lt: "Palau"
  },
  {
    en: 'Palestinian Territory, Occupied',
    code: 'PS',
    code3: 'PSE',
    isViesCountry: false,
    phoneCode: 970,
    pl: 'Palestyna',
    lt: "Palestina"
  },
  {
    en: 'Panama',
    code: 'PA',
    code3: 'PAN',
    isViesCountry: false,
    phoneCode: 507,
    pl: 'Panama',
    lt: "Panama"
  },
  {
    en: 'Papua New Guinea',
    code: 'PG',
    code3: 'PNG',
    isViesCountry: false,
    phoneCode: 675,
    pl: 'Papua Nowa Gwinea',
    lt: "Papua Naujoji Gvinėja"
  },
  {
    en: 'Paraguay',
    code: 'PY',
    code3: 'PRY',
    isViesCountry: false,
    phoneCode: 595,
    pl: 'Paragwaj',
    lt: "Paragvajus"
  },
  {
    en: 'Peru',
    code: 'PE',
    code3: 'PER',
    isViesCountry: false,
    phoneCode: 51,
    pl: 'Peru',
    lt: "Peru"
  },
  {
    en: 'Philippines',
    code: 'PH',
    code3: 'PHL',
    isViesCountry: false,
    phoneCode: 63,
    pl: 'Filipiny',
    lt: "Filipinai"
  },
  {
    en: 'Pitcairn',
    code: 'PN',
    code3: 'PCN',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Poland',
    code: 'PL',
    code3: 'POL',
    isViesCountry: true,
    viesPlaceholder: 'PL9999999999',
    phoneCode: 48,
    pl: 'Polska',
    lt: "Lenkija"
  },
  {
    en: 'Portugal',
    code: 'PT',
    code3: 'PRT',
    isViesCountry: true,
    viesPlaceholder: 'PT999999999',
    phoneCode: 351,
    pl: 'Portugalia',
    lt: "Portugalija"
  },
  {
    en: 'Puerto Rico',
    code: 'PR',
    code3: 'PRI',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Qatar',
    code: 'QA',
    code3: 'QAT',
    isViesCountry: false,
    phoneCode: 974,
    pl: 'Katar',
    lt: "Kataras"
  },
  {
    en: 'Rwanda',
    code: 'RW',
    code3: 'RWA',
    isViesCountry: false,
    phoneCode: 250,
    pl: 'Rwanda',
    lt: "Ruanda"
  },
  {
    en: 'Reunion',
    code: 'RE',
    code3: 'REU',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Romania',
    code: 'RO',
    code3: 'ROU',
    isViesCountry: true,
    viesPlaceholder: 'RO999999999',
    phoneCode: 40,
    pl: 'Rumunia',
    lt: "Rumunija"
  },
  {
    en: 'Russian, The Federation',
    code: 'RU',
    code3: 'RUS',
    isViesCountry: false,
    phoneCode: undefined,
    pl: 'Rosja',
    lt: "Rusija"
  },
  {
    en: 'Saint Helena',
    code: 'SH',
    code3: 'SHN',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Saint Kitts and Nevis',
    code: 'KN',
    code3: 'KNA',
    isViesCountry: false,
    phoneCode: 1869,
    pl: 'Saint Kitts i Nevis',
    lt: "Sent Kitsas ir Nevis"
  },
  {
    en: 'Saint Lucia',
    code: 'LC',
    code3: 'LCA',
    isViesCountry: false,
    phoneCode: 1758,
    pl: 'Saint Lucia',
    lt: "Sent Lusija"
  },
  {
    en: 'Saint Pierre and Miquelon',
    code: 'PM',
    code3: 'SPM',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Saint Vincent and the Grenadines',
    code: 'VC',
    code3: 'VCT',
    isViesCountry: false,
    phoneCode: 1784,
    pl: 'Saint Vincent i Grenadyny',
    lt: "Šventasis Vincentas ir Grenadinai"
  },
  {
    en: 'Samoa',
    code: 'WS',
    code3: 'WSM',
    isViesCountry: false,
    phoneCode: 685,
    pl: 'Samoa',
    lt: "Samoa"
  },
  {
    en: 'San Marino',
    code: 'SM',
    code3: 'SMR',
    isViesCountry: false,
    phoneCode: 378,
    pl: 'San Marino',
    lt: "San Marinas"
  },
  {
    en: 'Sao Tome and Principe',
    code: 'ST',
    code3: 'STP',
    isViesCountry: false,
    phoneCode: 239,
    pl: 'Wyspy \u015awi\u0119tego Tomasza i Ksi\u0105\u017c\u0119ca',
    lt: "San Tomės ir Prinsipės salos"
  },
  {
    en: 'Saudi Arabia',
    code: 'SA',
    code3: 'SAU',
    isViesCountry: false,
    phoneCode: 966,
    pl: 'Arabia Saudyjska',
    lt: "Saudo Arabija"
  },
  {
    en: 'Senegal',
    code: 'SN',
    code3: 'SEN',
    isViesCountry: false,
    phoneCode: 221,
    pl: 'Senegal',
    lt: "Senegalas"
  },
  {
    en: 'Serbia',
    code: 'RS',
    code3: 'SRB',
    isViesCountry: false,
    phoneCode: 381,
    pl: 'Serbia',
    lt: "Serbija"
  },
  {
    en: 'Seychelles',
    code: 'SC',
    code3: 'SYC',
    isViesCountry: false,
    phoneCode: 248,
    pl: 'Seszele',
    lt: "Seišeliai"
  },
  {
    en: 'Sierra Leone',
    code: 'SL',
    code3: 'SLE',
    isViesCountry: false,
    phoneCode: 232,
    pl: 'Sierra Leone',
    lt: "Siera Leonė"
  },
  {
    en: 'Singapore',
    code: 'SG',
    code3: 'SGP',
    isViesCountry: false,
    phoneCode: 65,
    pl: 'Singapur',
    lt: "Singapūras"
  },
  {
    en: 'Slovakia',
    code: 'SK',
    code3: 'SVK',
    isViesCountry: true,
    viesPlaceholder: 'SK9999999999',
    phoneCode: 421,
    pl: 'S\u0142owacja',
    lt: "Slovakija"
  },
  {
    en: 'Slovenia',
    code: 'SI',
    code3: 'SVN',
    isViesCountry: true,
    viesPlaceholder: 'SI99999999',
    phoneCode: 386,
    pl: 'S\u0142owenia',
    lt: "Slovėnija"
  },
  {
    en: 'Solomon Islands',
    code: 'SB',
    code3: 'SLB',
    isViesCountry: false,
    phoneCode: 677,
    pl: 'Wyspy Salomona',
    lt: "Saliamono salos"
  },
  {
    en: 'Somalia',
    code: 'SO',
    code3: 'SOM',
    isViesCountry: false,
    phoneCode: 252,
    pl: 'Somalia',
    lt: "Somalis"
  },
  {
    en: 'South Africa',
    code: 'ZA',
    code3: 'ZAF',
    isViesCountry: false,
    phoneCode: 27,
    pl: 'Republika Po\u0142udniowej Afryki',
    lt: "Pietų Afrikos Respublika"
  },
  {
    en: 'South Georgia and the South Sandwich Islands',
    code: 'GS',
    code3: 'SGS',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'South Korea',
    code: 'KR',
    code3: 'KOR',
    isViesCountry: false,
    phoneCode: 82,
    pl: 'Korea Po\u0142udniowa',
    lt: "Pietų Korėja"
  },
  {
    en: 'South Sudan',
    code: 'SS',
    code3: 'SSD',
    isViesCountry: false,
    phoneCode: 211,
    pl: 'Sudan Po\u0142udniowy',
    lt: "Pietų Sudanas"
  },
  {
    en: 'Spain',
    code: 'ES',
    code3: 'ESP',
    isViesCountry: true,
    viesPlaceholder: 'ESX9999999X',
    phoneCode: 34,
    pl: 'Hiszpania',
    lt: "Ispanija"
  },
  {
    en: 'Sri Lanka',
    code: 'LK',
    code3: 'LKA',
    isViesCountry: false,
    phoneCode: 94,
    pl: 'Sri Lanka',
    lt: "Šri Lanka"
  },
  {
    en: 'Sudan',
    code: 'SD',
    code3: 'SDN',
    isViesCountry: false,
    phoneCode: 249,
    pl: 'Sudan',
    lt: "Sudanas"
  },
  {
    en: 'Suriname',
    code: 'SR',
    code3: 'SUR',
    isViesCountry: false,
    phoneCode: 597,
    pl: 'Surinam',
    lt: "Surinamas"
  },
  {
    en: 'Svalbard and Jan Mayen',
    code: 'SJ',
    code3: 'SJM',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Swaziland',
    code: 'SZ',
    code3: 'SWZ',
    isViesCountry: false,
    phoneCode: 268,
    pl: 'Suazi',
    lt: "Eswatini"
  },
  {
    en: 'Sweden',
    code: 'SE',
    code3: 'SWE',
    isViesCountry: true,
    viesPlaceholder: 'SE999999999999',
    phoneCode: 46,
    pl: 'Szwecja',
    lt: "Švedija"
  },
  {
    en: 'Switzerland',
    code: 'CH',
    code3: 'CHE',
    isViesCountry: false,
    phoneCode: 41,
    pl: 'Szwajcaria',
    lt: "Šveicarija"
  },
  {
    en: 'Syrian Arab Republic',
    code: 'SY',
    code3: 'SYR',
    isViesCountry: false,
    phoneCode: 963,
    pl: 'Syria',
    lt: "Sirija"
  },
  {
    en: 'Taiwan, Republic of China',
    code: 'TW',
    code3: 'TWN',
    isViesCountry: false,
    phoneCode: 886,
    pl: 'Tajwan',
    lt: "Taivanas"
  },
  {
    en: 'Tajikistan',
    code: 'TJ',
    code3: 'TJK',
    isViesCountry: false,
    phoneCode: 992,
    pl: 'Tad\u017cykistan',
    lt: "Tadžikistanas"
  },
  {
    en: 'Tanzania, United Republic of',
    code: 'TZ',
    code3: 'TZA',
    isViesCountry: false,
    phoneCode: 255,
    pl: 'Tanzania',
    lt: "Tanzanija"
  },
  {
    en: 'Thailand',
    code: 'TH',
    code3: 'THA',
    isViesCountry: false,
    phoneCode: 66,
    pl: 'Tajlandia',
    lt: "Tailandas"
  },
  {
    en: 'Timor-Leste',
    code: 'TL',
    code3: 'TLS',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Togo',
    code: 'TG',
    code3: 'TGO',
    isViesCountry: false,
    phoneCode: 228,
    pl: 'Togo',
    lt: "Togas"
  },
  {
    en: 'Tonga',
    code: 'TO',
    code3: 'TON',
    isViesCountry: false,
    phoneCode: 676,
    pl: 'Tonga',
    lt: "Tonga"
  },
  {
    en: 'Trinidad and Tobago',
    code: 'TT',
    code3: 'TTO',
    isViesCountry: false,
    phoneCode: 1868,
    pl: 'Trynidad i Tobago',
    lt: "Trinidadas ir Tobagas"
  },
  {
    en: 'Tunisia',
    code: 'TN',
    code3: 'TUN',
    isViesCountry: false,
    phoneCode: 216,
    pl: 'Tunezja',
    lt: "Tunisas"
  },
  {
    en: 'Turkey',
    code: 'TR',
    code3: 'TUR',
    isViesCountry: false,
    phoneCode: 90,
    pl: 'Turcja',
    lt: "Turkija"
  },
  {
    en: 'Turkmenistan',
    code: 'TM',
    code3: 'TKM',
    isViesCountry: false,
    phoneCode: 993,
    pl: 'Turkmenistan',
    lt: "Turkmėnistanas"
  },
  {
    en: 'Tuvalu',
    code: 'TV',
    code3: 'TUV',
    isViesCountry: false,
    phoneCode: 688,
    pl: 'Tuvalu',
    lt: "Tuvalu"
  },
  {
    en: 'Uganda',
    code: 'UG',
    code3: 'UGA',
    isViesCountry: false,
    phoneCode: 256,
    pl: 'Uganda',
    lt: "Uganda"
  },
  {
    en: 'Ukraine',
    code: 'UA',
    code3: 'UKR',
    isViesCountry: false,
    phoneCode: 380,
    pl: 'Ukraina',
    lt: "Ukraina"
  },
  {
    en: 'United Arab Emirates',
    code: 'AE',
    code3: 'ARE',
    isViesCountry: false,
    phoneCode: 971,
    pl: 'Zjednoczone Emiraty Arabskie',
    lt: "Jungtiniai Arabų Emyratai"
  },
  {
    en: 'United Kingdom',
    code: 'GB',
    code3: 'GBR',
    isViesCountry: false,
    phoneCode: 44,
    pl: 'Wielka Brytania',
    lt: "Jungtinė Karalystė"
  },
  {
    en: 'United States',
    code: 'US',
    code3: 'USA',
    isViesCountry: false,
    phoneCode: 1,
    pl: 'Stany Zjednoczone',
    lt: "Jungtinės Amerikos Valstijos"
  },
  {
    en: 'United States Minor Outlying Islands',
    code: 'UM',
    code3: 'UMI',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Uruguay',
    code: 'UY',
    code3: 'URY',
    isViesCountry: false,
    phoneCode: 598,
    pl: 'Urugwaj',
    lt: "Urugvajus"
  },
  {
    en: 'Uzbekistan',
    code: 'UZ',
    code3: 'UZB',
    isViesCountry: false,
    phoneCode: 998,
    pl: 'Uzbekistan',
    lt: "Uzbekistanas"
  },
  {
    en: 'Vanuatu',
    code: 'VU',
    code3: 'VUT',
    isViesCountry: false,
    phoneCode: 678,
    pl: 'Vanuatu',
    lt: "Vanuatu"
  },
  {
    en: 'Venezuela',
    code: 'VE',
    code3: 'VEN',
    isViesCountry: false,
    phoneCode: 58,
    pl: 'Wenezuela',
    lt: "Venesuela"
  },
  {
    en: 'Viet Nam',
    code: 'VN',
    code3: 'VNM',
    isViesCountry: false,
    phoneCode: 84,
    pl: 'Wietnam',
    lt: "Vietnamas"
  },
  {
    en: 'Virgin Islands, British',
    code: 'VG',
    code3: 'VGB',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Virgin Islands, U.S.',
    code: 'VI',
    code3: 'VIR',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Wallis and Futuna',
    code: 'WF',
    code3: 'WLF',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Western Sahara',
    code: 'EH',
    code3: 'ESH',
    isViesCountry: undefined,
    phoneCode: undefined,
    pl: undefined,
    lt: undefined
  },
  {
    en: 'Yemen',
    code: 'YE',
    code3: 'YEM',
    isViesCountry: false,
    phoneCode: 967,
    pl: 'Jemen',
    lt: "Jemenas"
  },
  {
    en: 'Zambia',
    code: 'ZM',
    code3: 'ZMB',
    isViesCountry: false,
    phoneCode: 260,
    pl: 'Zambia',
    lt: "Zambija"
  },
  {
    en: 'Zimbabwe',
    code: 'ZW',
    code3: 'ZWE',
    isViesCountry: false,
    phoneCode: 263,
    pl: 'Zimbabwe',
    lt: "Zimbabvė"
  }
];

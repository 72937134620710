import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SortDropdownComponent } from './sort-dropdown.component';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [SortDropdownComponent],
  imports: [CommonModule, TranslateModule, DropdownModule, ButtonModule, FormsModule],
  exports: [SortDropdownComponent]
})
export class SortDropdownModule {}

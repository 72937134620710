import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NotificationDto, NotificationStatus } from '../../../../services/dto/notification.model';

@Component({
  selector: 'app-notification-tile',
  templateUrl: './notification-tile.component.html',
  styleUrls: ['./notification-tile.component.sass']
})
export class NotificationTileComponent implements OnInit {
  @Input() notification: NotificationDto;
  @Input() fullMode = false;
  @Output() buttonClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() markClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteClicked: EventEmitter<void> = new EventEmitter<void>();
  offerPresentationId: string;
  orderPresentationId: string;
  companyName: string;
  truckPlateNo: string;
  loadingParameters: string;
  unloadingParameters: string;

  notificationStatus = NotificationStatus;

  ngOnInit(): void {
    this.offerPresentationId = this.notification?.data?.offerPresentationId || '';
    this.orderPresentationId = this.notification?.data?.orderPresentationId || '';
    this.companyName = this.notification?.data?.companyName || '';
    this.truckPlateNo = this.notification?.data?.truckPlateNo || '';
    this.loadingParameters = this.notification?.data?.loadingParameters || '';
    this.unloadingParameters = this.notification?.data?.unloadingParameters || '';
  }

  clicked(): void {
    this.buttonClicked.emit();
  }

  setRead(): void {
    if (this.notification.status === NotificationStatus.UNREAD) {
      this.markClicked.emit();
    }
  }
}

<div class="a-page-header">
  <div class="grid">
    <div class="a-page-header-column grid align-items-center justify-content-between col-12 mt-2">
      <div class="grid align-items-center">
        <ng-container teleportOutlet="sectionHeaderTeleport"></ng-container>
        <ng-template appHeaderItemHost></ng-template>
      </div>

      <div class="flex align-items-center gap-1">
        <app-language-selector class="flex align-items-center mr-5 xl:mr-4"></app-language-selector>
        <ng-container *ngIf="auth.loggedIn">
          <app-notification-icon class="mr-5 xl:mr-4"></app-notification-icon>
          <img
            (click)="toggleMenu()"
            src="assets/icons/avatar.svg"
            class="pointer header-icon"
          />

          <app-my-profile-menu
            class="profile-menu"
            *ngIf="showMenu"
            #profileMenu
          >
            <app-profile-menu-item
              icon="/assets/icons/20px/myaccount.svg"
              label="{{ 'sectionHeader.myProfile' | translate }}"
              (clickEmit)="myAccount()"
              link="/my-account"
            >
            </app-profile-menu-item>
            <app-profile-menu-item
              icon="/assets/icons/20px/logout.svg"
              label="{{ 'sectionHeader.logOut' | translate }}"
              (clickEmit)="logout()"
            >
            </app-profile-menu-item>
          </app-my-profile-menu>
        </ng-container>
      </div>
    </div>
  </div>
</div>

import { Injectable, ErrorHandler } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandler implements ErrorHandler {
  private _chunkErrorSubject = new ReplaySubject<any>(1);

  chunkErrorOccured$: Observable<any> = this._chunkErrorSubject.asObservable();

  handleError(error: any): void {
    if (GlobalErrorHandler._isChunkLoadError(error)) {
      this._chunkErrorSubject.next(true);
    }
    console.error(error);
  }

  private static _isChunkLoadError(error: any): boolean {
    const name = error.rejection?.name ?? error.name;
    return name === 'ChunkLoadError';
  }
}

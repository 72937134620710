<ng-container *ngIf="{ value: showModal | async } as showModal">
  <div *ngIf="notifications | async as context" class="notifications">
    <img (click)="iconClicked(context?.allPending)" src="assets/icons/30px/bell.svg" class="pointer header-icon" />
    <div *ngIf="context && context.allPending > 0" class="new-notifications" data-test-id="new-notifications"></div>
    <div *ngIf="showModal.value" class="show-modal-position">
      <app-notification-modal></app-notification-modal>
    </div>
  </div>
  <div
    data-test-id="hide-modal-background"
    class="hide-modal-background"
    *ngIf="showModal.value"
    (click)="hideModal()"
  ></div>
</ng-container>

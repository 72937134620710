<a
  class="menu-item"
  [class.menu-item--only-icon]="onlyIcon"
  [routerLink]="link"
  [routerLinkActive]="link ? 'menu-item--active' : ''"
>
  <div class="menu-item__icon g-mr-20">
    <img [src]="'/assets/icons/' + icon" alt="icon" />
    <div *ngIf="badgeText" class="menu-item__icon__badge">{{ badgeText }}</div>
  </div>

  <div class="menu-item__label" *ngIf="!onlyIcon">{{ label }}</div>
</a>

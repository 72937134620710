import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, fromEvent, merge, Observable, Subscription, timer } from 'rxjs';
import { startWith, switchMapTo, throttleTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InactivityCheckerService {
  private readonly TIMEOUT_MINUTES: number = 5;
  private readonly active: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private timeoutSubscription: Subscription;
  private backSubscription: Subscription;
  public activeState: Observable<boolean> = this.active.asObservable();

  init(): void {
    this.active.next(true);
    this.timeoutSubscription = this.isTimeout.subscribe(() => {
      this.active.next(false);
      this.backSubscription = this.triggeringEvents.subscribe(() => {
        this.active.next(true);
        this.backSubscription.unsubscribe();
      });
    });
  }

  destroy(): void {
    this.active.next(false);
    if (this.timeoutSubscription) {
      this.timeoutSubscription.unsubscribe();
    }
    if (this.backSubscription) {
      this.backSubscription.unsubscribe();
    }
  }

  get isTimeout(): Observable<number> {
    return this.triggeringEvents.pipe(startWith('loaded'), throttleTime(10 * 1000), switchMapTo(this.createTimer));
  }

  private get createTimer(): Observable<number> {
    return timer(this.TIMEOUT_MINUTES * 60 * 1000);
  }

  // eslint-disable-next-line
  private get triggeringEvents(): Observable<any> {
    return merge(fromEvent(document.body, 'click'), fromEvent(document.body, 'keydown'));
  }
}

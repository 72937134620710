import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanDeactivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { RegistrationService } from '../services/registration.service';
import { HttpRequestChecker } from '../interceptors/services/http-request-checker.service';
import { delay } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { FormGroupUtilService } from '../services/form-group-util.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationStepGuard implements CanDeactivate<RegistrationStepI>, CanActivate {
  isHttpRequestPending = false;

  constructor(
    private registrationService: RegistrationService,
    private router: Router,
    private httpRequestChecker: HttpRequestChecker,
    private formGroupUtilService: FormGroupUtilService
  ) {
    this.httpRequestChecker.isHttpRequestPending
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((isHttpRequestPending: boolean) => {
        this.isHttpRequestPending = isHttpRequestPending;
      });
  }

  canDeactivate(
    component: RegistrationStepI,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.nextStepTriggered(currentState, nextState)) {
      this.formGroupUtilService.markAllFieldsAsTouched(component.getForm());
      this.formGroupUtilService.markAllFieldsAsDirtyAndUpdateValueAndValidity(component.getForm());
      return this.areAllPreviousStepsValid(nextState) && !component.isFormGroupPending();
    } else {
      return !(component.isFormGroupPending() || this.isHttpRequestPending);
    }
  }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.registrationService.selectedRoles.length === 0) {
      return this.router.navigate(['role-selection']);
    } else {
      return of(true);
    }
  }

  private nextStepTriggered(currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): boolean {
    return currentState.root.firstChild?.data.step < nextState.root.firstChild?.data.step;
  }

  private areAllPreviousStepsValid(nextState: RouterStateSnapshot): boolean {
    for (let i = 0; i < nextState.root.firstChild?.data.step - 1; i++) {
      if (!this.registrationService.validSteps[i]) {
        return false;
      }
    }
    return true;
  }
}

export interface RegistrationStepI {
  getForm(): FormGroup;

  isFormGroupPending(): boolean;

  setNavigationChangeSubscriber(): void;
}

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-password-tooltip',
  templateUrl: './password-tooltip.component.html',
  styleUrls: ['./password-tooltip.component.sass']
})
export class PasswordTooltipComponent {
  @Input() form: FormGroup;

  isFormControlCorrect(errorKey: string){
    if(!this.form.get('password')?.hasError('required')){
      return !this.form.get('password')?.hasError(errorKey)
    }

    return false
  }
}

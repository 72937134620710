import { Component } from '@angular/core';
import { InvitationRegistrationService } from '../../../services/invitation-registration.service';

@Component({
  selector: 'app-confirmation-invitation',
  templateUrl: './confirmation-invitation.component.html',
  styleUrls: ['./confirmation-invitation.component.sass']
})
export class ConfirmationInvitationComponent {
  constructor(public invitationRegistrationService: InvitationRegistrationService) {}
}

import { Component, ViewChild } from '@angular/core';
import { RegistrationStepI } from '../../../guards/registration-step.guard';
import { UserDataFormComponent } from '../../user-data/user-data-form/user-data-form.component';
import { FormGroup } from '@angular/forms';
import { InvitationRegistrationService } from '../../../services/invitation-registration.service';

@Component({
  selector: 'app-user-data-invitation',
  templateUrl: './user-data-invitation.component.html',
  styleUrls: ['./user-data-invitation.component.sass']
})
export class UserDataInvitationComponent implements RegistrationStepI {
  @ViewChild(UserDataFormComponent) userDataFormComponent: UserDataFormComponent;

  constructor(public invitationRegistrationService: InvitationRegistrationService) {}

  getForm(): FormGroup {
    return this.userDataFormComponent.getForm();
  }

  isFormGroupPending(): boolean {
    return this.userDataFormComponent.isFormGroupPending();
  }

  setNavigationChangeSubscriber(): void {
    this.userDataFormComponent.setNavigationChangeSubscriber();
  }
}

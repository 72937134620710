import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomizableDialogService {
  dialogConfig() {
    return {
      width: '770px',
      height: '476px',
      modal: true,
      styleClass: 'mono-ghost-background',
      style: {
        'background-color': '#F7F7F7'
      },
      contentStyle: {
        overflow: 'visible',
        'background-color': '#F7F7F7',
        'padding-bottom': 0,
        'padding-left': 0,
        'padding-right': 0
      }
    };
  }
}

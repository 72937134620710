import { Directive, AfterViewInit } from '@angular/core';
import { RwdService } from '../../services/rwd.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '.background-title'
})
export class BackgroundTitleDirective implements AfterViewInit {
  constructor(private rwdService: RwdService) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.rwdService.setBackgroundTitleSize();
    });
  }
}

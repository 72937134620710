import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CarsTableComponent } from './cars-table.component';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { FoldableButtonModule } from '../../shared/foldable-button/foldable-button.module';
import { CarDataPipeModule } from '../../../pipes/car-data/car-data-pipe.module';

@NgModule({
  declarations: [CarsTableComponent],
  exports: [CarsTableComponent],
  imports: [CommonModule, TranslateModule, TableModule, FoldableButtonModule, CarDataPipeModule]
})
export class CarsTableModule {}

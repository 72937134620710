import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationIconComponent } from './notification-icon.component';
import { NotificationModalModule } from './notification-modal/notification-modal.module';
import { NotificationIconService } from './notification-icon.service';

@NgModule({
  declarations: [NotificationIconComponent],
  imports: [CommonModule, NotificationModalModule],
  exports: [NotificationIconComponent],
  providers: [NotificationIconService]
})
export class NotificationIconModule {}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayCommissionWrapperComponent } from './pay-commission-wrapper.component';
import { PayCommissionService } from '../../pay-commission/pay-commission.service';
import { PayCommissionModule } from '../../pay-commission/pay-commission.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptorService } from '../../../interceptors/jwt-interceptor.service';

@NgModule({
  declarations: [PayCommissionWrapperComponent],
  imports: [CommonModule, PayCommissionModule, HttpClientModule],
  exports: [PayCommissionWrapperComponent],
  providers: [PayCommissionService, { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true }]
})
export class PayCommissionWrapperModule {}

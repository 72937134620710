export const LANGUAGES: LanguageItem[] = [
  { countryCode: 'GB', languageCode: 'en', name: 'English' },
  { countryCode: 'PL', languageCode: 'pl', name: 'Polski' },
  { countryCode: 'LT', languageCode: 'lt', name: 'Lietuvių' },
];

export type LanguageItem = Readonly<{
  countryCode: string;
  languageCode: string;
  name: string;
}>;

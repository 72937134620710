import {
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef,
  Renderer2
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth/auth.service';
import { GlobalSettingsService } from '../../../services/global-settings.service';
import { fromEvent, Subscription } from 'rxjs';
import { HeaderItemHostDirective } from './directives/header-item-host.directive';
import { SectionHeaderItem, SectionHeaderItemComponent } from './section-header.model';
import { tap, throttleTime } from 'rxjs/operators';

@Component({
  selector: 'app-section-header',
  templateUrl: './section-header.component.html',
  styleUrls: ['./section-header.component.sass']
})
export class SectionHeaderComponent implements OnInit, OnDestroy {
  private sub: Subscription = new Subscription();

  @ViewChild(HeaderItemHostDirective, { static: true }) headerItemHost!: HeaderItemHostDirective;

  showMenu = false;
  preventMenuHide = true;
  menuElementRef: ElementRef | undefined;
  prevScrollPos = 0;

  constructor(
    public auth: AuthService,
    private router: Router,
    public globalSetting: GlobalSettingsService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {}

  @ViewChild('profileMenu', { read: ElementRef })
  set _menuElementRef(el: ElementRef) {
    this.menuElementRef = el;
  }

  ngOnInit(): void {
    this.sub.add(
      this.globalSetting.headerChange$.subscribe((header: SectionHeaderItem | null) => {
        if (!header) return;
        this.loadComponent(header);
      })
    );

    this.initListenerWindowScroll();
  }

  logout(): void {
    this.sub.add(
      this.auth.logout().subscribe(() => {
        this.toggleMenu(false);
        window.location.assign('/sign-in');
      })
    );
  }

  myAccount(): void {
    this.toggleMenu(false);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  toggleMenu(show?: boolean): void {
    if (show === undefined) show = !this.showMenu;
    this.showMenu = show;
    this.preventMenuHide = true;
  }

  @HostListener('document:click', ['$event'])
  onClickAnywhere(event: any): void {
    if (this.showMenu && !this.menuElementRef?.nativeElement.contains(event.target)) {
      if (this.preventMenuHide) this.preventMenuHide = false;
      else this.toggleMenu(false);
    }
  }

  private loadComponent(item: SectionHeaderItem): void {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(item.component);

    const viewContainer = this.headerItemHost.viewContainer;
    viewContainer.clear();

    const componentRef = viewContainer.createComponent<SectionHeaderItemComponent>(componentFactory);
    componentRef.instance.data = item.data;
  }

  initListenerWindowScroll(): void {
    const subThrottleWindowScroll = fromEvent(window, 'scroll')
      .pipe(
        throttleTime(100),
        tap(() => {
          const currentScrollPos = window.scrollY;

          if (this.prevScrollPos > currentScrollPos) {
            this.renderer.removeClass(this.elementRef.nativeElement, 'hide-mobile-menu');
          } else if (currentScrollPos > 50) {
            this.renderer.addClass(this.elementRef.nativeElement, 'hide-mobile-menu');
          }
          this.prevScrollPos = currentScrollPos;
        })
      )
      .subscribe();

    this.sub.add(subThrottleWindowScroll);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayCommissionComponent } from './pay-commission.component';
import { ButtonModule } from 'primeng/button';
import { PayCommissionService } from './pay-commission.service';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  declarations: [PayCommissionComponent],
  imports: [CommonModule, ButtonModule, TranslateModule.forChild(), SharedModule],
  exports: [PayCommissionComponent],
  providers: [PayCommissionService]
})
export class PayCommissionModule {}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { OrderCriteria } from './model/order-criteria';
import { ListingsSearchService } from './service/ListingsSearchService';
import { Listing, ListingSearchResult, SortValues } from './model/ListingSearchResult';
import { Sort } from '../searchCommon/criteria';
import { ActivatedRoute, NavigationEnd, ParamMap, Router } from '@angular/router';
import { QueryParamsService } from './service/query-params.service';
import { GlobalLoaderService } from '../views/shared/global-loader/global-loader.service';
import { HistoryScrollPositionService } from '../services/history-scroll-position.service';

@Component({
  selector: 'app-listings',
  templateUrl: './listings.component.html',
  styleUrls: ['./listings.component.sass']
})
export class ListingsComponent implements OnInit, OnDestroy {
  navigationSubscription;
  listings: Listing[];
  totalCount: number;
  searchAfter: SortValues[];

  lastCriteria: OrderCriteria;
  lastSort: Sort | undefined;
  isLoadingMoreItems = false;
  loadMoreItemsError = false;

  constructor(
    private searchService: ListingsSearchService,
    private route: ActivatedRoute,
    private query: QueryParamsService,
    private globalLoaderService: GlobalLoaderService,
    private historyScrollPositionService: HistoryScrollPositionService,
    private router: Router
  ) {
    this.navigationSubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.subscribeRouteQueryParams();
      }
    });
  }

  ngOnInit(): void {
    this.subscribeRouteQueryParams();
  }

  ngOnDestroy(): void {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  subscribeRouteQueryParams(): void {
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.searchByParams(params).subscribe(
        (result: ListingSearchResult) => {
          this.listings = result.results;
          this.totalCount = result.total;
          this.searchAfter = result.searchAfter.sortValues;
          this.globalLoaderService.hide();
          this.historyScrollPositionService.scrollToHistoryPosition();
        },
        () => {
          this.globalLoaderService.hide();
        }
      );
    });
  }

  private searchByParams(params: ParamMap, searchAfter?: SortValues[]) {
    const criteria: OrderCriteria = {};

    if (this.query.containsLoadingData(params)) {
      criteria.loading = {
        latitude: this.query.getLoadingLatitude(params),
        longitude: this.query.getLoadingLongitude(params),
        range: this.query.getLoadingRange(params),
        countryCode:
          this.query.getLoadingAdministrativeAreaType(params) === 'country'
            ? this.query.getLoadingCountry(params)
            : undefined
      };
    }

    if (this.query.containsUnloadingData(params)) {
      criteria.unloading = {
        latitude: this.query.getUnloadingLatitude(params),
        longitude: this.query.getUnloadingLongitude(params),
        range: this.query.getUnloadingRange(params),
        countryCode:
          this.query.getUnloadingAdministrativeAreaType(params) === 'country'
            ? this.query.getUnloadingCountry(params)
            : undefined
      };
    }

    if (this.query.hasLoadingDates(params)) {
      criteria.loadingDateFrom = this.query.getLoadingDates(params)?.[0];
      criteria.loadingDateTo = this.query.getLoadingDates(params)?.[1];
    }

    criteria.unloadingDateTo = this.query.getUnloadingDate(params);

    criteria.minNoOfCars = this.query.getMinNumberOfCars(params);
    criteria.maxNoOfCars = this.query.getMaxNumberOfCars(params);
    criteria.shipper = this.query.getShipper(params);

    criteria.negotiable = this.query.getNegotiable(params);
    criteria.fullTruck = this.query.getFullTruckLoad(params);
    criteria.hideYourCompanysOrders = this.query.getHideYourCompanysOrders(params);
    criteria.showOnlyMyFavouriteOrders = this.query.getShowOnlyMyFavouriteOrders(params);

    criteria.skipId = this.query.getSourceOrderId(params);

    this.lastCriteria = criteria;
    this.lastSort = this.query.getSortOrder(params);

    return this.doSearch(criteria, this.lastSort, searchAfter);
  }

  private doSearch(criteria: OrderCriteria, sort?: Sort, searchAfter?: SortValues[]) {
    if (!searchAfter) {
      this.globalLoaderService.show();
    }

    return this.searchService.search(criteria, sort, searchAfter);
  }

  loadMoreResults(event: boolean): void {
    this.globalLoaderService.hide();
    this.isLoadingMoreItems = event;
    this.loadMoreItemsError = false;
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      this.searchByParams(params, this.searchAfter).subscribe(
        (result: ListingSearchResult) => {
          const temporaryListing = [...this.listings, ...result.results];
          const seenIds = new Set();
          const uniqueListing: Listing[] = [];
          temporaryListing.forEach((element) => {
            if (!seenIds.has(element.id)) {
              seenIds.add(element.id);
              uniqueListing.push(element);
            }
          });
          this.listings = uniqueListing;
          this.totalCount = result.total;
          this.searchAfter = result.searchAfter.sortValues;
          this.isLoadingMoreItems = false;
        },
        (error) => {
          this.isLoadingMoreItems = false;
          this.loadMoreItemsError = true;
        }
      );
    });
  }
}

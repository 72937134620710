import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationModalComponent } from './notification-modal.component';
import { TranslateModule } from '@ngx-translate/core';
import { NotificationTileModule } from '../../notification-tile/notification-tile.module';
import { ButtonModule } from 'primeng/button';
import { NotificationModalService } from './notification-modal.service';
import { NotificationListService } from '../../notification-list/notification-list.service';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [NotificationModalComponent],
  imports: [CommonModule, TranslateModule.forChild(), NotificationTileModule, ButtonModule, RouterModule],
  providers: [NotificationModalService, NotificationListService],
  exports: [NotificationModalComponent]
})
export class NotificationModalModule {}

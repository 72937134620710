import { Injectable } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmDialogComponent } from '../views/shared/confirm-dialog/confirm-dialog.component';
import { AlertDialogComponent } from '../views/shared/alert-dialog/alert-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class CustomDialogService {
  dialogIds: string[] = [];
  constructor(private dialogService: DialogService) {}

  open({
    component,
    config,
    callback
  }: {
    component: any;
    // eslint-disable-next-line @typescript-eslint/ban-types
    config?: DynamicDialogConfig & { data?: {} };
    callback?: () => any;
  }): void {
    if (!config) {
      config = {};
    }

    if (!config.data) {
      config.data = {};
    }

    const dynamicDialogRef: DynamicDialogRef = this.dialogService.open(component, config);
    dynamicDialogRef.onClose.subscribe(callback);
  }

  openConfirm({
    config,
    callback
  }: {
    config?: DynamicDialogConfig & { data?: ConfirmDialogData };
    callback?: (isConfirmed: boolean) => any;
  }): void {
    if (!config) {
      config = {};
    }

    if (!config.data) {
      config.data = {};
    }

    if (config.data.id) {
      if (this.dialogIds.find((item) => item === config?.data.id)) {
        return;
      } else this.dialogIds.push(config.data.id);
    }

    const dynamicDialogRef: DynamicDialogRef = this.dialogService.open(ConfirmDialogComponent, config);
    dynamicDialogRef.onClose.subscribe((confirm) => {
      this.dialogIds = this.dialogIds.filter((item) => item !== config?.data.id);

      if (!callback) return;
      callback(confirm);
    });
  }

  openAlert({
    config,
    callback
  }: {
    config?: DynamicDialogConfig & { data?: AlertDialogData };
    callback?: () => any;
  }): void {
    const defaultStyle = {
      minWidth: '200px',
      maxWidth: '70vw'
    };

    if (!config) {
      config = {};
    }

    if (!config.data) {
      config.data = {};
    }

    // INIT DEFAULT STYLE
    config.style = { ...defaultStyle, ...config.style };

    const dynamicDialogRef: DynamicDialogRef = this.dialogService.open(AlertDialogComponent, config);
    dynamicDialogRef.onClose.subscribe(callback);
  }
}

export interface ConfirmDialogData {
  confirmButtonText?: string;
  cancelButtonText?: string;
  headerText?: string;
  id?: string;
  text: string;
}

export interface AlertDialogData {
  headerText?: string;
  text: string;
  buttonText?: string;
}

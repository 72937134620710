import { Component, OnDestroy, OnInit } from '@angular/core';
import { RegistrationService } from '../../../services/registration.service';
import { AuthService } from '../../../auth/auth.service';
import { Subscription } from 'rxjs';
import { GlobalLoaderService } from '../../shared/global-loader/global-loader.service';

@Component({
  selector: 'app-confirmation-content',
  templateUrl: './confirmation-content.component.html',
  styleUrls: ['./confirmation-content.component.sass']
})
export class ConfirmationContentComponent implements OnInit, OnDestroy {
  userEmail?: string;
  sub: Subscription;
  resendResponseStatus: 'empty' | 'success' | 'error' = 'empty';

  constructor(
    private registrationService: RegistrationService,
    private authService: AuthService,
    private globalLoaderService: GlobalLoaderService,
  ) {}

  ngOnInit(): void {
    this.userEmail = this.registrationService.userData.email;
  }

  sendEmailAgain(): void {
    if (this.userEmail) {
      this.globalLoaderService.show();

      this.sub = this.authService.resendEmail(this.userEmail)
        .subscribe(() => {
          this.resendResponseStatus = 'success'
          this.globalLoaderService.hide();
        }, () => {
          this.resendResponseStatus = 'error';
          this.globalLoaderService.hide();
        });
    }
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}

export class UserData {
  constructor(
    public fullName: string = '',
    public countryPhoneCode: string = '',
    public phoneNumber: string = '',
    public email: string = '',
    public repeatEmail: string = '',
    public password: string = '',
    public repeatPassword: string = '',
    public role?: string[]
  ) {}
}

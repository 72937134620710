import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SectionHeaderItem } from '../views/shared/section-header/section-header.model';
import { SimpleHeaderComponent } from '../views/shared/section-header/components/simple-header/simple-header.component';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalSettingsService {
  private readonly headerChange: BehaviorSubject<SectionHeaderItem | null> =
    new BehaviorSubject<SectionHeaderItem | null>(null);
  readonly headerChange$ = this.headerChange.asObservable();

  constructor(private auth: AuthService, private translateService: TranslateService) {}

  updateHeader(header: SectionHeaderItem): void {
    this.headerChange.next(header);
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  updateTitleCustom(title: string, translate = true, data: Object = {}): void {
    this.updateHeader({
      component: SimpleHeaderComponent,
      data: {
        pageTitle: title,
        translatePageTitle: translate,
        pageTitleData: data
      }
    });
  }

  updateTitleFromList(pageUrl: string): void {
    this.pageTitles
      .pipe(map((pages) => this.findTranslationCode(pageUrl, pages)))
      .subscribe((result: string) => this.updateTitleCustom(result));
  }

  private get pageTitles(): Observable<{ [index: string]: string }> {
    return of({
      '/payments-list': 'paymentsList.title',
      '/chat': 'chat.title',
      '/carrier-tracking': 'carrierTracking.title'
    });
  }

  private findTranslationCode(pageUrl: string, pages: { [p: string]: string }): string {
    const pageKey = Object.keys(pages).find((key) => key.startsWith(pageUrl));
    return pageKey ? pages[pageKey] : '';
  }
}

<!-- FROM DIALOG SERVICE -->
<ng-container [ngTemplateOutlet]="headerTpl"></ng-container>
<ng-container [ngTemplateOutlet]="textTpl"></ng-container>
<ng-container
  *ngIf="dynamicDialogConfig.closable === false"
  [ngTemplateOutlet]="buttonsTpl"
></ng-container>

<!-- HEADER TPL -->
<ng-template #headerTpl>
  <h4
    *ngIf="data.headerText"
    class="pl-6 pr-6"
  >
    {{ data.headerText }}
  </h4>
</ng-template>

<!-- TEXT TPL -->
<ng-template #textTpl>
  <div
    class="pl-6 pr-6"
    [innerHTML]="data.text"
  ></div>
</ng-template>

<ng-template #buttonsTpl>
  <div class="grid justify-content-center mt-4">
    <p-button
      type="button"
      label="{{ data.buttonText || ('common.ok' | translate) }}"
      class="small-yellow"
      (click)="close()"
    ></p-button>
  </div>
</ng-template>

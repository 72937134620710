import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';
import { RegistrationService } from '../../../services/registration.service';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CompanyTaxIdUniqueValidator implements AsyncValidator {
  constructor(private registrationService: RegistrationService) {}

  validate(control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> {
    return of(control.value).pipe(
      // delay(1500),
      switchMap((taxId) =>
        this.registrationService.isTaxIdExisting(taxId).pipe(
          map((isExisting) => (isExisting.companyExists ? { companyExists: true } : null)),
          catchError(() => of(null))
        )
      )
    );
  }
}

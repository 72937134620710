import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentsComponent } from './comments.component';
import { FoldableButtonModule } from '../../shared/foldable-button/foldable-button.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { WrappedTextareaModule } from '../../shared/wrappered-textarea/wrapped-textarea.module';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';

@NgModule({
  declarations: [CommentsComponent],
  exports: [CommentsComponent],
  imports: [
    CommonModule,
    FoldableButtonModule,
    TranslateModule,
    ReactiveFormsModule,
    WrappedTextareaModule,
    ButtonModule,
    InputTextareaModule
  ]
})
export class CommentsModule {}

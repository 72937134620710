import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserData } from '../views/user-data/user-data';
import { CompanyExistsResult } from './dto/company-exists-result';
import { CompanyData } from '../views/company-data/company-data';
import { environment } from '../../environments/environment';
import { EmailResponse } from './dto/email-response';
import { RegisterResponseDto } from './dto/register-response-dto';
import { UserTermsData } from '../views/terms/user-terms-data';
import { RegisterCompanyRequest } from './dto/register-company-request';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  private apiUrl = environment.config.apiUrl;
  private checkEmailEndpoint = environment.config.checkEmailEndpoint;
  invitationMode = false;
  selectedRoles: string[] = [];
  userData: UserData = new UserData();
  companyData: CompanyData = new CompanyData();
  userTermsData: UserTermsData = new UserTermsData();
  validSteps: boolean[] = [false, false, false];
  accountCreated = false;
  shouldUpdatePhoneCode: boolean;
  responsiveClasses = {
    label: ['col-12', 'md:col-4', 'xl:col-3'],
    input: ['col-12', 'md:col-8', 'xl:col-9', 'input-helper']
  };

  constructor(private http: HttpClient) {}

  public isEmailExisting(email: string): Observable<EmailResponse> {
    return this.http.post<EmailResponse>(`${this.apiUrl}${this.checkEmailEndpoint}`, JSON.stringify(email));
  }

  isTaxIdExisting(taxId: string): Observable<CompanyExistsResult> {
    return this.http.post<CompanyExistsResult>(this.apiUrl + '/companies/check-taxid', JSON.stringify(taxId));
  }

  register(request: RegisterCompanyRequest): Observable<RegisterResponseDto> {
    return this.http.post<RegisterResponseDto>(this.apiUrl + '/companies', request);
  }
}

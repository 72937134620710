export type GetAllNotificationsForUserIdQueryResponse = Readonly<{
  notifications: NotificationDto[];
}>;

export type GetUnreadNotificationsForUserIdQueryResponse = Readonly<{
  allPending: number;
  notifications: NotificationDto[];
}>;

export type NotificationDto = Readonly<{
  id: string;
  userId: string;
  creationTime: string;
  type: NotificationType;
  status: NotificationStatus;
  link?: string;
  data?: { [index: string]: string };
}>;

export enum NotificationType {
  DOCUMENT_NEED_VERIFICATION = 'DOCUMENT_NEED_VERIFICATION',
  NEW_COMPLAINT = 'NEW_COMPLAINT',
  ORDER_PRICE_CHANGED = 'ORDER_PRICE_CHANGED',
  OFFER_ACCEPTED = 'OFFER_ACCEPTED',
  OFFER_REJECTED = 'OFFER_REJECTED',
  NEW_COMMENT = 'NEW_COMMENT',
  NEW_MESSAGE = 'NEW_MESSAGE',
  LOADING_CHECKLIST_COMPLETED = 'LOADING_CHECKLIST_COMPLETED',
  LOADING_COMPLETED = 'LOADING_COMPLETED',
  CHAT_MESSAGE_UNREAD = 'CHAT_MESSAGE_UNREAD',
  DRIVER_PREDEFINED_MESSAGE = 'DRIVER_PREDEFINED_MESSAGE',
  UNLOADING_CHECKLIST_COMPLETED = 'UNLOADING_CHECKLIST_COMPLETED',
  OFFER_EXPIRED = 'OFFER_EXPIRED',
  OFFER_COMMENT_CARRIER_POSTED = 'OFFER_COMMENT_CARRIER_POSTED',
  OFFER_COMMENT_SHIPPER_POSTED = 'OFFER_COMMENT_SHIPPER_POSTED'
}

export enum NotificationStatus {
  UNREAD = 'UNREAD',
  READ = 'READ'
}

import { Injectable } from '@angular/core';

declare let H: any;

@Injectable({
  providedIn: 'root'
})
export class MapIconsService {
  getMarker(point: any, label: string, color?: string): any {
    const markerColor = color || '#1A0837';
    const fontColor = color ? '#1A0837' : 'white';
    const svg =
      '<svg\n' +
      '   xmlns="http://www.w3.org/2000/svg"\n' +
      '   width="28"\n' +
      '   height="34"\n' +
      '   viewBox="-2 -2 26 32"\n' +
      '   fill="' +
      markerColor +
      '"\n' +
      '   version="1.1"\n' +
      '   id="svg977">\n' +
      '  <path\n' +
      '     d="m 12,0 c 5.6057,0 12,4.40597 12,12.806 0,5.3373 -3.6514,10.9612 -10.9543,16.8179 -0.6171,0.5015 -1.4743,0.5015 -2.0914,0 C 3.65143,23.7493 0,18.1433 0,12.806 0,4.40597 6.39429,0 12,0 Z"\n' +
      ' stroke="#1A0837" stroke-width="1px"' +
      '     fill="' +
      markerColor +
      '"/>\n' +
      '  <text x="12" y="18" font-size="12pt" font-family="Arial" font-weight="bold" text-anchor="middle" fill="' +
      fontColor +
      '">' +
      label +
      '</text>\n' +
      '</svg>\n';
    const icon = new H.map.Icon(svg, {anchor: {x: 28 / 2, y: 30}});
    return new H.map.Marker(point, { icon: icon });
  }

  getTrackingDot(point: any, label: string): any {
    const svg =
      '<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<circle cx="15" cy="15" r="12" fill="#1A0837"/>\n' +
      '<text x="15" y="20" font-size="12pt" font-family="Arial" font-weight="bold" text-anchor="middle" fill="white">' +
      label +
      '</text>\n' +
      '</svg>\n';
    const icon = new H.map.Icon(svg);
    return new H.map.Marker(point, { icon: icon });
  }

  getTruckIcon(point: any, label: string): any {
    const svg =
      '<svg width="126" height="60" viewBox="0 0 126 60" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<circle cx="63" cy="45" r="12" fill="#1A0837"/>\n' +
      '<g filter="url(#filter0_d_6647_85213)">\n' +
      '<rect x="10" width="106" height="30" rx="7" fill="#1A0837"/>\n' +
      '</g>\n' +
      '<defs>\n' +
      '<filter id="filter0_d_6647_85213" x="0" y="0" width="126" height="50" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">\n' +
      '<feFlood flood-opacity="0" result="BackgroundImageFix"/>\n' +
      '<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>\n' +
      '<feMorphology radius="10" operator="erode" in="SourceAlpha" result="effect1_dropShadow_6647_85213"/>\n' +
      '<feOffset dy="10"/>\n' +
      '<feGaussianBlur stdDeviation="10"/>\n' +
      '<feColorMatrix type="matrix" values="0 0 0 0 0.101961 0 0 0 0 0.0313726 0 0 0 0 0.215686 0 0 0 0.07 0"/>\n' +
      '<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6647_85213"/>\n' +
      '<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6647_85213" result="shape"/>\n' +
      '</filter>\n' +
      '</defs>\n' +
      '<text x="65" y="20" font-size="12pt" font-family="Arial" font-weight="bold" text-anchor="middle" fill="white">' +
      label +
      '</text>\n' +
      '</svg>\n';

    const icon = new H.map.Icon(svg);
    return new H.map.Marker(point, { icon: icon });
  }
}

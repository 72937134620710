import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferInfoComponent } from './offer-info.component';
import { TranslateModule } from '@ngx-translate/core';
import { RatingModule } from '../util/rating/rating.module';
import { ButtonModule } from 'primeng/button';
import { StatusColorPipeModule } from '../../../../../adamp-frontend-custserv/src/app/pipes/status-color-pipe.module';
import { StatusCircleModule } from '../status-circle/status-circle.module';
import { TeleportModule } from '@ngneat/overview';
import { SharedModule } from '../../shared/shared.module';
import { SectionHeaderModule } from '../shared/section-header/section-header.module';
import { OrderHistoryButtonModule } from '../shared/order-history-button/order-history-button.module';

@NgModule({
  declarations: [OfferInfoComponent],
  exports: [OfferInfoComponent],
    imports: [
        CommonModule,
        TranslateModule,
        RatingModule,
        ButtonModule,
        StatusColorPipeModule,
        StatusCircleModule,
        TeleportModule,
        SharedModule,
        SectionHeaderModule,
        OrderHistoryButtonModule,
    ]
})
export class OfferInfoModule {}

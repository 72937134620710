import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListingsComponent } from './listings.component';
import { CriteriaComponent } from './criteria/criteria.component';
import { ResultsComponent } from './results/results.component';
import { CardModule } from 'primeng/card';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CountryService } from './criteria/country-service';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataViewModule } from 'primeng/dataview';
import { ItemComponent } from './results/item/item.component';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { CalendarModule } from 'primeng/calendar';
import { DividerModule } from 'primeng/divider';
import { SharedModule } from '../shared/shared.module';
import { RatingModule } from 'primeng/rating';
import { CountryCodeModule } from '../pipes/country-code/country-code.module';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { LoaderModule } from '../views/shared/loader/loader.module';
import { SectionHeaderModule } from '../views/shared/section-header/section-header.module';
import { SortDropdownModule } from '../views/shared/sort-dropdown/sort-dropdown/sort-dropdown.module';
import { RouterModule } from '@angular/router';
import { DateElementModule } from '../views/shared/date-element/date-element.module';

@NgModule({
  declarations: [ListingsComponent, CriteriaComponent, ResultsComponent, ItemComponent],
  imports: [
    CommonModule,
    CardModule,
    TranslateModule,
    ButtonModule,
    InputNumberModule,
    InputSwitchModule,
    AutoCompleteModule,
    DropdownModule,
    ReactiveFormsModule,
    DataViewModule,
    VirtualScrollerModule,
    CalendarModule,
    DividerModule,
    SharedModule,
    RatingModule,
    FormsModule,
    CountryCodeModule,
    NgbTooltipModule,
    LoaderModule,
    SectionHeaderModule,
    SortDropdownModule,
    RouterModule,
    DateElementModule
  ],
  exports: [ItemComponent],
  providers: [CountryService]
})
export class ListingsModule {}

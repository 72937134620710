import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-sort-dropdown',
  templateUrl: './sort-dropdown.component.html',
  styleUrls: ['./sort-dropdown.component.sass']
})
export class SortDropdownComponent implements OnInit {
  @Input() sortOptions: SortOrderOption[] = [];
  @Input() disableLegend = false;
  @Output() sortOrderChange: EventEmitter<SortOrderOption> = new EventEmitter();

  selectedSortOrder: SortOrderOption;

  handleChangeEvent(event: SortOrderOption): void {
    this.sortOrderChange.emit(event);
  }

  ngOnInit(): void {
    this.selectedSortOrder = this.sortOptions[0];
  }
}

export type SortOrderOption = {
  label: string;
  dir: SortDirection;
  key: string;
};

export enum SortDirection {
  'ASC' = 'ASC',
  'DESC' = 'DESC'
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, OnDestroy, ApplicationInitStatus, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import * as op from 'rxjs/operators';
import * as sa from 'stream-chat-angular';
import * as stream from 'stream-chat';

import { environment } from '../../environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ChatService implements OnDestroy {
  private static _DEBUG = false;
  private _config = environment.streamChat;
  private _isConnectedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _isInitializedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _unreadMessagesSubject: BehaviorSubject<number | undefined> = new BehaviorSubject<number | undefined>(0);
  private _subscriptions: Subscription[] = [];
  private _voidChannel: stream.Channel<sa.DefaultStreamChatGenerics>;
  private _storedActiveChannel: stream.Channel<sa.DefaultStreamChatGenerics>;
  public isConnected$: Observable<boolean> = this._isConnectedSubject.asObservable();
  public isInitialized$: Observable<boolean> = this._isInitializedSubject.asObservable();
  public unreadMessages$: Observable<number | undefined> = this._unreadMessagesSubject.asObservable();
  private get streamChatLocalStorageKey() {
    return `streamChatToken_${this.auth.getUserInfo().userId}`;
  }

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private appInit: ApplicationInitStatus,
    private zone: NgZone,
    private chatClientService: sa.ChatClientService,
    private streamI18n: sa.StreamI18nService,
    private channelService: sa.ChannelService
  ) {
    appInit.donePromise.then(() => {
      this._initialize();
    });
  }

  private get _c(): any {
    return this.constructor as typeof ChatService;
  }

  get chatUserId(): string {
    return this.auth.getUserInfo().userId;
  }

  ngOnDestroy(): void {
    for (const subs of this._subscriptions) {
      subs.unsubscribe();
    }
  }

  private _initialize(): void {
    this._subscriptions.push(
      this.auth.loggedIn$.subscribe((loggedIn) => {
        if (loggedIn) {
          this._connect();
        } else {
          this._disconnect();
        }
      })
    );
  }

  private _createSubscriptions(): void {
    this._subscriptions.push(
      this.chatClientService.connectionState$.subscribe((state) => {
        this._isConnectedSubject.next(state == 'online');
      })
    );

    this._subscriptions.push(
      this.chatClientService.events$.subscribe(({ eventType, event }) => {
        if (
          eventType == 'message.new' ||
          eventType == 'notification.mark_read' ||
          eventType == 'notification.message_new'
        ) {
          this._unreadMessagesSubject.next(event.total_unread_count);
        }

        // eslint-disable-next-line sonarjs/no-collapsible-if
        if (this._c._DEBUG) {
          if (!eventType.startsWith('health.')) console.log('Chat event: ', eventType, event);
        }
      })
    );

    if (this._c._DEBUG) {
      this.isConnected$.subscribe((result) => {
        console.log('Chat connected: ' + result);
      });
      this.channelService.activeChannel$.subscribe((channel) => {
        console.log('Active channel:', channel);
      });
    }
  }

  private _getNewAuthToken(): Promise<string | undefined> {
    return this.http
      .get<Record<string, string>>(`${environment.config.apiUrl}${environment.config.chatLoginEndpoint}`)
      .pipe(op.map((response) => response.token))
      .toPromise();
  }

  storeActiveChannel(): void {
    if (!this._voidChannel) return;
    this.channelService.activeChannel$.pipe(op.take(1)).subscribe((channel) => {
      if (channel && channel.cid != this._config.inactivityChannelCid) {
        this._storedActiveChannel = channel;
      }
    });
    this.channelService.setAsActiveChannel(this._voidChannel);
  }

  restoreActiveChannel(): void {
    if (this._storedActiveChannel) {
      this.channelService.setAsActiveChannel(this._storedActiveChannel);
    } else {
      this.channelService.channels$.pipe(op.take(1)).subscribe((channels) => {
        if (channels?.length) {
          this.channelService.setAsActiveChannel(channels[0]);
        }
      });
    }
  }

  private async _loadChannels(): Promise<void> {
    await this.channelService.init(
      {
        type: 'order',
        members: { $in: [this.chatUserId] }
      },
      undefined,
      { limit: this._config.channelsPageSize }
    );

    let hasMoreChannels = true;
    const subs = this.channelService.hasMoreChannels$.subscribe({
      next: (value) => {
        hasMoreChannels = value;
      },
      error: () => {
        hasMoreChannels = false;
      }
    });

    try {
      for (let i = 0; i < this._config.initialChannelsPagesNumber - 1 && hasMoreChannels; ++i) {
        await this.channelService.loadMoreChannels();
      }
    } catch (error) {
      console.error(error);
      console.error('Failed to load all channels');
    }

    subs.unsubscribe();
  }

  private _handleInitializationError(error: any): void {
    console.error(error);
    console.error('Chat initialization failed!');
    this._isInitializedSubject.error(error);
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  private async _connect(): Promise<void> {
    try {
      let token: string | undefined = window.localStorage.getItem(this.streamChatLocalStorageKey) || undefined;
      let retry = true;
      let retries = 5;

      while (retry) {
        if (!token) {
          token = await this._getNewAuthToken();
          if (!token) {
            throw new Error('Failed to obtain user token for chat!');
          } else {
            window.localStorage.setItem(this.streamChatLocalStorageKey, token);
          }
          retry = false;
        }

        try {
          try {
            await this.chatClientService.init(this._config.apiKey, this.chatUserId, token);

            retry = false;
          } catch (e) {
            console.error(e);
            retry = true;
            token = undefined;
            window.localStorage.removeItem(this.streamChatLocalStorageKey);
          }

          try {
            await this.chatClientService.chatClient.getAppSettings();
            retry = false;
          } catch (e: any) {
            retry = true;
            if (e?.status === 401) {
              throw new Error('Token 401 streamchat');
            }
          }

          retries--;

          if (!retry || retries === 0) {
            break;
          }
        } catch (e) {
          console.error(e);
          token = undefined;
        }
      }

      // the following line makes default translations work but also somehow prevents overrides from working
      // (at least when using TranslateModule.forChild())
      //this.streamI18n.setTranslation();

      this._isConnectedSubject.next(true);

      if (this._c._DEBUG) {
        console.log('Chat user:', this.chatClientService.chatClient.user!);
      }

      this._unreadMessagesSubject.next(this.chatClientService.chatClient.user!.total_unread_count as number);

      this._createSubscriptions();

      // signal initialization once channel list has been loaded

      this.channelService.channels$
        .pipe(
          op.skipWhile((channels) => !channels),
          op.first()
        )
        .subscribe(() => {
          this._isInitializedSubject.next(true);
        });

      // load list of channels & start watching them
      await this._loadChannels();
    } catch (error) {
      this._handleInitializationError(error);
    }
  }

  private async _disconnect(): Promise<void> {
    this.channelService.channels$.pipe(op.first()).subscribe(async (channels) => {
      for (const channel of channels || []) {
        await channel.stopWatching();
      }
    });

    await this.chatClientService.chatClient.disconnect();
    this._isConnectedSubject.next(false);
  }
}

<div class="language-select">
  <p-dropdown
    [options]="languages"
    [(ngModel)]="selectedLanguage"
    (onClick)="clicked = !clicked"
    (onChange)="setSelectedLanguage($event.value)"
    (clickOutside)="clicked = false"
  >
    <ng-template pTemplate="selectedItem">
      <div
        *ngIf="selectedLanguage"
        class="radius-noround"
      >
        <img
          src="assets/images/flag_placeholder.png"
          [class]="'flag flag-' + (selectedLanguage.countryCode | countryCode)"
          width="30px"
          height="20px"
        />
      </div>
    </ng-template>
    <ng-template
      let-language
      pTemplate="item"
    >
      <div class="flex align-items-center">
        <img
          src="assets/images/flag_placeholder.png"
          [class]="'mr-2 flag flag-' + (language.countryCode | countryCode)"
          width="30px"
          height="20px"
        />
        <p
          class="primary-dark-purple m-0 p-0"
          [ngClass]="language.name === selectedLanguage.name ? 'body-large-bold' : 'body-large'"
        >
          {{ language.name }}
        </p>
      </div>
    </ng-template>
  </p-dropdown>
</div>

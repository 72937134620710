import { Injectable } from '@angular/core';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RwdService {
  currentBreakpoint$: BehaviorSubject<number> = new BehaviorSubject(0);
  get currentBreakpointValue(): number {
    return this.currentBreakpoint$.value;
  }

  constructor() {
    this.setCurrentBreakpoint();
    this.subscribeResize();
  }

  private subscribeResize() {
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(250),
        tap(() => {
          this.setCurrentBreakpoint();
          this.setBackgroundTitleSize();
        })
      )
      .subscribe();
  }

  setCurrentBreakpoint(): void {
    const currentBreakpoint =
      Object.values(RWD_BREAKPOINT)
        .reverse()
        .find((value: number) => {
          return window.innerWidth >= value;
        }) || 0;

    this.currentBreakpoint$.next(currentBreakpoint);
  }

  setBackgroundTitleSize(): void {
    const backgroundTitleElements = document.querySelectorAll('.background-title');

    backgroundTitleElements.forEach((backgroundTitleElement) => {
      const backgroundTitleParentElement = <HTMLElement>backgroundTitleElement.parentElement;
      const backgroundTitleParentSizes = backgroundTitleParentElement.getBoundingClientRect();

      let scale = 1;
      // CHECK EXIST SCALE
      if (backgroundTitleParentSizes.width !== backgroundTitleParentElement.offsetWidth) {
        scale = backgroundTitleParentElement.offsetWidth / backgroundTitleParentSizes.width;
      }

      if (backgroundTitleParentSizes) {
        const width = document.body.clientWidth;

        (<HTMLElement>backgroundTitleElement).style.transform = `translateX(-${backgroundTitleParentSizes.left}px)`;
        (<HTMLElement>backgroundTitleElement).style.width = `${Math.floor(width * scale)}px`;
      }
    });
  }
}

export const RWD_BREAKPOINT = {
  XS: 0,
  SM: 375,
  MD: 768,
  LG: 1024,
  XL: 1280
};

<p-dropdown
  class="dropdown a-no-background description-bold"
  [(ngModel)]="selectedSortOrder"
  (ngModelChange)="handleChangeEvent($event)"
  [options]="sortOptions"
>
  <ng-template pTemplate="selectedItem">
    <p-button
      type="button"
      icon="pi pi-sort-alt"
      label="{{ 'listings.search.sort' | translate }}:{{ selectedSortOrder.label | translate | uppercase }} {{
        !disableLegend ? (selectedSortOrder.dir === 'ASC' ? '(A-Z)' : '(Z-A)') : null
      }}"
      class="regular-text white-space-nowrap m-0 px-0 a-low-height-button"
    ></p-button>
  </ng-template>

  <ng-template
    let-option
    pTemplate="item"
  >
    <div>
      {{ option.label | translate | uppercase }}
      {{ !disableLegend ? (option.dir === 'ASC' ? '(A-Z)' : '(Z-A)') : null }}
    </div>
  </ng-template>
</p-dropdown>

<div class="a-page-registration-container">
  <img
    src="assets/icons/confirmation-success-icon.svg"
    alt="success icon"
  />

  <h4>{{ 'confirmation.title' | translate }}</h4>

  <p [innerHTML]="'confirmation.confirmText' | translate: { mail: userEmail ? userEmail : '' }"></p>

  <p class="mt-3">
    <ng-container *ngIf="resendResponseStatus === 'error'">
      <span class="font-bold secondary-red">{{ 'common.error' | translate }}</span
      ><br />
    </ng-container>

    <ng-container *ngIf="resendResponseStatus === 'success'">
      <span
        class="font-bold primary-green"
        [innerHTML]="'confirmation.resendSuccess' | translate"
      ></span
      ><br />
    </ng-container>

    <ng-container *ngIf="userEmail && resendResponseStatus === 'empty'">
      <a
        (click)="sendEmailAgain()"
        class="body-bold-underline pointer"
      >
        {{ 'confirmation.sendEmailAgain' | translate }}
      </a>
      <span [innerHTML]="'confirmation.helpOr' | translate"></span><br />
    </ng-container>

    <span [innerHTML]="'confirmation.help' | translate"></span>
  </p>
</div>

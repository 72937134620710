import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-registration-verificated-confirmation',
  templateUrl: './registration-verificated-confirmation.component.html',
  styleUrls: ['./registration-verificated-confirmation.component.sass']
})
export class RegistrationVerificatedConfirmationComponent implements OnInit, OnDestroy {
  verified = false;
  error: 'EXPIRED' | 'OTHER' | null;
  private sub: Subscription;
  private username: string;
  private code: string;

  constructor(private activatedRoute: ActivatedRoute, private auth: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.sub = this.activatedRoute.queryParams.subscribe((params) => {
      this.username = params['username'];
      this.code = params['code'];
      this.auth.verify({ userName: this.username, code: this.code }).subscribe(
        () => {
          this.verified = true;
        },
        (error) => {
          if (error.code == 'ExpiredCodeException') {
            this.error = 'EXPIRED';
          } else {
            this.error = 'OTHER';
          }
        }
      );
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  goToSignIn(): void {
    this.router.navigate(['sign-in']);
  }

  retry(): void {
    if (this.username) {
      this.auth.resendEmail(this.username).subscribe(() => {
        this.goToSignIn();
      });
    } else {
      this.goToSignIn();
    }
  }
}

import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomDialogService } from '../services/custom-dialog.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptorService implements HttpInterceptor {
  translateService: TranslateService | undefined;
  isOpenedDialogConfirmReload: boolean;

  constructor(
    private customDialogService: CustomDialogService,
    private readonly injector: Injector
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Circular DI fix
    let textFallbackTranslateServiceCatch: string;
    let translatedText: string;
    let pathTranslate: string;
    try {
      this.translateService = this.injector.get(TranslateService);
    } catch (error) {
      console.error(error);
    }

    return next.handle(req).pipe(
      catchError((error) => {
        switch (error.status) {
          case HttpStatusCode.BadRequest:
          case HttpStatusCode.Conflict:
            pathTranslate = 'api.409';
            textFallbackTranslateServiceCatch = 'Something went wrong. Please refresh the page and try again.';
            translatedText = this.translateService?.instant(pathTranslate);

            this.openConfirmError({
              text: this.getTranslatedTextOrFallback(translatedText, pathTranslate, textFallbackTranslateServiceCatch)
            });
            break;

          case HttpStatusCode.InternalServerError:
            pathTranslate = 'api.500';
            // eslint-disable-next-line no-case-declarations
            const requestId = error.headers?.get('x-amz-apigw-id');
            textFallbackTranslateServiceCatch = `An error has occurred on the server. Please refresh the page and try again.<br/><br/>Request ID ${requestId}`;
            translatedText = this.translateService?.instant(pathTranslate, {
              requestId
            });

            this.openConfirmError({
              text: this.getTranslatedTextOrFallback(translatedText, pathTranslate, textFallbackTranslateServiceCatch)
            });
            break;
        }

        return throwError(error);
      })
    );
  }

  openConfirmError({ text }: { text: string }) {
    if (!this.isOpenedDialogConfirmReload) {
      this.isOpenedDialogConfirmReload = true;

      this.customDialogService.openConfirm({
        config: {
          data: {
            confirmButtonText: this.getTranslatedTextOrFallback(
              this.translateService?.instant('common.refresh'),
              'common.refresh',
              'Refresh'
            ),
            cancelButtonText: this.getTranslatedTextOrFallback(
              this.translateService?.instant('common.cancel'),
              'common.cancel',
              'Cancel'
            ),
            headerText: this.getTranslatedTextOrFallback(
              this.translateService?.instant('common.oops'),
              'common.oops',
              'Oops...'
            ),
            text
          }
        },
        callback: (isConfirmed) => {
          this.isOpenedDialogConfirmReload = false;

          if (isConfirmed) {
            window.location.reload();
          }
        }
      });
    }
  }

  getTranslatedTextOrFallback(
    translatedText: string,
    pathTranslate: string,
    textFallbackTranslateServiceCatch: string
  ): string {
    return !translatedText || translatedText === pathTranslate ? textFallbackTranslateServiceCatch : translatedText;
  }
}

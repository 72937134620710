<div
  [class.field-wrapper]="!onelineDisabled"
  [class.field-wrapper-one-line]="oneline || onelineDisabled"
  [class.field-wrapper-one-line-disabled]="onelineDisabled"
  [class.field-wrapper-error]="isWrapperTextContent || hasError"
  [class.field-wrapper-transparent]="transparent"
  [class.field-wrapper-composed]="composed"
  [class.field-wrapper-auto-height]="autoHeight"
  [class.no-indentation]="noIndentation"
  [class.bordered]="bordered"
  [class.field-wrapper-auto-width]="autoWidth"
  class="{{ class }}"
>
  <label
    htmlFor="{{ labelFor }}"
    class="content"
  >
    <div
      *ngIf="label"
      class="field-wrapper-label description-regular"
    >
      <img
        *ngIf="image"
        src="assets/{{ image }}"
      />

      {{ label }}
    </div>

    <div class="field-wrapper-content">
      <ng-content></ng-content>
    </div>
  </label>
  <div
    #wrapper
    class="field-wrapper-validation"
  >
    <ng-content
      *ngIf="!customErrorCheck || hasError"
      select="[validation]"
    ></ng-content>
  </div>
</div>

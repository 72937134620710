import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvitationRegistrationService } from '../../services/invitation-registration.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-invitation-registration',
  templateUrl: './invitation-registration.component.html',
  styleUrls: ['./invitation-registration.component.sass']
})
export class InvitationRegistrationComponent implements OnInit {
  active: boolean;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public invitationRegistrationService: InvitationRegistrationService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.invitationRegistrationService.invitationId = params['invitationId'];
    });
    this.getInvitationCompanyData();
  }

  getInvitationCompanyData(): void {
    this.invitationRegistrationService
      .getInvitationCompanyData(this.invitationRegistrationService.invitationId)
      .subscribe(
        (response) => {
          this.invitationRegistrationService.companyName = response.companyName;
          this.invitationRegistrationService.companyCountryCode = response.companyCountryCode;
          this.invitationRegistrationService.email = response.email;
          this.invitationRegistrationService.roles = response.roles;
          this.active = true;
        },
        (error) => {
          this.active = false;
          throw new Error(error.error.message);
        }
      );
  }

  goToMainPage(): void {
    window.location.replace(environment.landingPageUrl);
  }

  goToRegistration(): void {
    this.router.navigate(['user-data-invitation']);
  }
}

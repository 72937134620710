import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderDetailComponent } from './order-detail.component';
import { PanelModule } from 'primeng/panel';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../shared/shared.module';
import { DividerModule } from 'primeng/divider';
import { CarsTableModule } from '../util/cars-table/cars-table.module';
import { CountryCodeModule } from '../../pipes/country-code/country-code.module';
import { ButtonModule } from 'primeng/button';
import { EditEtaModalModule } from '../edit-eta-modal/edit-eta-modal.module';

@NgModule({
  declarations: [OrderDetailComponent],
  exports: [OrderDetailComponent],
  imports: [
    CommonModule,
    PanelModule,
    TranslateModule,
    SharedModule,
    DividerModule,
    CarsTableModule,
    CountryCodeModule,
    ButtonModule,
    EditEtaModalModule
  ]
})
export class OrderDetailModule {}
